import _ from 'lodash';
import api from '@/services/api';
import notifications, { Notifications } from '@/services/notifications';
import items from '@/store/items.module';
import recordsCommonPartial from '@/store/records/common.module';

const resourceType = 'records';

export default () => ({
  namespaced: true,
  state: {
    ...items.state(),
    selected: [],
  },
  getters: {
    ...items.getters(),
    ...recordsCommonPartial.getters({
      mode: 'contents',
      scopes: { records: ['content', 'broadcast'] },
      includeChildren: false,
    }),
    isSelected(state) {
      return item => !!state.selected.find(selected => selected.id === item.id);
    },
    selectedCount(state) { return state.selected.length; },
    selectedUnimported(state) {
      return state.selected.filter(item => !item.id.startsWith('dashboard'));
    },
  },
  /* eslint-disable no-param-reassign */
  mutations: {
    ...items.mutations(),
    reset(state) {
      state.query = null;
      state.items = [];
      state.selected = [];
      state.total = 0;
      state.error = null;
    },
    addSelected(state, id) {
      if (state.selected.find(selected => selected.id === id)) return;
      const item = state.items.find(station => id === station.id);
      state.selected.push(item);
    },
    removeSelected(state, id) {
      const selectedIndex = state.selected.findIndex(selected => selected.id === id);
      if (selectedIndex === -1) return;
      state.selected.splice(selectedIndex, 1);
    },
    swapSelected(state, { id, station }) {
      const selectedIndex = state.selected.findIndex(selected => selected.id === id);
      if (selectedIndex >= 0) {
        state.selected.splice(selectedIndex, 1, station);
      }
    },
  },
  /* eslint-enable no-param-reassign */
  actions: {
    ...items.actions({
      resourceType,
      defaultPageSize: 50,
    }),

    async assign({
      commit, state, rootState,
    }) {
      commit('startLoading');
      try {
        const { selected } = state;
        const organizationId = _.get(rootState, 'organization.item.id');
        if (!organizationId) throw new Error('Missing organization ID');
        const content_ids = selected.map(station => station.content_id);
        const recordIds = selected.map(station => station.id);

        const organizationTopic = Notifications.topic('organizations', organizationId, null);
        const contentTopics = recordIds.map(recordId =>
          Notifications.topic('records', recordId, organizationId));
        const topics = [organizationTopic, ...contentTopics];
        const [organizationWait, ...contentWaits] = await Promise.all(topics
          .map(topic => notifications.createWait(topic)));

        const failedContentIds = [];
        try {
          const { data: { assignments } } = await api
            .assignItems('organizations', organizationId, 'stations', { content_ids });
          failedContentIds.push(...assignments.filter(el => !el.success).map(el => el.content_id));
        } catch (error) {
          failedContentIds.push(...content_ids);
        }
        if (failedContentIds.length) {
          throw new Error(`Failed to assign stations: ${failedContentIds.join(', ')}`);
        }

        await Promise.all([
          organizationWait(async () => {
            const organization = await api.getItemDetails('organizations', organizationId);
            return _.difference(organization.content_ids, content_ids).length === 0;
          }),
          ...contentWaits.map(wait => wait()),
        ]);

        const { data: item } = await api.getItemDetails('organizations', organizationId);
        commit('organization/setItem', item, { root: true });
        commit('organizations/swapItem', { item }, { root: true });
        commit('reset');
      } finally {
        commit('endLoading');
      }
    },
  },
});
