import _ from 'lodash';
import Rollbar from '@ibiquity/dashboard.rollbar';
import api from '@/services/api';
import cognito from '@/services/cognito';
import loading from '@/store/loading.module';
import tableConfiguration from './tableConfiguration';

const {
  VUE_APP_BROADCASTER_DASHBOARD_URL: broadcasterDashboardUrl,
} = process.env;

const requiredAgreements = ['eula', 'privacy_policy'];

const suggestionsComparator = ({ type, id, query }) => {
  let comparator;
  if (type === 'geo') {
    comparator = item => item.query === query && item.id === id;
  } else {
    comparator = item => item.query === query;
  }
  return comparator;
};

export default () => ({
  namespaced: true,

  state: () => ({
    ...loading.state(),
    saving: false,

    id: '',
    name: '',
    email: '',
    general_phone_number: '',
    picture: '',
    created_at: '',
    updated_at: '',
    error: '',
    organization_id: null,
    organization: null,
    pending_activation: null,
    solution_id: null,

    permissions: { allow: [] },
    accepted_agreements: null,
    search_history: {},
    table_configuration: {},
  }),

  /* eslint-disable no-param-reassign, no-shadow */
  actions: {
    getMyProfile: async ({ state, commit }) => {
      commit('startLoading');
      try {
        const response = await api.getUserInfo();
        commit('set', response.data);
        const { id, organization_id } = state;
        if (organization_id) {
          cognito.logout();
          window.location.replace(broadcasterDashboardUrl || '/');
          return;
        }
        Rollbar.updatePerson({ id });
      } catch (error) {
        commit('setError', 'Error loading user profile data');
      }
      commit('endLoading');
    },

    update: async ({
      commit, state, rootState, dispatch,
    }, payload) => {
      commit('saving', true);
      try {
        await api.updateItem('users', state.id, payload);
        const response = await api.getUserInfo();
        commit('set', response.data);

        const currentOrganizationId = _.get(rootState, 'organization.item.id');
        if (state.organization_id && state.organization_id === currentOrganizationId) {
          await dispatch('organization/members/loadItems', { force: true }, { root: true });
        }
      } finally {
        commit('saving', false);
      }
    },

    requestPasswordChange: async ({ state }) => {
      await cognito.requestPasswordChange(state.email);
    },

    updateRecents: ({ dispatch, commit, state }, {
      type, query, id, description, action,
    }) => {
      const trimmed = query.trim();
      if (!trimmed.length) return;

      commit(action, {
        type, query: trimmed, description, id,
      });
      dispatch('clipRecents', type);
      api.throttledUpdateItem('users', state.id, { search_history: state.search_history });
    },

    addRecent: ({ dispatch }, {
      type, query, id, description,
    }) => {
      dispatch('updateRecents', {
        type, query, id, description, action: 'addRecent',
      });
    },

    removeRecent: ({ dispatch }, { type, query, id }) => {
      dispatch('updateRecents', {
        type, query, id, action: 'removeRecent',
      });
    },

    clipRecents({ commit, state }, type) {
      const recents = state.search_history[type];
      commit('setRecents', {
        type,
        recents: recents.slice().sort(item => item.lastUsed).reverse().slice(0, 30),
      });
    },

    updateSolutionId: ({ commit, state }, solution_id) => {
      commit('setProperties', { solution_id });
      api.throttledUpdateItem('users', state.id, { solution_id });
    },
  },

  mutations: {
    ...loading.mutations(),

    set(state, userInfo) {
      state.id = userInfo.id;
      state.name = userInfo.name;
      state.email = userInfo.email;
      state.general_phone_number = userInfo.general_phone_number;
      state.picture = userInfo.picture;
      state.organization_id = _.get(userInfo.organization, 'id') || null;
      state.solution_id = userInfo.solution_id || null;
      state.organization = userInfo.organization || null;
      state.permissions = userInfo.permissions;
      state.pending_activation = userInfo.pending_activation;
      state.accepted_agreements = userInfo.accepted_agreements;
      state.search_history = userInfo.search_history || {};
      state.table_configuration = userInfo.table_configuration || {};
      state.created_at = userInfo.created_at;
      state.updated_at = userInfo.updated_at;
    },

    setProperties(state, properties) {
      Object.assign(state, properties);
    },

    saving(state, saving) {
      state.saving = !!saving;
    },

    setRecents(state, { type, recents }) {
      state.search_history[type].splice(
        0,
        state.search_history[type].length,
        ...recents,
      );
    },

    // in the application please use action of the same name
    addRecent(state, {
      type, query, id, description,
    }) {
      if (!state.search_history[type]) state.search_history[type] = [];
      const found = state.search_history[type].find(suggestionsComparator({
        type, id, query,
      }));
      if (!found) {
        state.search_history[type].push({
          id, query, description, weight: 1, lastUsed: Date.now(),
        });
      } else {
        found.weight += 1;
        found.lastUsed = Date.now();
        found.id = id;
      }
    },

    // in the application please use action of the same name
    removeRecent(state, { type, query, id }) {
      if (!state.search_history[type]) return;
      const foundIndex = state.search_history[type].findIndex(suggestionsComparator({
        type, id, query,
      }));
      if (foundIndex === -1) return;
      state.search_history[type].splice(foundIndex, 1);
    },

    setTableConfiguration(state, configuration) {
      state.table_configuration = configuration;
    },
  },

  getters: {
    ready: state => !!(state.id && state.email),

    pendingAgreements: (state) => {
      if (!state.accepted_agreements) return requiredAgreements;
      return requiredAgreements
        .filter(requiredAgreement =>
          !state.accepted_agreements[requiredAgreement]);
    },

    can: ({ permissions }) =>
      (action, resourceType, organizationScope) =>
        permissions.allow && !!permissions.allow.find((permission) => {
          if (permission.resourceType !== resourceType || permission.action !== action) {
            return false;
          }
          if (organizationScope === '*' && permission.organizationId !== '*') {
            return false;
          }
          if (organizationScope === '@' && permission.resourceId !== '*') {
            return false;
          }
          return true;
        }),

    recents: state => (type) => {
      if (!state.search_history[type]) return [];
      return _(state.search_history[type])
        .orderBy(['lastUsed'], ['desc'])
        .slice(0, 30)
        .value();
    },
  },

  modules: {
    tableConfiguration: tableConfiguration(),
  },
});
/* eslint-enable no-param-reassign, no-shadow */
