/* eslint-disable no-param-reassign,no-shadow */
import _ from 'lodash';
import api from '@/services/api';
import item from '@/store/item.module';
import notifications from '@/services/notifications';
import { toRawProperty } from '@/lib/recordProperties';
import { sourceFromRecord, remoteSourceValueProperty } from '@/lib/recordSources';

const state = () => ({
  ...item.state(),
  sourceChanges: [],
});

const getters = ({ resourceType, idKey = 'id' } = {}) => ({
  ...item.getters({ resourceType, idKey }),

  valuesChanged(state) {
    return state.changes.length > 0;
  },
  sourcesChanged(state) {
    return state.sourceChanges.length > 0;
  },
  isAnyChangeMade(state, getters) {
    return getters.valuesChanged || getters.sourcesChanged;
  },
  changedSources(state) {
    return state.sourceChanges.map(s => s.name);
  },
  isEditedItemSourceChanged(state, getters) {
    return property => getters.changedSources.includes(property);
  },
  changedPropertySource(state) {
    return (property) => {
      const changedItem = state.sourceChanges.find(item => item.name === property) || {};
      return changedItem.source;
    };
  },
  sourceChangesObject(state) {
    const changes = state.sourceChanges.map(({ name, source }) => {
      if (source === null) return [name, null];

      const { priorities = {} } = state.item;

      const fieldPriorities = _.get(priorities, name) || {};
      const prioritiesPatch = _(fieldPriorities)
        .pickBy(priority => priority === 11)
        .mapValues(_.constant(null))
        .value();
      prioritiesPatch[source] = 11;
      return [name, prioritiesPatch];
    });
    return _.fromPairs(changes);
  },

  itemUpdateObject(state, getters) {
    const changes = getters.changesObject;
    const result = _.omit(changes, ['logo']);
    if (_.has(changes, 'logo')) {
      result.logo_source_urls = changes.logo ? _.compact([changes.logo.stdres_url]) : null;
    }
    return result;
  },

  pendingPropertySource(state, getters) {
    return (property) => {
      const pendingPriorityUpdate = _.get(getters.editedItem, ['pending_priority_updates', property]);
      if (_.isEmpty(pendingPriorityUpdate)) return getters.defaultPropertySource(property);
      return _.findKey(pendingPriorityUpdate, priority => priority === 11);
    };
  },

  propertySource(state, getters) {
    return (property) => {
      if (getters.isPropertySourceBeingUpdated(property)) {
        return getters.pendingPropertySource(property);
      }

      if (getters.isPropertyOverridden(property)) return 'override';
      if (getters.isInheritedProperty(property)) return 'inherited'; // just for broadcast

      const changedSource = getters.changedPropertySource(property);
      if (changedSource === null) return getters.defaultPropertySource(property);
      if (changedSource) return changedSource;

      if (getters.changesObject[property] === null) return getters.defaultPropertySource(property);

      return sourceFromRecord(state.item, property) || '';
    };
  },

  isPropertyOverridden(state, getters) {
    return (property) => {
      if (_.has(getters.changesObject, property)) {
        const changedValue = getters.changesObject[property];
        return changedValue !== null;
      }
      if (_.has(getters.sourceChangesObject, property)) return false;
      const rawProperty = toRawProperty(property);
      const pendingUpdates = _.get(state.item, 'pending_updates') || {};
      if (_.has(pendingUpdates, rawProperty)) {
        return pendingUpdates[rawProperty] !== null;
      }

      const pendingPriorityUpdates = _.get(state.item, 'pending_priority_updates') || {};
      if (_.has(pendingPriorityUpdates, rawProperty)) return false;

      const source = sourceFromRecord(state.item, property);
      return source === 'dashboard';
    };
  },

  isInheritedProperty() { // overriden at broadcast store
    return () => false;
  },

  isPropertySourceBeingUpdated(state) {
    return property =>
      _.has(state.item.pending_priority_updates, property);
  },

  isPropertyValueBeingUpdated(state) {
    return (property) => {
      const rawProperty = toRawProperty(property);
      return _.has(state.item.pending_updates, rawProperty);
    };
  },

  isPropertyBeingUpdated(state, getters) {
    return property =>
      getters.isPropertySourceBeingUpdated(property)
      || getters.isPropertyValueBeingUpdated(property);
  },

  itemProperty(state, getters) {
    return (property) => {
      if (getters.isEditedItemSourceChanged(property)) {
        const source = getters.changedPropertySource(property)
          || getters.defaultPropertySource(property);
        return getters.rawValueBySource(property, source);
      }
      if (getters.isPropertySourceBeingUpdated(property)) {
        const pendingSource = getters.pendingPropertySource(property)
          || getters.defaultPropertySource(property);
        return getters.rawValueBySource(property, pendingSource);
      }
      if (getters.isPropertyValueBeingUpdated(property)) {
        return getters.pendingValue(property);
      }
      return _.get(getters.editedItem, [property]);
    };
  },

  pendingValue(state, getters) {
    return (property) => {
      const rawProperty = toRawProperty(property);
      const pendingUpdate = _.get(getters.editedItem, ['pending_updates', rawProperty]);
      if (property !== 'logo') return pendingUpdate;
      if (pendingUpdate === null) return null;
      return {
        stdres_url: pendingUpdate && pendingUpdate[0],
        hires_url: pendingUpdate && pendingUpdate[0],
      };
    };
  },

  rawDataForField(state) {
    return (field) => {
      const { remotes, default_sources = {} } = state.item;
      const rawField = toRawProperty(field);
      const rawData = _(remotes)
        .reject(({ source }) => source === 'dashboard')
        .filter(remote => _.has(remote, rawField))
        .map((remote) => {
          const { source } = remote;
          const value = _.get(remote, remoteSourceValueProperty(field));
          return { source, value };
        })
        .map((sourceRawData) => {
          if (sourceRawData.source !== default_sources[field]) return sourceRawData;
          return { ...sourceRawData, default: true };
        })
        .value();
      return rawData;
    };
  },

  rawValueBySource(state, getters) {
    return (property, source) =>
      (getters.rawDataForField(property).find(item => item.source === source) || {}).value;
  },

  defaultPropertySource(state) {
    return (field) => {
      const { default_sources = {} } = state.item;
      return default_sources[field] || '';
    };
  },

  isPropertySourceDefault(state, getters) {
    return (field) => {
      if (getters.isPropertyOverridden(field)) return false;

      if (getters.isEditedItemSourceChanged(field)) {
        return getters.changedPropertySource(field) === null;
      }

      if (getters.isPropertySourceBeingUpdated(field)) {
        const pendingPriorityUpdate = _.get(getters.editedItem, ['pending_priority_updates', field]);
        return _.isEmpty(pendingPriorityUpdate);
      }

      const { priorities = {} } = state.item;
      const source = getters.propertySource(field);
      const defaultSource = getters.defaultPropertySource(field);
      if (source !== defaultSource) return false;
      return _.get(priorities, [field, source]) !== 11;
    };
  },

  isRestoreDefaultAvailable(state, getters) {
    return (field) => {
      if (_.isEmpty(getters.rawDataForField(field))) return false;
      return (
        !!getters.defaultPropertySource(field)
        && !getters.isPropertySourceDefault(field)
      );
    };
  },
});

const mutations = ({ stripEmptyOnChange = true } = {}) => {
  const itemMutations = item.mutations({ stripEmptyOnChange });

  return {
    ...itemMutations,

    addChange(state, { name, value }) {
      itemMutations.addChange(state, { name, value });
      const sourceChangeIndex = state.sourceChanges.findIndex(i => (i.name === name));
      if (sourceChangeIndex >= 0) state.sourceChanges.splice(sourceChangeIndex, 1);
    },

    addSourceChange(state, { name, source }) {
      // note: use addSourceChange action in the app instead!
      if (!name) throw new Error(`Invalid property name provided: ${name}`);

      const existingIndex = state.sourceChanges.findIndex(i => (i.name === name));
      if (existingIndex >= 0) state.sourceChanges.splice(existingIndex, 1);

      state.sourceChanges.push({ name, source });
    },

    clearSourceChanges(state) {
      state.sourceChanges = [];
    },
  };
};

const actions = ({ idKey = 'id' } = {}) => ({
  ...item.actions({ idKey }),

  addSourceChange({ commit, state }, { name, source }) {
    // if overridden value in changes state - clear it
    commit('clearChange', name);

    // if overriden value in conrad - clear it
    const currentSource = sourceFromRecord(state.item, name);
    if (currentSource === 'dashboard') {
      commit('addChange', { name, value: null });
    }
    // 'addSourceChange' change has to be called after 'addChange'
    // as the 'addChange' clears state.sourceChanges
    commit('addSourceChange', { name, source });
  },

  async updatePriorities({
    commit, getters, dispatch,
  }, { indicateProgress = true } = {}) {
    if (indicateProgress) commit('setSaving', true);

    try {
      const stationUpdateWait = await notifications.createWait(getters.updateNotificationTopic);

      const { data: updateResponse } = await api.updatePriorities(
        getters.updateResourcePath,
        getters.updateResourceId,
        getters.sourceChangesObject,
      );

      const updateExpectation = getters.updateSourceExpectation
        ? getters.updateSourceExpectation(updateResponse)
        : getters.updateExpectation(updateResponse);
      const waitResult = await stationUpdateWait(updateExpectation);
      await dispatch('propagateUpdate', waitResult);

      commit('clearSourceChanges');
    } finally {
      if (indicateProgress) commit('setSaving', false);
    }
  },
});

export default {
  state,
  getters,
  mutations,
  actions,
};
