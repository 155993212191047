import _ from 'lodash';
import {
  mapActions,
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';
/* eslint-disable no-multi-spaces */
import { PrioritiesEnum }      from '@/lib/recordPriorities';
import ModalMixin              from '@/components/common/ModalMixin';
import ConfirmDiscardModal     from './_ConfirmDiscardModal';
/* eslint-enable no-multi-spaces */

export default (store) => {
  const resourceType = store.split('/')[1];

  return {
    mixins: [ModalMixin],
    data() {
      return {
        editMode: false,
        error: null,
      };
    },
    computed: {
      ...mapState(store, ['item', 'saving']),
      ...mapState('screen', ['laptop']),

      ...mapGetters(store, ['isAnyChangeMade']),
      ...mapGetters('me', ['can']),
      ...mapGetters('records/remotes', {
        isLoadingRemotes: 'loading',
        remotes: 'remotes',
      }),

      isSubmitDisabled() {
        if (this.saving) return true;
        return this.item && this.editMode && !this.isAnyChangeMade;
      },
      isCancelDisabled() {
        return this.saving;
      },
    },
    watch: {
      item: {
        immediate: true,
        async handler() {
          if (!this.item?.id) return;
          if (!this.remotes(this.item)) await this.loadRemotes(this.item.id);
        },
      },
    },
    methods: {
      ...mapActions('records/remotes', ['loadRemotes']),
      ...mapMutations(store, ['setSaving', 'clearChanges', 'clearSourceChanges']),
      ...mapMutations('playback', { setPlayback: 'setActive' }),

      sourceOptions(field) {
        // sort sources by "priority"
        const sorted = _(this.propertySources(field))
          // don't show currently selected option
          .filter(source => source !== this.source
            || this.propertyPriorityBySource(field, source) !== PrioritiesEnum.Manual)
          .sortBy(source => this.propertyPriorityBySource(field, source))
          .map(source => ({
            name: source,
            value: this.propertyValueBySource(field, source),
          }))
          .value();

        // show default if it is not selected currently
        const defaultPropertySource = this.defaultPropertySource(field);
        if (!this.isPropertySourceDefault(field) && defaultPropertySource) {
          sorted.unshift({
            name: defaultPropertySource,
            value: this.propertyValueBySource(field, defaultPropertySource),
            default: true,
          });
        }

        return sorted;
      },
      async confirmLeaveEdit(mode) {
        if (this.isAnyChangeMade) {
          const userConfirmed = await new Promise((resolve) => {
            this.showModal(ConfirmDiscardModal, {
              confirmDiscardResolution: resolve,
              mode,
            });
          });

          if (userConfirmed) {
            this.$ga.event({
              eventCategory: `${resourceType}-details-edit`,
              eventAction: 'cancel',
            });
          }
        }

        if (this.editMode) {
          this.clearChanges();
          this.clearSourceChanges();
          this.setSaving(false);
          this.editMode = false;
        }
      },
      cancel() {
        this.confirmLeaveEdit();
      },
    },
    beforeDestroy() {
      this.setPlayback(false);
    },
  };
};
