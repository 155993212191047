import { throttle } from 'lodash';
import axios from 'axios';
import axiosInterceptor from '@ibiquity/dashboard.rollbar/src/axiosInterceptor';
import cognito from '../cognito';
import apiEngineering from '../api-engineering';

const { VUE_APP_API_V2_URL: baseURL } = process.env;

let languages = null;
let countries = null;
let genres = null;

const defaultPerPage = 100;

const axiosInstance = axios.create({ baseURL });

const withAuthorization = (config = {}) => ({
  ...config,
  headers: { Authorization: `Bearer ${cognito.accessToken}` },
});

axiosInterceptor.install(axiosInstance);
if (localStorage.getItem('_dev')) apiEngineering.install(axiosInstance);

const get = async (url, config) => {
  const response = await axiosInstance.get(url, withAuthorization(config));
  const { data, headers } = response;
  const {
    'x-total-count': total,
    'x-total-children-count': childrenTotal,
  } = headers || {};
  const metadata = {
    total: total && Number(total),
    childrenTotal: childrenTotal && Number(childrenTotal),
  };
  return { data, metadata };
};

const getItems = (resourcePath, {
  query,
  page = 1,
  sort = null,
  pageSize = defaultPerPage,
  pagination = true,
  withHighlights = false,
  include = null,
  ...others
} = {}, customParams = {}) => {
  const params = { q: query, sort, ...others };
  if (pagination) {
    params.page = page;
    params.per_page = pageSize || defaultPerPage;
  }
  if (withHighlights) params.highlights_css_class = 'highlighted-search-term';
  if (include) params.include = include;
  return get(`/${resourcePath}`, { params, ...customParams });
};

const getItemDetails = (itemsName, id) => get(`/${itemsName}/${id}`);

const getResource = url => get(`/records/resources/?url=${encodeURIComponent(url)}`);

const getUserInfo = () => get('/users/me');

const getGenres = async () => {
  if (!genres) {
    const response = await get('/records/genres');
    genres = response.data;
  }
  return genres;
};

const getLanguages = async () => {
  if (!languages) {
    const response = await get('/records/languages');
    languages = response.data.map(i => ({ id: i.a2, name: i.name }));
  }
  return languages;
};

const getCountries = async () => {
  if (!countries) {
    const response = await get('/records/countries');
    countries = response.data.map(i => ({ id: i.a2, name: i.name }));
  }
  return countries;
};

const getGeoSuggestions = phrase =>
  get(`/records/geo-suggestions?phrase=${encodeURIComponent(phrase)}`);

const updateItem = (resourcePath, id, changes) =>
  axiosInstance.patch(`/${resourcePath}/${id}`, changes, withAuthorization());

const throttledUpdateItem = throttle(
  (resourcePath, id, changes) => updateItem(resourcePath, id, changes),
  6000,
  { leading: false, trailing: true },
);

const updatePriorities = (resourcePath, id, changes) =>
  axiosInstance.patch(`/${resourcePath}/${id}/priorities`, changes, withAuthorization());

const resetUserPassword = id =>
  axiosInstance.post(`/users/${id}/password_reset`, null, withAuthorization());

const createItem = (resourcePath, values) =>
  axiosInstance.post(`/${resourcePath}`, values, withAuthorization());

const deleteItem = (resourcePath, id) =>
  axiosInstance.delete(`/${resourcePath}/${id}`, withAuthorization());

const assignItems = (parent, id, children, assignments) =>
  axiosInstance.post(`/${parent}/${id}/${children}`, assignments, withAuthorization());

const unassignItems = (parent, id, children, unassignments) =>
  axiosInstance.delete(`/${parent}/${id}/${children}`, withAuthorization({ data: unassignments }));

const getHumanFrequency = (band, modelFrequency) => {
  if (band === 'AM') return modelFrequency;
  if (band === 'FM' || band === 'DAB') return (modelFrequency / 1000);
  throw new Error('Unsupported band provided');
};

const getModelFrequency = (band, humanFrequency) => {
  if (band === 'AM') return humanFrequency;
  if (band === 'FM' || band === 'DAB') return (humanFrequency * 1000);
  throw new Error('Unsupported band provided');
};

const getTypes = () => ['analog', 'hdradio', 'dab'];

const getIotConfig = () =>
  axiosInstance.get('/notifications/config?clientPrefix=dashboard', withAuthorization())
    .then(({ data }) => data);

export default {
  get,
  getItems,
  getItemDetails,
  updateItem,
  throttledUpdateItem,
  updatePriorities,
  createItem,
  deleteItem,
  getGenres,
  getLanguages,
  getCountries,
  getGeoSuggestions,
  resetUserPassword,
  getUserInfo,
  getIotConfig,
  getResource,
  assignItems,
  unassignItems,

  getHumanFrequency,
  getModelFrequency,
  getTypes,

  axiosInstance, // for unit tests purpose
};
