import _ from 'lodash';

const join = (queries, connector = 'AND') => {
  if (_.isEmpty(queries)) return '';
  return queries.map(q => `(${q})`).join(` ${connector} `);
};

export default {
  join,
};
