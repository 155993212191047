import api from '@/services/api';
import loading from './loading.module';

const state = {
  ...loading.state(),

  types: [],
  genres: [],
  languages: [],
  countries: [],
};

/* eslint-disable no-param-reassign, no-shadow */
export const actions = {
  initDictionaries: async ({ commit }) => {
    commit('startLoading');
    try {
      const [types, genres, languages, countries] = await Promise.all([
        api.getTypes(),
        api.getGenres(),
        api.getLanguages(),
        api.getCountries(),
      ]);
      commit('setTypes', types);
      commit('setGenres', genres);
      commit('setLanguages', languages);
      commit('setCountries', countries);
    } catch (error) {
      commit('setError', 'Error loading dictionary data');
    }
    commit('endLoading');
  },
};

export const mutations = {
  ...loading.mutations(),

  setTypes(state, types) {
    state.types = types;
  },
  setGenres(state, genres) {
    state.genres = genres;
  },
  setLanguages(state, languages) {
    state.languages = languages;
  },
  setCountries(state, countries) {
    state.countries = countries;
  },
};
/* eslint-enable no-param-reassign, no-shadow */

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
