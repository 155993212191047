import ArrayedTextField from './ArrayedTextField';
import FieldMixin from './FieldMixin';
import ArrayedFieldMixin from './ArrayedFieldMixin';

export default {
  mixins: [
    FieldMixin,
    ArrayedFieldMixin,
  ],
  components: {
    ArrayedTextField,
  },
};
