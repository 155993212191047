import _ from 'lodash';
/* eslint-disable no-multi-spaces */
import Panel                   from '@/components/common/Panel';
import LogoField               from './_Fields/LogoField';
import TextField               from './_Fields/TextField';
import GenresField             from './_Fields/GenresField';
import LanguagesField          from './_Fields/LanguagesField';
/* eslint-enable no-multi-spaces */

export default {
  components: {
    Panel,
    LogoField,
    TextField,
    GenresField,
    LanguagesField,
  },

  props: {
    editMode: {
      type: Boolean,
      required: true,
    },
    property: {
      type: Function,
      default: () => {},
    },
    isPropertyBeingUpdated: {
      type: Function,
      default: () => false,
    },
    editedPropertySource: {
      type: Function,
      default: () => '',
    },
    rawDataForField: {
      type: Function,
      default: () => {},
    },
    isBroadcastOverridesMessageVisible: {
      type: Function,
      default: () => false,
    },
    isPropertySourceDefault: {
      type: Function,
      default: () => false,
    },
    isRestoreInheritedAvailable: {
      type: Function,
      default: () => false,
    },
    isRestoreDefaultAvailable: {
      type: Function,
      default: () => false,
    },
  },

  computed: {
    fieldHandlers() {
      return {
        change: ({ id, value }) => {
          if (id === 'regions') {
            this.$emit('value-changed', { id, value: (value === null ? value : [value]) });
          } else {
            this.$emit('value-changed', { id, value });
          }
        },
        'source-change': event => this.$emit('source-changed', event),
      };
    },
  },

  methods: {
    fieldProperties(field) {
      const rawData = field === 'regions'
        ? _.map(this.rawDataForField(field), ({ value, ...rest }) => ({ value: value[0], ...rest }))
        : this.rawDataForField(field);
      const value = field === 'regions'
        ? _.head(this.property(field))
        : this.property(field);
      return {
        editMode: this.editMode,
        contentId: this.contentId,
        isUpdated: this.isPropertyBeingUpdated(field),
        isRestoreDefaultAvailable: this.isRestoreDefaultAvailable(field),
        isRestoreInheritedAvailable: this.isRestoreInheritedAvailable(field),
        source: this.editedPropertySource(field),
        rawData,
        value,
        isSourceDefault: this.isPropertySourceDefault(field),
        isBroadcastOverridesMessageVisible: this.isBroadcastOverridesMessageVisible(field),
      };
    },
  },
};
