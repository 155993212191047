/* eslint-disable no-shadow */
import _ from 'lodash';
import items from '@/store/items.module';
import en from '@/locale/en';

const state = () => ({
  ...items.state(),

  sort: 'fired_at:desc',
  searchQuery: '',
  includeConrad: false,
});

const actions = ({ parentScope } = {}) => ({
  ...items.actions(),

  async resubmit({ dispatch }, activity) {
    const { new_values: payload } = activity;
    await dispatch(`${parentScope}/updateItem`, { payload }, { root: true });
  },
});

/* eslint-disable no-param-reassign */
const mutations = () => ({
  ...items.mutations(),

  setSearchQuery: (state, searchQuery) => {
    const localeMappings = _(en.station.itemProperties)
      .mapValues(value => value.toLowerCase())
      .invert()
      .value();

    const mapping = {
      ...localeMappings,
      website: 'website_urls',
      long: 'long_description',
    };

    const searchQueryLower = searchQuery.toLowerCase();
    state.searchQuery = mapping[searchQueryLower] ? mapping[searchQueryLower] : searchQuery;
  },

  setIncludeConrad: (state, includeConrad) => {
    state.includeConrad = includeConrad;
  },
});
/* eslint-enable no-param-reassign */

export default {
  state,
  actions,
  mutations,
};
