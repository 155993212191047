import _ from 'lodash';

export default {
  methods: {
    getRadioTypeLabel(radioType) {
      switch (radioType) {
        case 'analog':
          return 'Analog';
        case 'hdradio':
          return 'HD Radio';
        case 'dab':
          return 'DAB';
        default:
          return _.upperFirst(radioType);
      }
    },
  },
};
