import _ from 'lodash';
import { createRecordId } from '@ibiquity/dashboard.conrad-events';
import lucene from '@/utils/lucene';
import activitiesCommon from './activities.module';

export default () => ({
  namespaced: true,

  state: activitiesCommon.state(),

  getters: {
    extendedQuery(state, getters, rootState) {
      return () => {
        const { content_id } = _.get(rootState, 'station.item');
        const conditions = [
          lucene.join([
            'type:station.update AND updated_fields:*',
            'type:station.source_priorities_change AND new_priorities:*',
            'type:(station.organization_assign OR station.organization_unassign)',
          ], 'OR'),
          'fired_at:*',
          'NOT manual_sync:true',
          `content_id:${content_id}`,
        ];
        if (state.searchQuery) conditions.push(state.searchQuery);
        if (!state.includeConrad) conditions.push('NOT producer.type:conrad');
        return conditions.length ? lucene.join(conditions) : '';
      };
    },

    resourcePath(state, getters, rootState) {
      const { content_id } = _.get(rootState, 'station.item');
      const record_id = createRecordId({ content_id });
      return `activities/${record_id}`;
    },
  },

  actions: activitiesCommon.actions({ parentScope: 'station' }),

  mutations: activitiesCommon.mutations(),
});
