import _ from 'lodash';
import api from '@/services/api';

const actions = ({
  path,
  cachedItem,
  resourceType,
  idKey = 'id',
}) => ({
  async loadItem({ state, commit, rootState }, id) {
    if (id === state.item[idKey]) return;

    let item;
    if (cachedItem) {
      item = cachedItem(rootState, id);
    } else if (path) {
      const items = _.get(rootState, path);
      item = _.find(items, { [idKey]: id });
    }

    if (!item) {
      commit('startLoading');
      try {
        ({ data: item } = await api.getItemDetails(resourceType, id));
      } finally {
        commit('endLoading');
      }
    }
    commit('setItem', item);
  },
});

export default {
  actions,
};
