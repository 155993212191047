import api from '@/services/api';
import loading from '@/store/loading.module';

const resourcePath = 'solutions';

export default () => ({
  namespaced: true,
  state: {
    ...loading.state(),
    removingStepIds: [],
    solution: null,
  },
  mutations: {
    ...loading.mutations(),

    setSolution(state, solution) {
      state.solution = solution;
    },
    startStepRemoval(state, id) {
      state.removingStepIds.push(id);
    },
    endStepRemoval(state, id) {
      state.removingStepIds = state.removingStepIds.filter(rid => rid !== id);
    },
    addStep(state, step) {
      state.solution.steps.push(step);
    },
    removeStep(state, id) {
      state.solution.steps = state.solution.steps.filter(step => step.id !== id);
    },
  },
  actions: {
    async createSolution({ commit, dispatch }, cloneSolutionId = undefined) {
      const solution = await api.createItem(resourcePath, { solution_id: cloneSolutionId });
      dispatch('me/updateSolutionId', solution.id, { root: true });
      commit('setSolution', solution);
    },
    async loadSolution({ commit, dispatch, rootState }, solutionId) {
      const id = solutionId || rootState.me.solution_id;
      if (!id) throw new Error('Error while loading a solution: missing solution ID');
      if (solutionId) dispatch('me/updateSolutionId', solutionId, { root: true });
      commit('startLoading');
      commit('setError', null);
      try {
        const solution = await api.getItemDetails(resourcePath, id);
        commit('setSolution', solution);
      } catch (error) {
        commit('setError', 'Error while loading a solution');
        throw error;
      } finally {
        commit('endLoading');
      }
    },
    async createStep({ state, commit, dispatch }, step) {
      let { id: solutionId } = state.solution || {};
      if (!solutionId) {
        await dispatch('createSolution');
        solutionId = state.solution.id;
      }
      const storedStep = await api.createItem(`${resourcePath}/${solutionId}/steps`, step);
      commit('addStep', storedStep);
    },
    async removeStep({ state, commit }, id) {
      const { id: solutionId } = state.solution || {};
      if (!solutionId) throw new Error('Unable to remove a step: missing solution');
      commit('startStepRemoval', id);
      try {
        await api.deleteItem(`${resourcePath}/${solutionId}/steps`, id);
      } catch (error) {
        commit('setError', 'Error while removing step');
        throw error;
      } finally {
        commit('endStepRemoval', id);
      }
      commit('removeStep', id);
    },
  },
  getters: {
    isRemovingStep(state) {
      return id => state.removingStepIds.includes(id);
    },
    steps(state) {
      return state.solution?.steps || [];
    },
  },
});
