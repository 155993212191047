import _ from 'lodash';

export default {
  computed: {
    linkUrlLabel() {
      return property =>
        this.$t(`${this.resourcePath(property)}.linkUrl`);
    },

    ..._({
      header: 'header',
      editLabel: 'edit',
    })
      // eslint-disable-next-line func-names
      .mapValues(resource => () => function (property) {
        return this.$t(`${this.resourcePath(property)}.${resource}`);
      })
      .value(),
  },
  methods: {
    resourcePath(property) {
      return `station.arrayedItems.${_.camelCase(property)}`;
    },
  },
};
