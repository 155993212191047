import loading from '@/store/loading.module';
import api from '@/services/api';

/* eslint-disable no-param-reassign, no-shadow */
export default () => ({
  namespaced: true,
  state: {
    ...loading.state(),
    geoQuery: null,
    geoId: null,
    geoSuggestions: [],
  },
  mutations: {
    ...loading.mutations(),
    setGeoQuery: (state, geoQuery = null) => {
      state.geoQuery = geoQuery;
    },
    setGeoId: (state, geoId = null) => {
      state.geoId = geoId;
    },
    setGeoSuggestions: (state, geoSuggestions = []) => {
      state.geoSuggestions = geoSuggestions;
    },
  },
  actions: {
    loadGeoSuggestions: async ({ commit, state }) => {
      const { geoQuery } = state;
      try {
        commit('startLoading');
        const { data } = await api.getGeoSuggestions(geoQuery);
        commit('setGeoSuggestions', data);
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('endLoading');
      }
    },
  },
});
