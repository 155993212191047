/* eslint-disable no-console */
import EventEmitter from 'events';
import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
} from 'amazon-cognito-identity-js';

const {
  VUE_APP_USER_POOL_ID: UserPoolId,
  VUE_APP_CLIENT_ID: ClientId,
  VUE_APP_COGNITO_URL: CognitoEndpoint,
} = process.env;

export class CognitoService extends EventEmitter {
  constructor() {
    super();
    const userPoolConfig = { UserPoolId, ClientId };
    if (CognitoEndpoint) userPoolConfig.endpoint = CognitoEndpoint;

    this.userpool = new CognitoUserPool(userPoolConfig);
    this.cognitoUser = null;
    this.scheduler = null;
  }

  login(Username, Password, AccountSetup = {}) {
    // This is because Cognito treats even email login as case sensitive
    Username = Username.toLowerCase(); // eslint-disable-line no-param-reassign

    this.cognitoUser = new CognitoUser({ Username, Pool: this.userpool });

    const authenticationData = { Username, Password };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const { cognitoUser } = this;

    return new Promise((resolve, reject) => {
      this.cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess(result) {
          localStorage.setItem('LastUserId', cognitoUser.username);
          resolve(result);
        },
        newPasswordRequired() {
          const { NewPassword, UserAttributes = {} } = AccountSetup;
          cognitoUser.completeNewPasswordChallenge(NewPassword, UserAttributes, this);
        },
        onFailure(error) {
          reject(error);
        },
      });
    }).then((session) => {
      this.scheduleRefresh();
      return session;
    });
  }

  async initSession() {
    if (this.currentSession) return this.currentSession;

    const lastUserId = localStorage.getItem('LastUserId');
    if (!lastUserId) return Promise.resolve();
    this.cognitoUser = new CognitoUser({ Username: lastUserId, Pool: this.userpool });

    return new Promise((resolve, reject) => {
      this.cognitoUser.getSession((error, session) => {
        if (error) return reject(error);
        return resolve(session);
      });
    });
  }

  get currentSession() {
    return this.cognitoUser && this.cognitoUser.signInUserSession;
  }

  get accessToken() {
    return this.currentSession && this.currentSession.getAccessToken().getJwtToken();
  }

  get refreshToken() {
    return this.currentSession && this.currentSession.getRefreshToken();
  }

  logout() {
    this.clearScheduler();
    if (this.cognitoUser) this.cognitoUser.signOut();
  }

  async isLoggedIn() {
    try {
      await this.initSession();
      return !!this.currentSession && this.sessionExpiration() > 0;
    } catch (error) {
      return false;
    }
  }

  sessionExpiration() {
    const token = this.currentSession && this.currentSession.getAccessToken();
    if (!token) return 0;
    return (token.getExpiration() * 1000) - Date.now();
  }

  refreshSession() {
    return new Promise((resolve, reject) => {
      /* eslint-disable no-param-reassign */
      const handleRefreshFail = (error) => {
        console.warn(`Refresh token failed: ${error.message}`);
        this.emit('refresh:failed');
        reject(error);
      };
      /* eslint-enable no-param-reassign */

      if (!this.refreshToken) {
        handleRefreshFail(new Error('Missing refresh token'));
        return;
      }

      this.cognitoUser.refreshSession(this.refreshToken, (error, newSession) => {
        if (error) handleRefreshFail(error);
        else resolve(newSession);
      });
    });
  }

  clearScheduler = () => {
    if (this.scheduler) {
      clearTimeout(this.scheduler);
      this.scheduler = null;
    }
  }

  scheduleRefresh({ force = false } = {}) {
    if (this.scheduler && !force) return;
    this.clearScheduler();

    const expirationMsec = this.sessionExpiration();
    const minuteMsec = 60 * 1000;
    const delay = Math.min(
      Math.max(expirationMsec - minuteMsec, 0), // refresh 1 minute before expiration
      2147483647, // maximum 32 bit integer delay
    );
    if (Number.isNaN(delay)) return;

    const handler = () =>
      this.refreshSession()
        .then(() =>
          this.scheduleRefresh({ force: true }));
    this.scheduler = setTimeout(handler, delay);
  }

  changePassword(Username, VerificationCode, NewPassword) {
    this.cognitoUser = new CognitoUser({ Username, Pool: this.userpool });
    return new Promise((resolve, reject) =>
      this.cognitoUser.confirmPassword(VerificationCode, NewPassword, {
        onSuccess: resolve,
        onFailure: reject,
      }));
  }

  requestPasswordChange = (Username) => {
    this.cognitoUser = new CognitoUser({ Username, Pool: this.userpool });
    return new Promise((resolve, reject) => {
      this.cognitoUser.forgotPassword({
        onSuccess(result) {
          resolve(result);
        },
        onFailure(error) {
          reject(error);
        },
      });
    });
  }
}

export default new CognitoService();
