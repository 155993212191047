import items from '@/store/items.module';
import csvExport from '@/store/csv-export.module';
import recordsCommonPartial from '@/store/records/common.module';

const resourceType = 'records';
const mode = 'contents';

const records = ['content'];
const sources = ['remote_content'];
const scopes = { records, sources, all: records.concat(sources) };

/* eslint-disable no-param-reassign, no-shadow */
export default () => ({
  namespaced: true,
  state: {
    ...items.state(),
    ...csvExport.state(),
  },
  mutations: {
    ...items.mutations(),
    ...csvExport.mutations(),
  },
  actions: {
    ...items.actions({
      resourceType,
      defaultPageSize: 50,
      withHighlights: true,
    }),
    ...csvExport.actions({ resourceType: `${resourceType}/${mode}` }),
  },
  getters: {
    ...items.getters({ resourceType }),
    ...recordsCommonPartial.getters({ mode, scopes }),
  },
});
