import _ from 'lodash';

const CreateMixin = ({
  emptyDefault = false,
  withPagination = true,
  withInitialSearch = true,
  additionalUrlParameters = [],
} = {}) => {
  const urlParameters = [{
    paramName: 'query',
    paramId: 'q',
    type: String,
    default: undefined,
    searchParam: true,
  }, {
    paramName: 'page',
    paramId: 'p',
    type: Number,
    default: withPagination ? 1 : undefined,
  }, {
    paramName: 'sort',
    paramId: 's',
    type: String,
    default: undefined,
  }, ...additionalUrlParameters];

  return {
    props: _(urlParameters)
      .mapKeys(({ paramId }) => paramId)
      .mapValues(parameter => _.pick(parameter, ['default', 'type']))
      .value(),
    computed: {
      searchParams() {
        return _(urlParameters)
          .filter(({ searchParam }) => searchParam)
          .mapKeys(({ paramId }) => paramId)
          .mapValues(({ paramId, default: defaultValue }) => this[paramId] || defaultValue)
          .value();
      },
      isAnySearchParamSet() {
        const searchParams = urlParameters
          .filter(({ searchParam }) => searchParam)
          .filter(({ paramId }) => this[paramId]);
        return !!searchParams.length;
      },
    },
    methods: {
      handleSearch({ query }, others = {}) {
        this.$router.push({
          query: {
            ...this.searchParams,
            ...others,
            q: query || undefined,
          },
        });
      },
      changeQueryParam(paramName, value) {
        if (this[paramName] === value) return;
        this.$router.push({
          query: {
            ..._(urlParameters)
              .mapKeys(({ paramId }) => paramId)
              .mapValues(({ paramId }) => this[paramId])
              .value(),
            [paramName]: value || undefined,
          },
        });
      },
      searchItems(customParams = { type: 'records' }) {
        if (emptyDefault && !this.isAnySearchParamSet) return this.clearItems();

        const params = {
          ..._(urlParameters)
            .mapKeys(({ paramName }) => paramName)
            .mapValues(({ paramId }) => this[paramId])
            .omitBy(_.isUndefined)
            .value(),
          ...customParams,
        };

        return this.loadItems(params);
      },
      ..._(urlParameters)
        .mapKeys(({ paramName }) => `${paramName}Changed`)
        // eslint-disable-next-line func-names
        .mapValues(({ paramId, type }) => function (newValue) {
          const adjustedNewValue = (type === Number && newValue <= 1)
            ? undefined
            : newValue;
          this.changeQueryParam(paramId, adjustedNewValue);
        })
        .value(),
    },
    mounted() {
      if (!withInitialSearch) return null;
      return this.searchItems();
    },
    watch: _(urlParameters)
      .mapKeys(({ paramId }) => paramId)
      // eslint-disable-next-line func-names
      .mapValues(() => (function () {
        this.clearItems();
        this.searchItems();
      }))
      .value(),
  };
};

export default {
  ...CreateMixin(),
  Create: CreateMixin,
};
