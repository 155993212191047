import ArrayedUrlField from './ArrayedUrlField';
import FieldMixin from './FieldMixin';
import ArrayedFieldMixin from './ArrayedFieldMixin';

export default {
  mixins: [
    FieldMixin,
    ArrayedFieldMixin,
  ],
  components: {
    ArrayedUrlField,
  },
};
