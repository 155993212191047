import AwsIot from 'aws-iot-device-sdk';
import _ from 'lodash';
import EventEmitter from 'events';
import Rollbar from '@ibiquity/dashboard.rollbar';
import api from './api';

const topicWildcard = '+';

const createConfig = () =>
  api.getIotConfig()
    .then(config => ({ protocol: 'wss', ...config }))
    .catch(() => null);

const subscribeDeviceTopic = (device, topic) => {
  if (!device) return null;
  return new Promise((resolve, reject) => {
    device.subscribe(topic, { qos: 1 }, err =>
      (err ? reject(err) : resolve()));
  });
};

const refreshCredentialsPeriod = 1 * (60 - 5) * 60 * 1000; // 55 minutes

const topicToRegex = topic =>
  new RegExp(`^${topic.replace(/\+/g, '[^/]+')}$`);

class Notifications extends EventEmitter {
  static topic(resourceType, id, organizationId) {
    if (resourceType === 'organizations') {
      return `dashboard/organizations/${id}`;
    }
    return `dashboard/${resourceType}/${organizationId || 'xperi'}/${id}`;
  }

  constructor() {
    super();
    this.subscribers = {};
    this.device = null;
    this.reinitializationScheduler = null;
  }

  async initDevice() {
    const config = await createConfig();
    if (!config) return null;
    const device = AwsIot.device(config);
    device.on('message', (topic, payload) => {
      _.forEach(this.subscribers, (subscriber, subscriberTopic) => {
        if (!subscriber.topicRegExp.test(topic)) return;
        this.emit(subscriberTopic, JSON.parse(payload.toString()));
      });
    });
    device.on('error', (error) => {
      Rollbar.error('Error coming from IoT Device:', error);
    });
    return device;
  }

  async reinitialize() {
    const newDevice = await this.initDevice();
    const subscribedTopics = Object.keys(this.subscribers);
    await Promise.all(subscribedTopics
      .filter(topic => this.subscribers[topic].handlers.length)
      .map(topic => subscribeDeviceTopic(newDevice, topic)));
    this.device.end();
    this.device = newDevice;
  }

  async initialize() {
    this.device = await this.initDevice();
    this.reinitializationScheduler = setInterval(() =>
      this.reinitialize(), refreshCredentialsPeriod);
  }

  clear() {
    if (this.device) this.device.end();
    clearInterval(this.reinitializationScheduler);
  }

  async subscribe(topic, cb) {
    if (!this.device) return;
    await subscribeDeviceTopic(this.device, topic);
    if (!this.subscribers[topic]) {
      this.subscribers[topic] = {
        topicRegExp: topicToRegex(topic),
        handlers: [],
      };
    }
    this.subscribers[topic].handlers.push(cb);
    this.addListener(topic, cb);
  }

  async unsubscribe(topic, cb) {
    if (!this.subscribers[topic]) return;
    const { handlers } = this.subscribers[topic];
    const callbackIndex = handlers.indexOf(cb);
    if (callbackIndex >= 0) handlers.splice(callbackIndex, 1);

    this.removeListener(topic, cb);
    if (handlers.length > 0) return;
    await new Promise((resolve, reject) => {
      this.device.unsubscribe(topic, err => (err ? reject(err) : resolve()));
    });
  }

  async createWait(topic) {
    let resolve;
    let reject;
    let expectations;
    let results = [];
    const fulfilled = new Promise((_resolve, _reject) => {
      resolve = _resolve;
      reject = _reject;
    });
    const onNotification = async (event = {}) => {
      try {
        if (!expectations?.length) return;

        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < expectations.length; index++) {
          const expectation = expectations[index];
          const handler = (_.isFunction(expectation.handler) && expectation.handler)
            || (_.isFunction(expectation) && expectation);
          const { operation } = expectation;
          // eslint-disable-next-line no-continue
          if (results[index]) continue;
          if (!operation || _.castArray(operation).includes(event.operation)) {
            // eslint-disable-next-line no-await-in-loop
            results[index] = handler ? await handler(event) : event;
          }
        }
        if (!results.every(result => result)) return;

        await this.unsubscribe(topic, onNotification);
        resolve(results.length === 1 ? results[0] : results);
      } catch (error) {
        await this.unsubscribe(topic, onNotification);
        reject(error);
      }
    };
    await this.subscribe(topic, onNotification);

    return (_expectations = () => true) => {
      expectations = _.castArray(_expectations);
      results = expectations.map(() => false);
      return fulfilled;
    };
  }
}

export default new Notifications();
export { Notifications, topicWildcard };
