import api from '@/services/api';

const asyncSuggestions = async (phrase) => {
  const { data: suggestions } = await api.getGeoSuggestions(phrase);
  return suggestions.map(suggestion => ({
    action: 'submit',
    id: suggestion.location_id,
    query: suggestion.name,
    description: suggestion.address,
  }));
};

export default { asyncSuggestions };
