const state = () => ({
  loading: false,
  error: null,
});

/* eslint-disable no-param-reassign, no-shadow */
const mutations = () => ({
  startLoading(state) {
    state.loading = true;
  },
  endLoading(state) {
    state.loading = false;
  },
  setError(state, error) {
    state.error = error;
  },
});
/* eslint-enable no-param-reassign, no-shadow */

export default {
  state,
  mutations,
};
