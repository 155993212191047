import { render, staticRenderFns } from "./_OrganizationLogo.vue?vue&type=template&id=73842157&scoped=true&"
import script from "./_OrganizationLogo.vue?vue&type=script&lang=js&"
export * from "./_OrganizationLogo.vue?vue&type=script&lang=js&"
import style0 from "./_OrganizationLogo.vue?vue&type=style&index=0&id=73842157&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73842157",
  null
  
)

export default component.exports