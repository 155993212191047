import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations('organization/member', ['clearChanges']),
  },
  beforeRouteLeave(to, from, next) {
    this.clearChanges();
    next();
  },
  beforeRouteUpdate(to, from, next) {
    this.clearChanges();
    next();
  },
};
