import Vue from 'vue';
import Vuex from 'vuex';
import { PlaybackStore as playback } from '@ibiquity/dashboard.audio-player';
import dictionaries from './dictionaries';
import me from './me';
import screen from './screen';
import station from './station';
import stations from './stations';
import records from './records';
import record from './record';
import organization from './organization';
import organizations from './organizations';
import featureFlags from './featureFlags';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    me: me(),
    dictionaries,
    screen,
    stations: stations(),
    station: station(),
    records: records(),
    record: record(),
    organization: organization(),
    organizations: organizations(),
    playback,
    featureFlags: featureFlags(),
  },
  strict: process.env.NODE_ENV !== 'production',
});

export default store;
