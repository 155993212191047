import operators from './operators';

const all = [
  {
    keyword: 'email', placeholder: 'value', description: 'Find user with given email', importance: 1,
  },
  {
    keyword: 'name', placeholder: 'value', description: 'Find user with given name', importance: 1,
  },
  {
    keyword: 'role', placeholder: 'value', description: 'Find user with given role', importance: 2,
  },
  {
    keyword: 'enabled:true', description: 'Find enabled users', importance: 2,
  },
  {
    keyword: 'accepted_agreements.eula:true', description: 'Find users that accepted EULA', importance: 3,
  },
  {
    keyword: 'accepted_agreements.privacy_policy:true', description: 'Find users that accepted privacy policy', importance: 3,
  },
];

export default { defaults: all, all: all.concat(operators) };
