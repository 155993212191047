var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-header"},[_vm._l((_vm.columns),function({
      name,
      headerType,
      fixedLeft,
      fixedRight,
    },index){return _c(_vm.headerComponent(headerType),{directives:[{name:"resizable",rawName:"v-resizable",value:({ resizable: _vm.isResizable(_vm.columns[index]) }),expression:"{ resizable: isResizable(columns[index]) }"}],key:_vm.key(name),tag:"component",class:['cell', {
      'fixed-left': fixedLeft,
      'fixed-right': fixedRight,
    }],attrs:{"column":_vm.columns[index]},on:{"sort":function($event){return _vm.$emit('sort', $event)},"resize":function($event){return _vm.handleResize(name, $event)}}})}),_c('dash-table-actions-header',{staticClass:"cell fixed-right",attrs:{"mode":_vm.mode},on:{"horizontal-scroll":function($event){return _vm.$emit('horizontal-scroll', $event)}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }