import operators from './operators';

const all = [
  {
    keyword: 'name', placeholder: 'name', description: 'Find organizations with given name', importance: 1,
  },
  {
    keyword: 'content_ids', placeholder: 'content_id', description: 'Find organization with given station assigned', importance: 1,
  },
  {
    keyword: 'id', placeholder: 'organization_id', description: 'Find organization with given id', importance: 2,
  },
];

export default { defaults: all, all: all.concat(operators) };
