import _ from 'lodash';
/* eslint-disable no-multi-spaces */
import Panel                   from '@/components/common/Panel';
import LogoField               from './_Fields/LogoField';
import GenresField             from './_Fields/GenresField';
import LanguagesField          from './_Fields/LanguagesField';
import TextField               from './_Fields/TextField';
/* eslint-enable no-multi-spaces */

export default {
  components: {
    Panel,
    LogoField,
    TextField,
    GenresField,
    LanguagesField,
  },

  props: {
    editMode: {
      type: Boolean,
      required: true,
    },
    property: {
      type: Function,
      default: () => ({}),
    },
    isPropertyBeingUpdated: {
      type: Function,
      default: () => false,
    },
    editedPropertySource: {
      type: Function,
      default: () => '',
    },
    isPropertySourceDefault: {
      type: Function,
      default: () => false,
    },
    propertySources: {
      type: Function,
      default: () => [],
    },
    sourceOptions: {
      type: Function,
      default: () => [],
    },
    isRestoreInheritedAvailable: {
      type: Function,
      default: () => false,
    },
    isRestoreDefaultAvailable: {
      type: Function,
      default: () => false,
    },
  },

  computed: {
    fieldHandlers() {
      return {
        change: ({ id, value }) => {
          if (id === 'regions') {
            this.$emit('value-changed', { id, value: (value === null ? value : [value]) });
          } else {
            this.$emit('value-changed', { id, value });
          }
        },
        'source-change': event => this.$emit('source-changed', event),
      };
    },
  },

  methods: {
    fieldProperties(field) {
      const value = field === 'regions'
        ? _.head(this.property(field))
        : this.property(field);
      return {
        editMode: this.editMode,
        item: this.item,
        contentId: this.contentId,
        isPropertyBeingUpdated: this.isPropertyBeingUpdated(field),
        isRestoreDefaultAvailable: this.isRestoreDefaultAvailable(field),
        isRestoreInheritedAvailable: this.isRestoreInheritedAvailable(field),
        source: this.editedPropertySource(field),
        value,
        propertySources: this.propertySources(field),
        isSourceDefault: this.isPropertySourceDefault(field),
        sourceOptions: this.sourceOptions(field),
        'data-test-panel-item': field,
      };
    },
  },
};
