export const extractQueryParams = (route) => {
  const props = {};
  if (route.query.p && !Number.isNaN(Number(route.query.p))) {
    props.p = Number(route.query.p);
  }
  ['q', 's', 'lq', 'l', 'lp'].forEach((prop) => {
    if (route.query[prop]) {
      props[prop] = String(route.query[prop]);
    }
  });
  return props;
};

const isView = (route => route.name && route.name.endsWith('details-view'));

const isEdit = (route => route.name && route.name.endsWith('details-edit'));

export const toEditMode = (to, from) => isEdit(to) && isView(from);

export const fromEditMode = (to, from) => isEdit(from) && isView(to);

export const editModeTransition = (to, from) => toEditMode(to, from) || fromEditMode(to, from);

export default {
  extractQueryParams,

  toEditMode,
  fromEditMode,
  editModeTransition,
};
