export default {
  props: {
    keyAttribute: {
      type: String,
      default: 'id',
    },
    nestedItemsAttribute: {
      type: String,
      default: '',
    },
    nestedItemKeyAttribute: {
      type: String,
      default: '',
    },
    columns: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: 'viewer',
    },
    depth: {
      type: Number,
      default: 1,
    },
    expectedProperties: {
      type: [Array, Function],
      default: () => [],
    },
    withInheritedHighlights: {
      type: Boolean,
      default: false,
    },
  },
};
