var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"record",class:[_vm.rowType, { 'overridden': _vm.item.source === 'dashboard' }],on:{"click":_vm.handleRowClick}},[(_vm.hasNestedRowOffset || _vm.hasRemoteRowOffset)?_c('div',{staticClass:"row-gap",class:{ 'nested-row-gap': _vm.hasNestedRowOffset, 'remote-row-gap': _vm.hasRemoteRowOffset }}):_vm._e(),_vm._l((_vm.columns),function({
      name,
      fixedLeft,
      fixedRight,
      type,
      params,
      component,
      centered,
      narrow,
      defaultWidth,
      width,
    }){return _c(_vm.cellComponent(component, type),_vm._b({key:name,tag:"component",class:{
      centered,
      narrow,
      'fixed-left': fixedLeft,
      'fixed-right': fixedRight,
    },style:(_vm.columnStyle(_vm.calcWidth(width || defaultWidth, fixedLeft))),attrs:{"name":name,"item":_vm.item,"parent-item":_vm.parentItem,"normalize-missing-value":!_vm.isDashboardRemote,"placeholder-disabled":_vm.isDashboardRemote,"expected-properties":_vm.expectedProperties,"with-inherited-highlights":_vm.withInheritedHighlights},nativeOn:{"click":function($event){return _vm.handleCellClick.apply(null, arguments)}}},'component',params,false))}),_c('dash-table-record-actions-cell',{staticClass:"fixed-right",style:(_vm.actionsStyle),attrs:{"mode":_vm.mode,"item":_vm.item,"remotes-expanded":_vm.remotesExpanded},on:{"toggle-remotes":function($event){return _vm.$emit('toggle-remotes', $event)},"source-tooltip-show":function($event){return _vm.$emit('source-tooltip-show')},"source-tooltip-hide":function($event){return _vm.$emit('source-tooltip-hide')}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }