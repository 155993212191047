export default (htmlContent) => {
  const printable = document.createElement('div');
  printable.id = 'printable';
  printable.innerHTML = `
    <style>
      @media screen {
        body > #printable { display: none; }
      }
      @media print {
        body > * { display: none; }
        body > #printable { display: block; }
      }
    </style>
    ${htmlContent}
  `;

  document.body.appendChild(printable);
  window.print();
  printable.remove();
};
