export default mode => ({
  props: {
    storeNamespace: {
      type: String,
      default: 'records',
    },
    columnNames: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    state() {
      return this.$store.state[this.storeNamespace][mode];
    },
    modalProps() {
      return {
        exporting: this.state.exporting,
        error: this.state.exportError,
        description: this.$t(`records.csv.exportModals.${mode}Description`),
      };
    },
    modalEvents() {
      return {
        download: () => this.download(),
        retry: () => this.exportProjectedItems(),
        close: () => this.$emit('close'),
      };
    },
  },
  async created() {
    await this.exportProjectedItems();
  },
  methods: {
    exportItems(options) {
      return this.$store.dispatch(`${this.storeNamespace}/${mode}/exportItems`, options);
    },
    download() {
      return this.$store.dispatch(`${this.storeNamespace}/${mode}/download`);
    },
    async exportProjectedItems() {
      const orderAdjustedColumnNames = [...this.columnNames];
      if (orderAdjustedColumnNames[0] === 'logo') {
        orderAdjustedColumnNames.push(orderAdjustedColumnNames.shift());
      }
      const include = orderAdjustedColumnNames.length && orderAdjustedColumnNames.join(',');
      await this.exportItems({ include });
    },
  },
});
