import _ from 'lodash';

import api from '@/services/api';
import notifications, { Notifications, topicWildcard } from '@/services/notifications';
import item from '@/store/item.module';
import itemFromApi from '@/store/item-from-api.module';

import members from './members';
import member from './member';
import stations from './stations';
import assignableStations from './assignableStations';

export default () => ({
  namespaced: true,

  state: {
    ...item.state(),
    creatingMember: false,
  },

  getters: {
    ...item.getters({ resourceType: 'organizations' }),

    owner(state) {
      const { owners = [] } = state.item;
      return owners[0];
    },
    hasOwner(state, getters) {
      return !!getters.owner;
    },
    currentUserOrganization(state, getter, rootState) {
      return state.item.id === rootState.me.organization_id;
    },
    updateNotificationTopic(state) {
      return Notifications.topic('organizations', state.item.id, null);
    },
  },

  /* eslint-disable no-param-reassign */
  mutations: {
    ...item.mutations(),
    setCreatingMember(state, creating) {
      state.creatingMember = creating;
    },
  },
  /* eslint-enable no-param-reassign */

  actions: {
    ...item.actions({ resourceType: 'organizations' }),
    ...itemFromApi.actions({ resourceType: 'organizations', path: 'organizations.items' }),

    async propagateUpdate({
      state, getters, commit, dispatch,
    }) {
      const { data: organization } = await api.getItemDetails('organizations', state.item.id);
      commit('setItem', organization);
      commit('organizations/swapItem', { item: organization }, { root: true });
      if (getters.currentUserOrganization) {
        await dispatch('me/getMyProfile', {}, { root: true });
      }
    },

    async createMember({ state, commit, dispatch }, payload) {
      commit('setCreatingMember', true);
      try {
        const parentId = state.item.id;
        const organizationTopic = Notifications.topic('organizations', parentId, null);
        const usersTopic = Notifications.topic('users', topicWildcard, parentId);

        const [organizationWait, userWait] = await Promise.all([
          notifications.createWait(organizationTopic),
          notifications.createWait(usersTopic),
        ]);

        const { data: { id: userId } } = await api.createItem('users', {
          ...payload, organization_id: parentId,
        });

        await Promise.all([
          organizationWait(async () => {
            const { data: { member_ids } } = await api.getItemDetails('organizations', parentId);
            return _.includes(member_ids, userId);
          }),
          userWait(async () => {
            const { data: user } = await api.getItemDetails('users', userId);
            return user.id === userId;
          }),
        ]);

        const { data: organization } = await api.getItemDetails('organizations', parentId);
        commit('setItem', organization);
        commit('organizations/swapItem', { item: organization }, { root: true });
        await dispatch('organization/members/loadItems', { force: true }, { root: true });
      } finally {
        commit('setCreatingMember', false);
      }
    },
  },

  modules: {
    members: members(),
    member: member(),
    stations: stations(),
    assignableStations: assignableStations(),
  },
});
