import station from './station';
import viewer from './viewer';
import editor from './editor';

export default () => ({
  namespaced: true,
  modules: {
    station: station(),
    viewer: viewer(),
    editor: editor(),
  },
});
