import { mapState } from 'vuex';

export default (stickyElementRef, offset = 60) => ({
  props: {
    withShadow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      scrollGuard: false,
      isSticked: false,
      addShadow: false,
    };
  },
  computed: {
    ...mapState('screen', ['laptop']),
  },
  mounted() {
    window.addEventListener('scroll', this.scrollHandler);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
  methods: {
    scrollHandler() {
      if (!this.scrollGuard) {
        window.requestAnimationFrame(() => {
          const stickyEl = this.$refs[stickyElementRef];
          if (!stickyEl) return;

          // 60 is current SCSS's $menu-size value and 64 for laptop, +1 of bottom border
          const sizeLimit = offset + 1 + (this.laptop ? 4 : 0);

          this.isSticked = stickyEl.getBoundingClientRect().top <= sizeLimit;
          this.addShadow = this.withShadow && stickyEl.getBoundingClientRect().top <= sizeLimit;
          this.scrollGuard = false;
        });
      }
      this.scrollGuard = true;
    },
  },
});
