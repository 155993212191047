import _ from 'lodash';
import RecordsTableIdCell from '../_Cells/IdCell';
import RecordsTableLogoCell from '../_Cells/LogoCell';
import { columns as viewerColumns } from './viewer';
import { columns as sharedColumns } from './shared';

/* eslint-disable object-curly-newline, global-require */
const overridenColumns = ['logo', 'broadcast_id'];

const viewerOnlyColumns = _(viewerColumns)
  .reject(({ name }) => _.find(sharedColumns, { name }))
  .reject(({ name }) => overridenColumns.includes(name))
  .value();

const columns = [
  {
    name: 'id',
    header: 'Hierarchy / ID #',
    component: RecordsTableIdCell,
    defaultWidth: 166,
    sortable: true,
    exportable: true,
    fixedLeft: true,
    exportableAs: ['broadcast_id', 'content_id'],
    params: { sortKey: 'content_id' },
  },
  ...sharedColumns,
  ...viewerOnlyColumns,
  {
    name: 'logo',
    header: 'Logotype',
    defaultWidth: 75,
    component: RecordsTableLogoCell,
    exportable: true,
    params: {
      mode: 'editor',
    },
  },
];

const order = [
  'id',
  'logo',
  'name',
  'call_sign',
  'type',
  'freq',
  'country',
  'regions',
  'city',
  'status',
  'updated_at',
  'slogan',
  'genre_ids',
  'languages',
  'streams',
  'broadcast_type',
  'band',
  'channel',
  'ecc',
  'gcc',
  'pi_code',
  'bandwidth',
  'eid',
  'sid',
  'scids',
  'fcc_id',
  'hd_location',
  'has_live_data',
  'use_live_unverified',
  'format',
  'description',
  'long_description',
  'website_urls',
  'facebook_urls',
  'twitter_urls',
  'address',
  'local_name',
  'owner',
  'email',
  'phone',
  'sms',
  'actions',
];

export { columns, order };
