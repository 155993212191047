import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';

export default (store) => {
  const resourceType = store.split('/')[0];

  return {
    computed: {
      ...mapState(store, ['saving']),
      ...mapGetters(store, ['valuesChanged', 'sourcesChanged']),
    },

    methods: {
      ...mapMutations(store, ['setSaving', 'clearChanges', 'clearSourceChanges']),
      ...mapActions(store, ['updateItem', 'updatePriorities']),

      async submit() {
        this.setSaving(true);
        try {
          if (this.valuesChanged) await this.updateItem({ indicateProgress: false });
          if (this.sourcesChanged) await this.updatePriorities({ indicateProgress: false });
          this.$ga.event({
            eventCategory: `${resourceType}-edit`,
            eventAction: 'submit',
          });
          this.showSuccess();
        } catch (e) {
          this.showError();
        } finally {
          this.setSaving(false);
        }
      },

      cleanup(to, from, next) {
        if (this.saving) {
          this.clearChanges();
          this.clearSourceChanges();
          this.setSaving(false);
        }
        next();
      },
    },

    beforeRouteLeave(to, from, next) {
      return this.cleanup(to, from, next);
    },

    beforeRouteUpdate(to, from, next) {
      return this.cleanup(to, from, next);
    },
  };
};
