/* eslint-disable no-multi-spaces */
import MeProfile            from '@/components/views/Me/Profile/Index';
import MeOrganization       from '@/components/views/Me/Organization/Index';
import MeOrganizationMember from '@/components/views/Me/Organization/Member/Index';
/* eslint-enable no-multi-spaces */

export const meRoutes = [
  {
    path: 'me/profile',
    name: 'me-profile-view',
    component: MeProfile,
  },
  {
    path: 'me/profile/edit',
    name: 'me-profile-edit',
    component: MeProfile,
    props: {
      editMode: true,
    },
  },
  {
    path: 'me/organization',
    name: 'me-organization',
    component: MeOrganization,
  },
  {
    path: 'me/organization/members/:memberId',
    name: 'me-organization-member-view',
    component: MeOrganizationMember,
  },
  {
    path: 'me/organization/members/:memberId/edit',
    name: 'me-organization-member-edit',
    component: MeOrganizationMember,
    props: {
      editMode: true,
    },
  },
];

export default { meRoutes };
