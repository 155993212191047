import { mapMutations, mapActions } from 'vuex';
import _ from 'lodash';

export default ({
  methods: {
    ...mapMutations('station', {
      addStationChange: 'addChange',
    }),
    ...mapMutations('station/broadcast', {
      addBroadcastChange: 'addChange',
    }),
    ...mapActions('station', {
      addStationSourceChange: 'addSourceChange',
    }),
    ...mapActions('station/broadcast', {
      addBroadcastSourceChange: 'addSourceChange',
    }),

    ..._({
      stationValueChanged: 'addStationChange',
      stationSourceChanged: 'addStationSourceChange',
      broadcastValueChanged: 'addBroadcastChange',
      broadcastSourceChanged: 'addBroadcastSourceChange',
    })
      // eslint-disable-next-line func-names
      .mapValues(handler => function ({ id, ...changes } = {}) {
        if (id) {
          this[handler]({ name: id, ...changes });
          return true;
        }
        return false;
      })
      .value(),
  },
});
