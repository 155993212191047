import { render, staticRenderFns } from "./_MemberDetails.vue?vue&type=template&id=d5b13bca&scoped=true&"
import script from "./_MemberDetails.vue?vue&type=script&lang=js&"
export * from "./_MemberDetails.vue?vue&type=script&lang=js&"
import style0 from "./_MemberDetails.vue?vue&type=style&index=0&id=d5b13bca&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5b13bca",
  null
  
)

export default component.exports