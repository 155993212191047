import { mapActions, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('me', {
      organizationId: 'organization_id',
    }),
  },
  methods: {
    ...mapActions('organization', { loadOrganization: 'loadItem' }),
  },
  watch: {
    organizationId(newOrganizationId) {
      this.loadOrganization(newOrganizationId);
    },
  },
  created() {
    if (!this.organizationId) return;
    this.loadOrganization(this.organizationId);
  },
};
