import _ from 'lodash';
import * as viewer from './viewer';
import * as editor from './editor';

const columnsByMode = {
  viewer,
  editor,
};

const orderedColumns = _.mapValues(columnsByMode, ({ columns, order }) => _(order)
  .map(name => _.find(columns, { name }))
  .compact()
  .value());

export default {
  ...orderedColumns,
};
