import _ from 'lodash';
import { createRecordId } from '@ibiquity/dashboard.conrad-events';
import api from '@/services/api';

const getRecords = async (query, mode, count) => {
  const per_page = 50;
  const pageCount = Math.ceil(count / per_page);
  const pages = _.range(1, pageCount + 1);

  const recordsPages = await Promise.all(pages.map(async (page) => {
    const { data: recordsPage } = await api.getItems('records', {
      page, per_page, mode, query,
    });
    return recordsPage;
  }));

  return _.flatten(recordsPages);
};

const getContentBroadcasts = (contentId, count) =>
  getRecords(`content_id:${contentId}`, 'broadcasts', count);

const getContentRemotes = (contentId, count) =>
  getRecords(`content_id:${contentId}`, 'remote_contents', count);

const getContent = async (content_id) => {
  const recordId = createRecordId({ content_id });
  const { data: content } = await api.getItemDetails('records', recordId);
  return content;
};

const getContentWithChildren = async (contentId) => {
  const content = await getContent(contentId);
  const { remote_count, broadcast_count } = content;

  const [remotes, broadcasts] = await Promise.all([
    getContentRemotes(contentId, remote_count),
    getContentBroadcasts(contentId, broadcast_count),
  ]);

  return {
    ...content,
    broadcasts,
    remotes,
  };
};

const getBroadcastRemotes = (broadcastId, count) =>
  getRecords(`broadcast_id:${broadcastId}`, 'remote_broadcasts', count);

const getBroadcast = async (broadcast_id) => {
  const recordId = createRecordId({ broadcast_id });
  const { data: broadcast } = await api.getItemDetails('records', recordId);
  return broadcast;
};

const getBroadcastWithChildren = async (broadcastId) => {
  const broadcast = await getBroadcast(broadcastId);
  const { remote_count } = broadcast;

  const remotes = await getBroadcastRemotes(broadcastId, remote_count);

  return {
    ...broadcast,
    remotes,
  };
};

export default {
  getContentWithChildren,
  getContent,
  getBroadcastWithChildren,
  getBroadcast,
};
