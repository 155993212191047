export default {
  props: {
    error: [Boolean, String],
    editMode: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Empty',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getEmittedValue(value) {
      return value;
    },
    emitChangedValue(value, id) {
      const emittedValue = this.getEmittedValue(value);

      // for v-model behavior interface
      this.$emit('input', emittedValue);

      // for collecting data with id / value pair
      this.$emit('change', { id: (id || this.id), value: emittedValue });
    },
    propagateEvent(event) {
      if (!event) return;
      this.$emit(event.type);
    },
  },
};
