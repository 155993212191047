/* eslint-disable no-param-reassign, no-shadow */
import _ from 'lodash';
import api from '@/services/api';
import notifications, { Notifications } from '@/services/notifications';
import loading from './loading.module';

const state = () => ({
  ...loading.state(),
  item: {},
  saving: false,
  changes: [],
});

const getters = ({ resourceType, idKey = 'id' } = {}) => ({
  isAnyChangeMade(state) {
    return state.changes.length > 0;
  },
  changedProperties(state) {
    return state.changes.map(s => s.name);
  },
  changesObject(state) {
    return state.changes.reduce((acc, item) => {
      acc[item.name] = item.value;
      return acc;
    }, {});
  },
  editedItem(state, getters) {
    return { ...state.item, ...getters.changesObject };
  },
  isEditedItemPropertyNullified(state, getters) {
    return property => getters.changesObject[property] === null;
  },
  isEditedItemPropertyChanged(state, getters) {
    return property => getters.changedProperties.includes(property);
  },
  updateExpectation() {
    return updateResponse =>
      notification =>
        updateResponse.update_id === notification.update_id;
  },
  updateNotificationTopic(state) {
    const { [idKey]: id, organization_id } = state.item;
    return Notifications.topic(resourceType, id, organization_id);
  },
  updateResourcePath() {
    return resourceType;
  },
  updateResourceId(state) {
    const { [idKey]: id } = state.item;
    return id;
  },
  itemUpdateObject(state, getters) {
    return getters.changesObject;
  },
});

const mutations = ({ stripEmptyOnChange = true } = {}) => ({
  ...loading.mutations(),
  clearItem(state) {
    state.item = {};
  },
  setItem(state, item) {
    state.item = item;
  },
  setSaving(state, saving) {
    state.saving = !!saving;
  },
  addChange(state, { name, value }) {
    if (!name) throw new Error(`Invalid property name provided: ${name}`);

    const existingIndex = state.changes.findIndex(i => (i.name === name));
    if (existingIndex >= 0) {
      state.changes.splice(existingIndex, 1);
    }

    const currentValue = state.item[name];
    if (currentValue === value) return;
    if (_.isUndefined(currentValue) && value === '' && stripEmptyOnChange) return;
    state.changes.push({ name, value });
  },
  clearChange(state, name) {
    const existingIndex = state.changes.findIndex(i => (i.name === name));
    if (existingIndex >= 0) {
      state.changes.splice(existingIndex, 1);
    }
  },
  clearChanges(state) {
    state.changes = [];
  },
});

const actions = ({ idKey = 'id' } = {}) => ({
  loadItem() {},
  propagateUpdate() {},
  reloadItem() {},
  async updateItem({
    commit, getters, dispatch,
  }, { payload = {}, indicateProgress = true } = {}) {
    if (indicateProgress) commit('setSaving', true);

    try {
      const wait = await notifications.createWait(getters.updateNotificationTopic);

      const patch = { ...getters.itemUpdateObject, ...payload };
      const { data: updateResponse } = await api.updateItem(
        getters.updateResourcePath, getters.updateResourceId, patch,
      );

      const updateExpectation = getters.updateExpectation(updateResponse);
      const waitResult = await wait(updateExpectation);

      await dispatch('propagateUpdate', waitResult);

      commit('clearChanges');
    } finally {
      if (indicateProgress) commit('setSaving', false);
    }
  },

  async watchItem({ state, getters, dispatch }, onChange) {
    const { [idKey]: id } = state.item;
    const topic = getters.updateNotificationTopic;

    const reloadItem = async () => {
      if (state.item[idKey] !== id) return;
      await dispatch('reloadItem');
      if (onChange) await onChange();
    };
    const subscription = notifications.subscribe(topic, reloadItem);
    return async () => {
      await subscription;
      await notifications.unsubscribe(topic, reloadItem);
    };
  },
});

export default {
  state,
  getters,
  mutations,
  actions,
};
