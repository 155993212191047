import { mapMutations, mapActions } from 'vuex';
import _ from 'lodash';

export default ({
  configurationKey = 'configuration',
  columnsKey = 'columns',
} = {}) => ({
  computed: {
    orderedColumns() {
      const columns = [...this[columnsKey]];
      this[configurationKey].forEach(({ name, position, width }) => {
        const columnIndex = columns.findIndex(column => column.name === name);
        if (columnIndex === -1) return;
        if (!width && typeof position === 'undefined') return;

        const originalColumn = columns.splice(columnIndex, 1)[0];
        const index = position >= 0 ? position : columnIndex;
        const changedColumn = width ? { ...originalColumn, width } : originalColumn;
        columns.splice(index, 0, changedColumn);
      });

      // Force put the fixed column to the beginning of array
      const fixedColumnIndex = columns.findIndex(column => column.fixedLeft);
      columns.unshift(columns.splice(fixedColumnIndex, 1)[0]);

      return columns;
    },
    visibleOrderedColumns() {
      return this.orderedColumns.filter(column => this.isColumnVisible(column.name));
    },
    isColumnVisible() {
      return name => !this[configurationKey].some(column => column.name === name && column.hidden);
    },
    exportableColumnNames() {
      return _(this.visibleOrderedColumns)
        .filter({ exportable: true })
        .map(column => column.exportableAs || column.name)
        .flatten()
        .value();
    },
  },
  methods: {
    ...mapActions('me/tableConfiguration', [
      'loadTableConfiguration',
      'saveTableConfiguration',
    ]),
    ...mapMutations('me/tableConfiguration', [
      'updateColumnConfig',
    ]),
  },
});
