import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';

export default (store) => {
  const resourceType = store.split('/').pop();

  return {
    computed: {
      ...mapState(store, ['saving']),
      ...mapGetters(store, ['valuesChanged', 'sourcesChanged']),
    },

    methods: {
      ...mapMutations(store, ['setSaving', 'clearChanges', 'clearSourceChanges']),
      ...mapActions(store, ['updateItem', 'updatePriorities']),

      async submit() {
        this.setSaving(true);
        try {
          if (this.valuesChanged) await this.updateItem({ indicateProgress: false });
          if (this.sourcesChanged) await this.updatePriorities({ indicateProgress: false });
          this.$ga.event({
            eventCategory: `${resourceType}-details-edit`,
            eventAction: 'submit',
          });
          this.showSuccess();
        } catch (e) {
          this.showError();
        } finally {
          this.setSaving(false);
        }
      },

      cleanup() {
        this.clearChanges();
        this.clearSourceChanges();
        this.setSaving(false);
      },
    },

    beforeDestroy() {
      this.cleanup();
    },
  };
};
