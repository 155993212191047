import records from './records';

const urlPathMatches = (config, path) =>
  config.url.replace(config.baseURL, '').startsWith(path);

const install = (instance) => {
  [records]
    .forEach(({ path, request, response }) => {
      instance.interceptors.request.use((config) => {
        if (request && urlPathMatches(config, path)) {
          return request(config) || config;
        }
        return config;
      });
      instance.interceptors.response.use((result) => {
        const {
          config, data, headers, status,
        } = result;
        if (response && urlPathMatches(config, path)) {
          response(data, { headers, status });
        }
        return result;
      });
    });
};

export default { install };
