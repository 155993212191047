/**
  Emits event on DOM Element intersection change

  Options:
    - options
        - threshold: Array<Number> - intersection thresholds, default: [0.0]
        - rootMargin: String - root element margin, default: 0px 0px 0px 0px
        - rootSelector: String - root element selector (it has to be unique), default: viewport
    - on
      - change(El: HTMLElement, state: Boolean): void - on change event
      - enter(El: HTMLElement): void - on enter event
      - leave(El: HTMLElement): void - on leave event
 */

const reusableObservers = new Map();
//  TIP: assign to window for debug process
// window.reusableObservers = reusableObservers;

function onIntersectionHandler(domElement, handlers = {}) {
  return (entry) => {
    if (entry.isIntersecting) {
      if (handlers.enter) handlers.enter(domElement);
    } else if (handlers.leave) handlers.leave(domElement);

    if (handlers.change) handlers.change(domElement, entry.isIntersecting);
  };
}

// default values are IntersectionObserver API defaults
function getObserverUID({ rootSelector = 'viewport', threshold = 0, rootMargin = '0' }) {
  return `${rootSelector}-${threshold}-${rootMargin}`;
}

function reuseObserver(domElement, handlers, observerUID) {
  if (!reusableObservers.get(observerUID).callbacks.has(domElement)) {
    const observer = reusableObservers.get(observerUID);
    observer.callbacks.set(domElement, onIntersectionHandler(domElement, handlers));
  }
  return reusableObservers.get(observerUID).observer;
}

const reusableObserverCallback = observerUID => (entries) => {
  const { callbacks } = reusableObservers.get(observerUID);

  entries.forEach((entry) => {
    const elementCallbacks = callbacks.get(entry.target);
    elementCallbacks(entry);
  });
};

function initReusableObserver(domElement, options, handlers, observerUID) {
  const observer = new IntersectionObserver(reusableObserverCallback(observerUID), {
    threshold: options.threshold,
    root: options.rootSelector && document.querySelector(options.rootSelector),
    rootMargin: options.rootMargin,
  });
  reusableObservers.set(observerUID, {
    observer,
    callbacks: new Map().set(domElement, onIntersectionHandler(domElement, handlers)),
  });

  return observer;
}

function inserted(domElement, binding) {
  const { value } = binding;

  if (value.preventObserver) return;

  let observer;
  const observerUID = getObserverUID(value.options);

  if (reusableObservers.has(observerUID)) {
    observer = reuseObserver(domElement, value.on, observerUID);
  } else {
    observer = initReusableObserver(domElement, value.options, value.on, observerUID);
  }

  observer.observe(domElement);
}

function unbind(domElement, binding) {
  const { value } = binding;

  if (value.preventObserver) return;

  const observerUID = getObserverUID(value.options);
  const reusableObserver = reusableObservers.get(observerUID);

  reusableObserver.observer.unobserve(domElement);
  reusableObserver.callbacks.delete(domElement);

  if (reusableObserver.callbacks.size === 0) {
    reusableObserver.observer.disconnect();
    reusableObservers.delete(observerUID);
  }
}

export default {
  inserted,
  unbind,
};
