import { render, staticRenderFns } from "./RecordActionsCell.vue?vue&type=template&id=5d60259a&scoped=true&"
import script from "./RecordActionsCell.vue?vue&type=script&lang=js&"
export * from "./RecordActionsCell.vue?vue&type=script&lang=js&"
import style0 from "./RecordActionsCell.vue?vue&type=style&index=0&id=5d60259a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d60259a",
  null
  
)

export default component.exports