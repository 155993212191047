/* eslint-disable no-multi-spaces */
import OrganizationsIndex               from '@/components/views/Organizations/Index';
import OrganizationDefaultHeader        from '@/components/views/Organization/Header';
import OrganizationIndex                from '@/components/views/Organization/Index';
import OrganizationMembersIndex         from '@/components/views/Organization/Members/Index';
import OrganizationMemberIndex          from '@/components/views/Organization/Member/Index';
import OrganizationMemberHeader         from '@/components/views/Organization/Member/Header';
import OrganizationStationsIndex        from '@/components/views/Organization/Stations/Index';
import OrganizationStationsAssignHeader from '@/components/views/Organization/Stations/Assign/Header';
import OrganizationStationsAssignIndex  from '@/components/views/Organization/Stations/Assign/Index';
/* eslint-enable no-multi-spaces */

import { extractQueryParams } from './common';

export const organizationsRoutes = [
  {
    path: 'organizations',
    name: 'organizations',
    component: OrganizationsIndex,
    props: route => extractQueryParams(route),
  },
  {
    path: 'organizations/:id',
    component: OrganizationIndex,
    name: 'organization-details-view',
    redirect: { name: 'organization-stations-view' },
    children: [
      {
        path: 'stations',
        name: 'organization-stations-view',
        components: {
          header: OrganizationDefaultHeader,
          default: OrganizationStationsIndex,
        },
        props: {
          default: route => extractQueryParams(route),
        },
      },
      {
        path: 'stations/assign',
        name: 'organization-stations-assign',
        components: {
          header: OrganizationStationsAssignHeader,
          default: OrganizationStationsAssignIndex,
        },
        props: {
          default: route => extractQueryParams(route),
        },
      },
      {
        path: 'members',
        name: 'organization-members-view',
        components: {
          header: OrganizationDefaultHeader,
          default: OrganizationMembersIndex,
        },
        props: {
          default: route => extractQueryParams(route),
        },
      },
      {
        path: 'members/:memberId',
        name: 'organization-member-view',
        components: {
          header: OrganizationMemberHeader,
          default: OrganizationMemberIndex,
        },
      },
      {
        path: 'members/:memberId/edit',
        name: 'organization-member-edit',
        components: {
          header: OrganizationMemberHeader,
          default: OrganizationMemberIndex,
        },
        props: {
          default: { editMode: true },
        },
      },
    ],
  },
];

export default { organizationsRoutes };
