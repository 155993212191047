import _ from 'lodash';
import Router from 'vue-router';
import cognito from '@/services/cognito';

/* eslint-disable no-multi-spaces */
import AppLayout                        from '@/components/layouts/AppLayout';
import LoginIndex                       from '@/components/views/Login/Index';
import AccountSetup                     from '@/components/views/Login/AccountSetup';
import ChangePassword                   from '@/components/views/Login/ChangePassword';
import RequestPasswordChange                   from '@/components/views/Login/RequestPasswordChange';
import Agreements                       from '@/components/views/Login/Agreements';
import NotFound                         from '@/components/views/NotFound';
import StationsIndex                    from '@/components/views/Stations/Index';
import StationIndex                     from '@/components/views/Station/Index';
import StationDetails                   from '@/components/views/Station/Details';
import StationBroadcastsIndex           from '@/components/views/Station/Broadcasts/Index';
import StationActivityLogIndex          from '@/components/views/Station/ActivityLog/Index';
import StationBroadcastIndex            from '@/components/views/Station/Broadcast/Index';
import StationBroadcastDetails          from '@/components/views/Station/Broadcast/Details';
import StationBroadcastActivityLogIndex from '@/components/views/Station/Broadcast/ActivityLog/Index';
import RecordsIndex                     from '@/components/views/Records/Index';

import { extractQueryParams, editModeTransition } from './common';
import { organizationsRoutes } from './organizations';
import { meRoutes }            from './me';
/* eslint-enable no-multi-spaces */

async function requireAuth(to, from, next) {
  const query = { redirect: to.fullPath };
  if (!await cognito.isLoggedIn()) {
    next({ name: 'login', query });
  } else {
    next();
  }
}

async function requireNotAuth(to, from, next) {
  if (await cognito.isLoggedIn()) {
    next({ name: 'stations' });
  } else {
    next();
  }
}

async function logoutOnEnter(to, from, next) {
  if (await cognito.isLoggedIn()) {
    cognito.logout();
    next();
  } else {
    next();
  }
}

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) return savedPosition;
  if (to.hash) {
    return {
      selector: to.hash,
    };
  }
  if (editModeTransition(to, from)) return { x: 0 };
  return { x: 0, y: 0 };
};

const router = new Router({
  mode: 'history',
  scrollBehavior,
  linkActiveClass: 'active',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginIndex,
      beforeEnter: requireNotAuth,
    },
    {
      path: '/login/setup',
      name: 'setup-from-login',
      component: AccountSetup,
      beforeEnter: requireNotAuth,
      props: true,
    },
    {
      path: '/setup',
      name: 'setup-from-link',
      component: AccountSetup,
      beforeEnter: logoutOnEnter,
      props: route => ({
        username: _.replace(route.query.username, ' ', '+'),
        password: route.query.token,
      }),
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: RequestPasswordChange,
      props: {
        header: 'forgotten',
      },
    },
    {
      path: '/expired-password',
      name: 'expired-password',
      component: RequestPasswordChange,
      props: {
        header: 'expired',
      },
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: ChangePassword,
    },
    {
      path: '/agreements',
      name: 'agreements',
      component: Agreements,
      beforeEnter: requireAuth,
    },
    {
      path: '/',
      component: AppLayout,
      beforeEnter: requireAuth,
      redirect: { name: 'market-viewer' },
      children: [
        {
          path: 'features',
          name: 'features',
          component: () => import('@/components/views/FeaturesPage'),
        },
        {
          path: 'records/viewer',
          name: 'market-viewer',
          component: RecordsIndex,
          props: route => ({
            mode: 'viewer',
            ...extractQueryParams(route),
            type: route.query?.type || 'records',
          }),
        },
        {
          path: 'records/editor',
          name: 'records-editor',
          component: RecordsIndex,
          props: route => ({
            mode: 'editor',
            ...extractQueryParams(route),
            type: route.query?.type || 'records',
          }),
        },
        {
          path: 'records',
          name: 'records',
          redirect: { name: 'market-viewer' },
        },
        {
          path: 'stations',
          name: 'stations',
          component: StationsIndex,
          props: route => extractQueryParams(route),
        },
        {
          path: 'stations/:id',
          component: StationIndex,
          children: [
            {
              path: '/',
              name: 'station-details-view',
              component: StationDetails,
            },
            {
              path: 'edit',
              name: 'station-details-edit',
              component: StationDetails,
              props: { editMode: true },
            },
            {
              path: 'broadcasts',
              name: 'station-details-broadcasts-view',
              component: StationBroadcastsIndex,
            },
            {
              path: 'activities',
              name: 'station-details-activity-log-view',
              component: StationActivityLogIndex,
            },
          ],
        },
        {
          path: 'stations/:id/broadcasts/:broadcastId',
          component: StationBroadcastIndex,
          children: [
            {
              path: '/',
              name: 'station-broadcast-details-view',
              component: StationBroadcastDetails,
            },
            {
              path: 'edit',
              name: 'station-broadcast-details-edit',
              component: StationBroadcastDetails,
              props: { editMode: true },
            },
            {
              path: 'activities',
              name: 'station-broadcast-activity-log-view',
              component: StationBroadcastActivityLogIndex,
            },
          ],
        },
        ...organizationsRoutes,
        ...meRoutes,
      ],
    },
    {
      path: '*',
      component: AppLayout,
      beforeEnter: requireAuth,
      children: [{
        path: '*',
        name: 'not-found',
        component: NotFound,
      }],
    },
  ],
});

export default router;
