import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState('organization', { organization: 'item' }),
  },
  methods: {
    ...mapActions('organization/member', { loadMember: 'loadItem' }),
  },
  created() {
    this.loadMember(this.$route.params.memberId);
  },
};
