import { mapActions } from 'vuex';

export default store => ({
  data() {
    return {
      unwatch: null,
    };
  },
  destroyed() {
    if (this.unwatch) this.unwatch();
  },
  methods: {
    ...mapActions(store, ['watchItem']),
    async watch(onChange) {
      if (this.unwatch) {
        this.unwatch();
        this.unwatch = null;
      }
      this.unwatch = await this.watchItem(onChange);
    },
  },
});
