/* eslint-disable no-param-reassign, no-shadow */
import _ from 'lodash';
import api from '@/services/api';
import notifications, { Notifications } from '@/services/notifications';
import loading from './loading.module';

const state = () => ({
  ...loading.state(),
  query: null,
  page: 1,
  pageSize: 100,
  sort: null,
  customSearchParams: {},
  items: [],
  total: null,
  creating: false,
  error: null,
});

const getters = ({ resourceType } = {}) => ({
  searchParams: () => ({}),
  notificationTopic() {
    return Notifications.topic(resourceType, '*', '*');
  },
});

const mutations = ({ idKey = 'id' } = {}) => ({
  ...loading.mutations(),
  setQuery(state, query) {
    state.query = query;
  },
  setPage(state, page) {
    state.page = page;
  },
  setPageSize(state, pageSize) {
    state.pageSize = pageSize;
  },
  setSort(state, sort) {
    state.sort = sort;
  },
  setItems(state, items) {
    state.items = items;
  },
  setCustomSearchParams(state, params) {
    state.customSearchParams = params;
  },
  addItems(state, items) {
    state.items = [...state.items, ...items];
  },
  swapItem(state, { [idKey]: key, item }) {
    const index = _.findIndex(state.items, { [idKey]: key || item[idKey] });
    if (index >= 0) {
      state.items.splice(index, 1, item);
    } else {
      state.items.push(item);
    }
  },
  setTotal(state, total) {
    state.total = total;
  },
  setCreating(state, creating) {
    state.creating = creating;
  },
  clearItems(state) {
    state.items = [];
    state.total = 0;
    state.error = null;
  },
  setError(state, error) {
    state.error = error;
  },
  setMetadata(state, metadata = {}) {
    const { total = 0 } = metadata;
    state.total = total;
  },
});

const actions = ({ resourceType, defaultPageSize = 100, withHighlights = false } = {}) => ({
  async loadItems({ commit, state, getters }, params = {}) {
    const {
      query, page, force, sort, pageSize = defaultPageSize, addItems, ...custom
    } = params;
    const extendedQuery = getters.extendedQuery ? getters.extendedQuery(query, custom) : query;
    const areParamsTheSame = state.query === extendedQuery
      && state.page === page
      && state.pageSize === pageSize
      && state.sort === sort
      && _.isEmpty(custom);
    if (!force && areParamsTheSame) {
      return { items: state.items, total: state.total };
    }

    commit('startLoading');
    commit('setQuery', extendedQuery);
    commit('setPage', page);
    commit('setPageSize', pageSize);
    commit('setSort', sort);
    commit('setError', null);
    if (!_.isEmpty(custom)) commit('setCustomSearchParams', custom);
    try {
      const resourcePath = resourceType || getters.resourcePath;
      const params = {
        query: getters.baseQuery ? getters.baseQuery(extendedQuery) : extendedQuery,
        page,
        sort,
        pageSize,
        withHighlights,
        ...getters.searchParams,
      };
      const response = await api.getItems(resourcePath, params);
      const { data: items, metadata = {} } = response;

      if (addItems) commit('addItems', items);
      else commit('setItems', items);

      commit('setMetadata', metadata);
      return true;
    } catch (error) {
      commit('setError', 'Error while retrieving items');
      throw error;
    } finally {
      commit('endLoading');
    }
  },

  async clearItems({ commit }) {
    commit('startLoading');
    commit('setQuery', null);
    commit('setPage', 1);
    commit('setSort', null);
    commit('setItems', []);
    commit('setMetadata', {});
    commit('setError', null);
    commit('endLoading');
  },

  async createItem({
    dispatch, commit, state, getters,
  }, payload) {
    commit('setCreating', true);
    try {
      const wait = await notifications.createWait(getters.notificationTopic);
      const { data: { id } } = await api.createItem(resourceType, payload);

      const item = await wait(async () => {
        try {
          return await api.getItemDetails(resourceType, id);
        } catch (error) {
          return false;
        }
      });
      await dispatch('loadItems', { query: state.query, page: state.page, force: true });
      return item;
    } finally {
      commit('setCreating', false);
    }
  },
});

export default {
  state,
  getters,
  mutations,
  actions,
};
