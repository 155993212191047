import { mapState, mapGetters, mapMutations } from 'vuex';

import ModalMixin from '@/components/common/ModalMixin';

import ConfirmDiscardModal from './ConfirmDiscardModal';

export default (store) => {
  const resourceType = store.split('/')[0];

  return {
    props: {
      editMode: {
        type: Boolean,
        default: false,
      },
    },

    mixins: [ModalMixin],

    data() {
      return {
        error: null,
      };
    },

    computed: {
      ...mapState(store, ['item', 'saving']),
      ...mapState('screen', ['tablet']),

      ...mapGetters(store, ['isAnyChangeMade']),
      ...mapGetters('me', ['can']),

      isSubmitDisabled() {
        if (this.saving) return true;
        return this.item && this.editMode && !this.isAnyChangeMade;
      },
      isCancelDisabled() {
        return this.saving;
      },
    },

    methods: {
      ...mapMutations(store, ['clearChanges', 'clearSourceChanges']),
      ...mapMutations('playback', { setPlayback: 'setActive' }),

      async confirmLeaveEdit(to, from, next) {
        if (this.isAnyChangeMade) {
          const userConfirmed = await new Promise((resolve) => {
            this.showModal(ConfirmDiscardModal, { confirmDiscardResolution: resolve });
          });

          if (userConfirmed) {
            this.clearChanges();
            this.clearSourceChanges();
            this.$ga.event({
              eventCategory: `${resourceType}-edit`,
              eventAction: 'cancel',
            });
            next();
          }

          return;
        }

        if (this.editMode) {
          this.clearChanges();
          this.clearSourceChanges();
        }

        next();
      },
    },

    beforeDestroy() {
      this.setPlayback(false);
    },

    beforeRouteLeave(to, from, next) {
      return this.confirmLeaveEdit(to, from, next);
    },

    beforeRouteUpdate(to, from, next) {
      return this.confirmLeaveEdit(to, from, next);
    },
  };
};
