const importance = -1;

function keywordHighlighter(keyword, substr, template) {
  return keyword.replace(substr.toUpperCase(), template);
}

export default [
  { keyword: 'AND', importance, keywordHighlighter },
  { keyword: 'OR', importance, keywordHighlighter },
  { keyword: 'NOT', importance, keywordHighlighter },
];
