import { mapGetters } from 'vuex';

export default {
  props: {
    editMode: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapGetters('station', {
      editedStation: 'editedItem',
      stationProperty: 'itemProperty',
      isEditedStationPropertyChanged: 'isEditedItemPropertyChanged',
      editedStationPropertySource: 'propertySource',
      hasBroadcastsWithOverrides: 'hasBroadcastsWithOverrides',
      hasHdBroadcasts: 'hasHdBroadcasts',
      isPropertyBeingUpdated: 'isPropertyBeingUpdated',
      rawDataForStationField: 'rawDataForField',
      isStationPropertySourceDefault: 'isPropertySourceDefault',
      isRestoreDefaultAvailable: 'isRestoreDefaultAvailable',
    }),

    isBroadcastOverridesMessageVisible() {
      return property =>
        !this.editMode && this.hasBroadcastsWithOverrides(property);
    },
  },
};
