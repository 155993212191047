import ModalMixin from '@/components/common/ModalMixin';

const maxExportableItems = 10000;

export default ({ totalKey = 'total' } = {}) => ({
  mixins: [ModalMixin],

  computed: {
    disabledExport() {
      return this[totalKey] > maxExportableItems;
    },
  },

  methods: {
    async exportCsv(exportModal, exportModalProps) {
      if (this.disabledExport) {
        this.showMessageModal({
          name: 'export-disabled',
          type: 'error',
          icon: 'warning-red',
          header: this.$t('export.disabledExport.header'),
          message: this.$t('export.disabledExport.info'),
          button: this.$t('common.modals.error.button'),
          dismissable: true,
          plain: true,
          closeButton: true,
        });
        return;
      }
      this.showModal(exportModal, {
        width: 560,
        height: 316,
        ...exportModalProps,
      });
    },
  },
});
