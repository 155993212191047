/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { toRawProperty } from '@/lib/recordProperties';

const sourceLabels = {
  radiocom: 'RADIO.COM',
  iheart: 'iHeart',
  radioapp: 'RadioApp',
  radiodns: 'RadioDNS',
  fmlist: 'FMList',
  tagstation: 'TagStation',
  tunein: 'TuneIN',
};

export const extractSource = source => (_.isPlainObject(source) ? source.source : source);

export const sourceFromRecord = (record, property) => {
  const { sources = {} } = record || {};
  return extractSource(sources[property]);
};

export const propertySources = (remotes, property) => {
  const rawProperty = toRawProperty(property);
  return _(remotes)
    .filter(remote => remote[rawProperty])
    .map(remote => remote.source)
    .uniq()
    .value();
};

export const isPropertySourceDefault = (record, property, source) => {
  const { default_sources = {} } = record;
  return source === default_sources[property];
};

export const defaultPropertySource = (record, remotes, property) =>
  propertySources(remotes, property)
    ?.find(source => isPropertySourceDefault(record, property, source));

export const remoteBySource = (remotes, source) =>
  remotes?.find(remote => remote.source === source);

export const sourceLabel = source =>
  sourceLabels[source] || _.capitalize(source);

export const remoteSourceValueProperty = (property) => {
  if (property === 'logo') {
    return 'logo_source_urls.0';
  }
  return property;
};
