import api from '@/services/api';
import itemPartial from '../item.module';
import csvExportPartial from '../csv-export.module';

const resourceType = 'records';

/* eslint-disable no-param-reassign, no-shadow */
export default () => ({
  namespaced: true,
  state: {
    ...itemPartial.state(),
    ...csvExportPartial.state(),
    query: null,
  },
  mutations: {
    ...itemPartial.mutations(),
    ...csvExportPartial.mutations(),
    setExportQuery(state, query) {
      state.query = query;
    },
  },
  actions: {
    ...itemPartial.actions({ idKey: 'id', resourceType }),
    ...csvExportPartial.actions({ resourceType: `${resourceType}/combined` }),

    async loadItem({ state, commit }, id) {
      if (id === state.item.id) return;

      let item;
      commit('startLoading');
      try {
        const result = await api.getItemDetails(resourceType, id);
        item = result.data;
        const { data: broadcasts } = await api.getItems(resourceType, {
          mode: 'broadcasts',
          query: `content_id:${item.content_id}`,
        });
        item.broadcasts = broadcasts;
        item.broadcast_count = broadcasts.length;
      } finally {
        commit('endLoading');
      }
      commit('setItem', item);
    },
  },
  getters: {
    ...itemPartial.getters(),
  },
});
