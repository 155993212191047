import _ from 'lodash';
import records from './records';
import organizations from './organizations';
import members from './members';
import geo from './geo';

const types = {
  records,
  organizations,
  members,
  geo,
};

const normalizeWithColon = ({ keyword, placeholder = null, ...others }) => {
  const suggestion = { placeholder, ...others };
  if (!placeholder || keyword.endsWith(':') || placeholder.startsWith('(')) {
    return { keyword, ...suggestion };
  }
  if (keyword.includes(':')) return { keyword: `${keyword}.`, ...suggestion };
  return { keyword: `${keyword}:`, ...suggestion };
};

const suggestions = (type) => {
  const {
    defaults = [],
    all = [],
    asyncSuggestions,
  } = types[type] || {};

  const search = async (term, { scope } = {}) => {
    if (!term || term.length < 2) return [];
    const trimmed = _.trim(term, ' :.').toLowerCase();

    if (asyncSuggestions) {
      const result = await asyncSuggestions(term);
      return result;
    }

    const allSuggestions = _.isFunction(all) ? all(trimmed, { scope }) : all;

    return _(allSuggestions)
      .map(normalizeWithColon)
      .filter(({ keyword, placeholder }) =>
        !trimmed
        || keyword?.toLowerCase()?.includes(trimmed)
        || placeholder?.toLowerCase()?.includes(trimmed))
      .sortBy(['importance', 'keyword'])
      .value();
  };

  return {
    defaults: defaults.map(suggestion => normalizeWithColon(suggestion)),
    search,
  };
};

const tokenizeQuery = (query) => {
  // The expression below is supposed to cover entire search term by detecting segments:
  // group #1: whitechars or bracket or operator
  // group #2: one or more sub-groups of either:
  //   - a string started with quotation char and optionally ended with it
  //     (optionally, as final, unfinished segment should be included as well)
  //   - a string started with opening bracket and optionally ended with ending bracket
  //     (optionally, as final, unfinished segment should be included as well)
  //   - a string without whitechars, brackets and quotation marks
  // This is sufficient for context detection for providing proper search suggestions,
  // but is not meant to handle any kind of query validation.
  // For instance the following string would be considered as a single, valid segment too:
  // "foo bar"baz"ba
  const segmentsSplitExpression = /((\s+)|([()]))|((("[^"]*"?)|(\([^\s)]*\)?)|([^\s"()]+))+)/gi;
  let matched = {};
  const segments = [];
  do {
    const { lastIndex: previousLastIndex } = segmentsSplitExpression;
    matched = segmentsSplitExpression.exec(query);
    if (!matched || matched.index !== previousLastIndex) break;

    const { lastIndex } = segmentsSplitExpression;
    const [value, nonSegmentMatch] = matched;

    if (!nonSegmentMatch) {
      segments.push({
        value,
        start: matched.index,
        end: lastIndex - 1,
      });
    }
  } while (true); // eslint-disable-line no-constant-condition
  return segments;
};

export default {
  suggestions,
  tokenizeQuery,
};
