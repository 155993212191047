/* eslint-disable no-shadow */

const getters = ({
  mode, scopes: mapping = {}, context, includeChildren = true,
} = {}) => ({
  searchParams: (state) => {
    const { type } = state.customSearchParams;
    const include_children = includeChildren ? true : '';
    if (!type || !mapping[type]) return { mode, context, include_children };
    const scopes = mapping[type].join(',');
    return {
      scopes, mode, context, include_children,
    };
  },
  extendedQuery: () =>
    (query, searchParams = {}) => {
      const { location } = searchParams;
      if (!location) return query;
      if (!query) return `location:"${location}"`;
      return `location:"${location}" AND (${query})`;
    },
});

export default {
  getters,
};
