import _ from 'lodash';
import items from '@/store/items.module';
import csvExport from '@/store/csv-export.module';
import recordsCommonPartial from '@/store/records/common.module';
import geoSuggestions from './geo-suggestions';

const resourceType = 'records';
const context = 'matching';
const mode = 'broadcasts';
const scopes = ['broadcast'];

/* eslint-disable no-param-reassign, no-shadow */
export default () => ({
  namespaced: true,
  state: {
    ...items.state(),
    ...csvExport.state(),
  },
  mutations: {
    ...items.mutations(),
    ...csvExport.mutations(),
  },
  actions: {
    ...items.actions({
      resourceType,
      defaultPageSize: 50,
      withHighlights: true,
    }),
    ...csvExport.actions({ resourceType: `${resourceType}/${mode}` }),
  },
  getters: {
    ...items.getters({ resourceType }),
    ...recordsCommonPartial.getters({ mode, scopes }),

    searchParams: (state) => {
      const { location } = state.customSearchParams;
      return _.omitBy({
        scopes, mode, context, location,
      }, _.isUndefined);
    },

    extendedQuery: () =>
      (query, searchParams = {}) => {
        const { location_query } = searchParams;
        if (!location_query && !query) return 'remote_broadcast_id:empty';
        if (!location_query) return `(${query}) AND remote_broadcast_id:empty`;
        if (!query) return `location:"${location_query}" AND remote_broadcast_id:empty`;
        return `location:"${location_query}" AND (${query}) AND remote_broadcast_id:empty`;
      },
  },
  modules: {
    'geo-suggestions': geoSuggestions(),
  },
});
