export default {
  common: {
    labels: {
      organization: 'Organization',
      organizations: 'Organizations',
      stations: 'Stations',
      broadcasts: 'Broadcasts',
      members: 'Members',
      email: 'Email',
      emailAddress: 'Email address',
      phoneNumber: 'Phone number',
      editMode: 'Edit Mode',
      create: 'New',
      cancel: 'Cancel',
      delete: 'Delete',
      save: 'Save',
      saveChanges: 'Save changes',
      learnMore: 'Learn more',
      loadMoreResults: 'Load more results',
      device: 'Device',
      connect: 'Connect',
      download: 'Download',
      updating: 'Updating...',
      savingChanges: 'Saving changes...',
      joined: 'Joined',
      lastActive: 'Last active',
      editDetails: 'Edit details',
      editItem: 'Edit item',
      options: 'Options',
      resetPassword: 'Reset password',
      overridden: 'Overridden',
      station: 'Station',
      overriddenSource: 'Override',
      sending: 'Sending...',
      tryAgain: 'Try again',
      usageSpecification: 'Usage specification',
      remove: 'Remove',
      restoreDefaults: 'Restore defaults',
      contentData: 'Station data',
      recordStructure: 'Record structure',
      activity: 'Activity',
      updated: 'Updated',
      loading: 'Loading...',
      showAll: 'Show all',
      liveData: 'Live data',
    },
    forms: {
      optionalField: '(optional)',
      errors: {
        required: 'This field can\'t be empty',
        email: 'This field must be valid email address',
        minLength: 'This field has to be at least {minLength} characters long',
        default: 'This field is invalid',
      },
      upload: {
        picture: {
          header: 'Upload picture',
          shortHeader: 'Upload',
          remove: 'Remove',
          tip: 'Picture should be a .png or .jpg with max weight of 2 Mb.',
        },
      },
    },
    modals: {
      error: {
        button: 'I understand',
        message: 'Please check your internet connection <br> and try again in a moment.',
      },
      success: {
        button: 'Got it, thanks!',
      },
    },
    links: {
      learnMore: 'Learn more',
    },
    messages: {
      syntaxError: 'We couldn\'t return any results due to <strong>improper search terms.</strong><br />Try removing special characters from the search query.',
    },
  },
  main: {
    loading: 'Loading Dashboard...',
    administration: {
      header: 'Administration',
      controlPanel: 'Control panel',
    },
  },
  login: {
    index: {
      signing: 'Signing in...',
      header: 'Welcome! Please sign in:',
      username: {
        placeholder: 'Your email address',
      },
      password: {
        placeholder: 'Provide your password',
      },
      submitLabel: 'Sign in',
      forgotPassword: 'Forgot your password?',
    },
    accountSetup: {
      setting: 'Setting up your account...',
      header: 'Welcome to DTS Dashboard',
      description: 'Please setup your account to start managing radio stations. Your email will be your login.',
      password: {
        label: 'Set your password',
        tip: 'Minimum 10 characters, with uppercase and digits.',
        placeholder: 'Your new password',
      },
      fullname: {
        label: 'Full name',
        placeholder: 'Your name and surname',
      },
      picture: {
        label: 'Set your profile picture',
        tip: 'Picture should be a .png or .jpg file with max weight of 2 Mb.',
      },
      submitLabel: 'Continue to Dashboard',
      acknowledge: 'You will be asked to agree to our EULA and privacy policy.',
      agreements: {
        eula: {
          header: 'End User Licence Agreement confirmation',
          label: 'End User Licence Agreement',
          confirmation: 'I agree to the End User Licence Agreement',
        },
        privacyPolicy: {
          header: 'Privacy Policy confirmation',
          label: 'Privacy Policy',
          confirmation: 'I agree to the Privacy Policy terms',
        },
        successModal: {
          header: 'Your account is ready!',
          message: 'You can now manage stations and devices that were assigned to your organization.',
        },
        errorModal: {
          message: 'We couldn\'t set up your account right now.',
        },
      },
    },
    requestPasswordChange: {
      header: {
        default: 'Password reset',
        forgotten: 'Forgot your password?',
        expired: 'Password has expired',
      },
      description: 'Please enter your email address to start password reset process',
      email: {
        label: 'Your email address',
        placeholder: 'Your account\'s email address',
      },
      backToLogin: 'Go back to the login screen.',
    },
  },
  firstSetup: {
    organization: {
      intro: {
        header: 'Welcome to the DTS Connected Radio!',
        description: '<strong>DTS Administrator</strong> has invited the <strong>{name}</strong> organization to manage Connected Radio information for radio stations.',
        actionHint: 'Before proceeding please setup your organization account:',
        setupAccount: 'Setup organization account',
      },
      form: {
        header: 'Setup <strong>{name}</strong> organization account',
        description: 'Email address <strong>{email}</strong> will be the login for your organization’s account.',
        logoUploadHint: '<strong>Upload organization logo.</strong> Setting up a logo of your organization will greatly increase its recognizability!',
        uploadImage: 'Upload image',
        organizationName: 'Organization name',
        phoneNumberLabel: 'Phone number',
        phoneNumberPlaceholder: 'Organization\'s phone number',
        actionHint: 'Please create your organization account to proceed:',
        createOrganization: 'Create organization account',
        termsInformation: 'By continuing you akcnowledge and agree with<br>Connected Radio <a href="{termsUrl}">terms and services</a>.',
        saving: 'Setting up your account...',
      },
    },
  },
  pagination: {
    defaultEmptyResult: 'No items',
    defaultSummary: {
      single: '<strong>{total}</strong> Items',
      multiple: '{itemsStart}<strong>{start}</strong>-<strong>{end}</strong> of {itemsEnd}<strong>{total}</strong> Items',
    },
    prevPage: 'Previous page',
    nextPage: 'Next page',
  },
  search: {
    defaultPlaceholder: 'Find an item',
    clear: 'Clear search',
    suggestions: {
      recents: 'Recent searches:',
      searchSuggestions: 'Search suggestions:',
      searchHintStart: 'Click or hit',
      searchHintEnd: 'to search',
      guidelines: 'Show advanced search guidelines',
      emptyPlaceholder: 'Sorry, no suggestions have been found',
    },
    guidelines: {
      header: 'Advanced search guidelines',
      description: 'Advanced search queries can help you narrow down results to find specific records or sources. You can use all of these in conjunction with each other to further customize your search.',
      items: {
        quotes: 'Put any phrase in quotes to force exact matching of the whole phrase, for example: <b>“New York”</b>',
        and: 'Put in <b>AND</b> between search terms to find results that contain both of terms, for example: <b>name:BBC AND country:US</b>',
        or: 'Put in <b>OR</b> (shortcut: ",") between search terms to find results that contain either of terms, for example: <b>Paris OR Berlin</b>',
        not: 'Put in <b>NOT</b> in your search to find results that don’t match your query, for example: <b>NOT analog</b>',
        asterisk: 'An asterisk acts as a wild-card and will match on any word or part of it, for example: <b>“Rock * Roll”</b> or <b>*rock</b>',
        field: 'Put in field name to find matches for that exact field only, for example: <b>call_sign:WKRP</b>',
        empty: 'Find records that don’t have any value in a specific field, for example: <b>slogan:empty</b>',
        source: 'Find records that get field values from a given source, for example: <b>source:radiodns</b> or <b>name.source:tunein</b>',
        sourceCount: 'Filter records based on the amount of sources they have, for example: <b>source.count:>5</b>',
        hasData: 'Find records that have available data (not only selected) from a given source, for example: <b>has.data:tagstation</b> or <b>slogan.has.data:fmlist</b>',
        location: 'Find records around a given location, by providing location name, coordinates or country codes in formats ISO 3166-1 alpha-2 and alpha-3. For example: <b>location:London</b> or <b>location:GBR</b> or <b>location:52.4,19.2,400</b>',
        updated: 'Find records updated before or after a given date by specifying a date or timestamp, for example: <b>updated.before:2019-07-05T10:00:00Z</b>',
        updatedField: 'Find records with a field updated before or after a given date, for example: <b>slogan.updated.between:2020-09-12,2020-09-13</b>',
      },
    },
  },
  viewer: {
    actions: {
      customize: 'Customize market viewer table',
    },
    customizeModal: {
      header: 'Customize market viewer table',
      description: 'Change the order of the Market Viewer table columns or even disable some of them to have a clearer overview of station records and to make it more suitable for your workflow.',
    },
    index: {
      header: 'Broadcasts',
      search: 'Search broadcasts',
      pagination: {
        single: '<strong>{total}</strong> Broadcasts',
        multiple: '{itemsStart}<strong>{start}</strong>-<strong>{end}</strong> of {itemsEnd}<strong>{total}</strong> Broadcasts',
      },
    },
  },
  editor: {
    actions: {
      customize: 'Customize records editor table',
    },
    customizeModal: {
      header: 'Customize records editor table',
      description: 'Change the order of the Records Editor table columns or even disable some of them to have a clearer overview of station records and to make it more suitable for your workflow.',
    },
  },
  members: {
    index: {
      header: 'Members',
      search: 'Search members',
      description: 'Browse a list of people who are members of your organization.',
      pagination: {
        single: '<strong>{total}</strong> Members',
        multiple: '{itemsStart}<strong>{start}</strong>-<strong>{end}</strong> of {itemsEnd}<strong>{total}</strong> Members',
      },
      emptyResult: 'No members',
      invite: {
        full: 'Invite new member',
        short: 'Invite',
      },
      placeholder: 'Feels lonely here! Start <strong>adding new members</strong> to delegate tasks within <strong>{organization}</strong>.',
    },
    item: {
      verified: 'Verified',
      pendingInvitation: 'Pending invitation...',
      lastActive: 'Last active',
    },
    errorModal: {
      common: {
        header: 'We couldn\'t send the invitation right now.',
      },
      userExists: {
        header: 'This member has already been invited',
        message: 'Please review the list of existing members and <br /> specify a new email address.',
      },
    },
    successModal: {
      header: 'Invitation has been sent.',
      message: 'The member will be able to accept the invitation<br/>with a link sent to <strong>{email}</strong>',
    },
    types: {
      member: 'Member',
      owner: 'Owner',
      administrator: 'DTS Administrator',
    },
  },
  broadcast: {
    edit: {
      successModal: {
        header: 'The override has been successfully submitted.',
        message: 'It may take up to several minutes for the<br>changes to be visible on radio receivers',
      },
      errorModal: {
        header: 'We couldn\'t submit the override right now.',
      },
    },
    assignment: {
      header: 'Move to a different station',
      description: 'Broadcast will become a part of another, already available Station. You can find a station by its ID, FCC, call sign and other attributes.',
      tip: 'You can use advanced selectors to search for stations.',
      contentSelect: {
        placeholder: 'Please select a station',
      },
      spinner: {
        label: 'Assigning...',
      },
      buttons: {
        cancel: 'Cancel',
        confirm: 'Move this Broadcast',
      },
      successModal: {
        header: 'Request to transfer the broadcast has been submitted.',
        message: 'A request to move broadcast to another station has been submitted. It may take up to several minutes for the transfer to complete.',
      },
      failureModal: {
        header: 'The Broadcast could not be transferred to a target station.',
      },
    },
    options: {
      assign: 'Move to a different station',
    },
  },
  organizations: {
    name: 'Organization',
    index: {
      header: 'Organizations',
      description: 'Manage user accounts and organizations within the system.',
      search: 'Search organizations',
      pagination: {
        single: '<strong>{total}</strong> Organizations',
        multiple: '{itemsStart}<strong>{start}</strong>-<strong>{end}</strong> of {itemsEnd}<strong>{total}</strong> Organizations',
      },
    },
    item: {
      ownerPlaceholder: 'Ownership not established',
    },
    create: {
      header: 'Creating new organization...',
      action: 'Create organization',
      success: {
        header: 'New organization has been created.',
        content: 'You can change its properties and add stations before you invite members to the organization.',
      },
      error: 'We couldn’t create the organization right now.',
      tip: 'You will be able to refine the organization before inviting members.',
      inProgress: 'Creating...',
    },
    update: {
      error: 'We couldn’t update the organization right now.',
    },
  },
  organization: {
    form: {
      name: {
        label: 'Organization\'s name',
        placeholder: 'Specity organization\'s name',
      },
      logo: {
        label: 'Set a logo for this organization',
        tip: 'Picture should be a .png or .jpg with max weight of 2 Mb.',
      },
    },
    update: {
      header: 'Editing organization...',
    },
    inviteMemberModal: {
      sending: 'Sending...',
      header: 'Inviting new member...',
      actionButton: 'Invite member',
      emailPlaceholder: 'Provide user\'s email address',
      suggestedActions: 'Suggested actions',
      promoteToOwner: 'Promote this member to organization owner.',
      ownerDescription: 'Owners can invite and manage users within organization.',
      ownerRecommended: 'It is recommended to assign an owner for this organization.',
    },
    inviteMembersDescription: '<strong>Invite members</strong> to delegate organization\'s administration to them. Currently no member has taken ownership privileges.',
    stations: {
      assign: 'Assign stations',
      listPlaceholder: 'No stations found! <strong>Start assigning stations</strong> to allow <strong>{organization}</strong> to manage them.',
    },
    assignStations: {
      header: 'Station assignment',
      actionButton: 'Assign stations',
      searchPlaceholder: 'Find stations',
      previewLabel: '<strong>{selectedCount}</strong> selected',
      listPlaceholder: '<strong>Use the search field</strong> to find and assign Stations to <strong>{organization}</strong> organization.',
      select: 'Select',
      unselect: 'Selected',
      preview: {
        summary: 'Stations selected',
        expand: 'Preview selected items',
        collapse: 'Close preview',
        applyChanges: 'Apply & close preview',
      },
      reassignModal: {
        header: 'Reassigning station...',
        description: 'You selected a station that is already assigned to {organization}. Are you sure you want to continue and reassign this station?',
        warning: 'Previous organization will lose management privileges.',
        confirm: 'Select anyway',
      },
      success: {
        header: 'Stations successfully assigned',
        message: 'Stations are now assigned to <strong>{organization}</strong>. This organization will be able to edit them and their broadcasts.',
      },
      error: {
        header: 'Error assigning stations',
        message: 'There was an error while assigning stations to <strong>{organization}</strong>. Please try again later.',
      },
    },
  },
  station: {
    itemProperties: {
      logo: 'Logotypes',
      logo_url: 'Logo Lo-Res',
      logo_url_hires: 'Logo Hi-Res',
      logo_source_url: 'Logo Source URL',
      logo_source_url_hires: 'Logo Source URL (high resolution)',
      name: 'Name',
      slogan: 'Slogan',
      genres: 'Genres',
      genre_ids: 'Genres',
      languages: 'Languages',
      format: 'Format',
      description: 'Description',
      long_description: 'Long description',
      email: 'Email addresses',
      phone: 'Phone numbers',
      sms: 'SMS numbers',
      streams: 'Online streams',
      website_urls: 'Website URLs',
      facebook_urls: 'Facebook URLs',
      twitter_urls: 'Twitter URLs',
      has_live_data: 'Use live data',
      use_live_unverified: 'Use live unverified',
      active: 'Status',
      call_sign: 'Call sign',
      freq: 'Frequency',
      band: 'Band',
      address: 'Address',
      city: 'City',
      regions: 'Regions',
      country: 'Country',
      status: 'Status',
      ecc: 'ECC',
      gcc: 'GCC',
      channel: 'Channel',
      bandwidth: 'Bandwidth',
      eid: 'Ensemble ID',
      pi_code: 'PI Code',
      hd_station_id: 'SIS broadcast ID',
      hd_location: 'SIS location (latitude / longitude)',
      album_art_display: 'Album art display',
      sid: 'Service ID',
      scids: 'Service control ID',
      locked_by: 'Locked by',
      translator: 'Translator',
      fccid: 'FCC facility ID',
      coverage_area: 'Coverage area',
      kml_url: 'URL of coverage map in KML format',
      transmitter_details: 'Transmitter details',
      mq_uri: 'Live notifications AMQP URI',
      mq_exchange: 'Live notifications AMQP exchange',
      mq_key: 'Live notifications AMQP key',
      sort_key: 'Sort key',
    },
    arrayedItems: {
      reset: 'Restore Station values',
      streams: {
        header: 'Online streams',
        change: 'Change online streams',
        addNew: 'Add new Stream source',
        edit: 'Editing online stream properties',
        linkUrl: 'Stream URL',
        tip: 'Try playing the stream link before saving changes. Please keep in mind that due to technical constraints like geofencing you might not be able to test every stream.',
        status: {
          empty: 'Please provide a valid stream link.',
          error: 'Your stream could not be loaded and can not be tested.',
          loading: 'Please hold on while your stream link is being checked.',
          live: 'Your stream is live and available for you to test.',
        },
      },
      facebookUrls: {
        header: 'Facebook references',
        change: 'Change Facebook reference',
        addNew: 'Add Facebook reference',
        edit: 'Editing Facebook URL properties',
        linkUrl: 'Facebook URL',
      },
      twitterUrls: {
        header: 'Twitter references',
        change: 'Change Twitter reference',
        addNew: 'Add Twitter reference',
        edit: 'Editing Twitter URL properties',
        linkUrl: 'Twitter URL',
      },
      websiteUrls: {
        header: 'Web references',
        change: 'Change Web reference',
        addNew: 'Add Web reference',
        edit: 'Editing website URL properties',
        linkUrl: 'Website URL',
      },
      email: {
        header: 'Email addresses',
        itemHeader: 'Email address',
        change: 'Change email addresses',
        addNew: 'Add new email address',
        edit: 'Edit email address',
      },
      phone: {
        header: 'Phone numbers',
        itemHeader: 'Phone number',
        change: 'Change phone numbers',
        addNew: 'Add new Phone number',
        edit: 'Edit phone number',
      },
      sms: {
        header: 'SMS numbers',
        itemHeader: 'SMS number',
        change: 'Change SMS numbers',
        addNew: 'Add new SMS number',
        edit: 'Edit SMS number',
      },
    },
    sourcePicker: {
      title: 'Currently set value:',
    },
    logo: {
      stdResTitle: 'Standard resolution',
      hiResTitle: 'High resolution',
      stdResDescription: 'Sizes from {minSize} to {maxSize}',
      hiResDescription: 'Sizes of {size} and larger',
      empty: 'No logo',
      width: 'Width',
      height: 'Height',
      minSize: 'Min size',
      maxSize: 'Max size',
      upload: 'Upload new logotype',
    },
    edit: {
      successModal: {
        header: 'The update has been successfully submitted.',
        message: 'It may take up to several minutes for the<br>changes to be visible on radio receivers',
      },
      errorModal: {
        header: 'We couldn\'t submit the update right now.',
      },
      restore: 'Restore',
      restoreDefault: 'Restore default',
      restoreInherited: 'Restore inherited',
    },
    options: {
      reassign: 'Change organization assignment',
    },
    organizationReassign: {
      header: 'Changing organization assignment...',
      message: 'You are about to change the organization assign for <strong>{station}</strong>. What should happen with this station moving forward?',
      assign: {
        label: 'Move to a different organization',
        description: 'Station will be moved from current organization to selected one.',
        pickerPlaceholder: 'Please select new organization',
      },
      unassign: {
        label: 'Remove from assigned organization',
        description: 'This action will leave the station without organization assignment.',
      },
      successModal: {
        header: 'Station has been re-assigned successfully!',
        message: 'The station has been successfully assigned to other organization.',
      },
      errorModal: {
        header: 'We couldn’t re-assign the station right now.',
      },
      submit: 'Change assignment',
    },
    activityLog: {
      pagination: {
        single: 'Activity',
        multiple: 'Activities',
      },
      action: {
        details: 'Details',
      },
      modal: {
        updateSummary: 'Update summary',
        resubmit: 'Resubmit the changes',
        success: {
          header: 'Your update has been resubmitted',
          message: 'It may take up to several minutes for the <br /> changes to be applied.',
        },
        error: {
          header: 'We couldn\'t resubmit your update',
        },
      },
    },
  },
  records: {
    placeholder: {
      geoSearchInput: 'Select a location',
      searchInput: 'Please specify your search criteria',
      noQuery: '<strong>Start by using the search field</strong><br>to find records you need',
      emptySearch: 'No records matching your search criteria have been found!<br /><strong>Try searching with a different query.</strong>',
    },
    header: {
      viewer: 'Market Viewer',
      editor: 'Records Editor',
      description: 'You can view and edit properties of records assigned to your organization.',
    },
    total: {
      none: 'No records found',
      single: '1 record found',
      multiple: '{total} records found',
      singleShort: '1 record',
      multipleShort: '{total} records',
    },
    table: {
      moreUnmatchedRecords: '<strong>1</strong> more {resourceType} not matching search criteria | <strong>{unmatchedCount}</strong> more {resourceType}s not matching search criteria.',
      loadBroadcasts: 'Load broadcasts',
      showOnlyMatched: 'Show only matching search criteria',
      rowActions: {
        viewInEditorMode: 'View in the Editor',
      },
    },
    remotes: {
      selectedIndicators: {
        overridden: 'Selected (overridden)',
        inherited: 'Selected (inherited)',
        default: 'Selected (by default)',
        manual: 'Selected (manually)',
      },
    },
    csv: {
      export: 'Export search results to .csv',
      exportModals: {
        viewerDescription: 'Broadcasts that matched your search criteria will be packed together and exported to a .csv file for you to download and share.',
        editorDescription: 'Broadcasts and stations that matched your search criteria will be packed together and exported to a .csv file for you to download and share.',
        stationsDescription: 'Stations that matched your search criteria will be packed together and exported to a .csv file for you to download and share.',
      },
    },
    details: {
      panels: {
        liveDataSettings: 'Live data settings',
        generalInformation: 'General information',
        contactOptions: 'Contact options',
        connectivity: 'Connectivity',
        hdRadioProperties: 'HD Radio properties',
      },
      station: {
        options: {
          reassign: 'Change organization assignment',
        },
        edit: 'Edit station data',
        editMode: {
          successModal: {
            header: 'The update has been successfully submitted.',
            message: 'It may take up to several minutes for the<br>changes to be visible on radio receivers',
          },
          errorModal: {
            header: 'We couldn\'t submit the update right now.',
          },
          restore: 'Restore',
          restoreDefault: 'Restore default',
          restoreInherited: 'Restore inherited',
        },
      },
      broadcast: {
        edit: 'Edit broadcast data',
        editMode: {
          successModal: {
            header: 'The override has been successfully submitted.',
            message: 'It may take up to several minutes for the<br>changes to be visible on radio receivers',
          },
          errorModal: {
            header: 'We couldn\'t submit the override right now.',
          },
        },
      },
      total: {
        none: 'No records found',
        single: '<strong>1</strong> record in total',
        multiple: '<strong>{total}</strong> records in total',
      },
    },
  },
  stations: {
    index: {
      search: 'Search stations',
      exportButton: 'Export',
      pagination: {
        single: '<strong>{total}</strong> Stations',
        multiple: '{itemsStart}<strong>{start}</strong>-<strong>{end}</strong> of {itemsEnd}<strong>{total}</strong>',
        resource: 'Stations',
      },
      emptySearch: 'No stations matching your search criteria have been found!<br /><strong>Try searching with a different query.</strong>',
    },
    actions: {
      customize: 'Customize stations table',
    },
    customizeModal: {
      header: 'Customize stations table',
      description: 'Change the order of the Stations table columns or even disable some of them to have a clearer overview of station records and to make it more suitable for your workflow.',
    },
    exportModal: {
      description: 'Broadcasts from all stations that matched your search criteria will be packed together and exported to a <strong>.csv</strong> file for you to download and share.',
    },
  },
  export: {
    disabledExport: {
      header: 'Too many search results!',
      info: 'Unfortunately, we don’t yet support exporting more than 10,000 records at once. Please narrow your search criteria.',
    },
    modal: {
      header: 'Exporting search results…',
      pending: 'Please wait while we generate the .csv file for you…',
      error: 'Oops! Something went wrong, please try again.',
      download: 'The export file is now ready, click here to download.',
    },
  },
  activityLog: {
    search: 'Search activities',
    emptySearch: 'No records matching your search criteria have been found!<br /><strong>Try searching with a different query.</strong>',
  },
  pendingUpdates: {
    label: 'Update in progress',
    modal: {
      header: 'Update is in progress',
      info: 'We are sorry, this field is currently locked, because the update is taking longer than we expected.',
      closeButton: 'Got it, thanks!',
    },
  },
  pendingTransfer: {
    label: 'Transferring to another station',
    modal: {
      header: 'Transfer in progress',
      info: 'We are sorry, this broadcast is currently locked, because it is being transferred. It will be unlocked when the transfer is complete.',
      closeButton: 'Got it, thanks!',
    },
  },
  member: {
    yourProfile: 'Your profile',
    profileDetails: 'Profile details',
    editProfile: 'Edit profile',
    editingProfile: 'Editing profile',
    form: {
      profilePicture: {
        label: 'Set profile picture',
      },
      fullName: {
        label: 'Full name',
      },
    },
    updateError: 'Error updating organization member account',
    requestPasswordChange: {
      modal: {
        header: 'Resetting password...',
        message: 'You are about to send a password reset link for <strong>{name}</strong> to email address <strong>{email}</strong>. Are you sure you want to continue?',
      },
      successModal: {
        header: 'Password reset link has been sent.',
        message: {
          common: 'You will receive an email with a password reset link.<br/>Please follow the instructions received in the email.',
          personalized: 'We have sent an email with a password reset link <br/> and next steps to <strong>{email}</strong>',
        },
      },
      errorModal: {
        header: 'We couldn’t send the password reset link.',
      },
    },
  },
  sourceIndicator: {
    inherited: 'Inherited',
    default: 'default',
  },
  notFound: {
    header: 'Sorry, page not found!',
    simpleDescription: 'We tried but couldn\'t reach the page you were looking for.',
    fullDescription: 'It might have been removed, renamed or didn’t exist at all. Please double check the address you have provided, otherwise use the button below:',
    returnMessage: 'Return to Dashboard\'s homepage',
  },
  playback: {
    error: {
      header: 'Stream playback error',
      message: 'We were unable to play the requested stream. Please make sure that the stream link is valid.',
    },
  },
};
