/* eslint-disable no-param-reassign, no-shadow */
import _ from 'lodash';
import {
  isPropertySourceDefault,
  sourceFromRecord,
} from '@/lib/recordSources';

const excludedSources = ['matcher'];

const getSource = (item, property) => {
  const source = sourceFromRecord(item, property);
  if (!source || excludedSources.includes(source)) return null;
  return source || null;
};

const valueProperty = ({ item, property }) => {
  if (property === 'logo') {
    const { source } = item;
    if (source) return 'logo_source_urls.0';
    return 'logo.source_url';
  }
  return property;
};

const highlightsValueProperty = ({ property }) => {
  const output = highlightsProperty => ['highlights', highlightsProperty];
  if (property === 'streams') return output('streams.url');
  return output(property);
};

const getValue = (item, property, { joined = true, highlights = false } = {}) => {
  const valuePropertyGetter = highlights ? highlightsValueProperty : valueProperty;
  const value = _.get(item, valuePropertyGetter({ item, property }));
  return joined ? _.castArray(value).join(', ') : value;
};

const getHighlightedValue = (item, property, { fallback = true, ...options } = {}) => {
  const value = getValue(item, property, { highlights: true, ...options });
  if (value) return value;
  if (fallback) return getValue(item, property, options);
  return null;
};

const isPropertyHighlighted = ({ item, property }) => {
  if (!item.highlights) return false;
  const [highlightsKey, propertyKey] = highlightsValueProperty({ property });
  return propertyKey in item[highlightsKey];
};

const getters = () => ({
  record() {
    return item => ({
      isContent() {
        return !item.broadcast_id && !item.source;
      },
      isBroadcast() {
        return !!item.broadcast_id;
      },
      isRemote() {
        return !!item.source;
      },
      isRemoteBroadcast() {
        return !!item.remote_broadcast_id;
      },
      highlightedValue(property, options = {}) {
        return getHighlightedValue(item, property, options);
      },
      isHighlighted(...properties) {
        return properties.some(property => isPropertyHighlighted({ item, property }));
      },
    });
  },
  field(state, getters, rootState, rootGetters) {
    return (property, item, parentItem) => {
      const record = getters.record(item);
      return {
        value(options = {}) {
          return getValue(item, property, options);
        },
        highlightedValue(options = {}) {
          return getHighlightedValue(item, property, options);
        },
        parentValue(options = {}) {
          return getValue(parentItem, property, options);
        },
        valueExists() {
          return property in item;
        },
        source() {
          if (record.isRemote()) return item.source;
          return getSource(item, property);
        },
        parentSource() {
          if (!parentItem) return null;
          return getSource(parentItem, property);
        },
        sourceType() {
          const source = this.source();
          if (!source) return null;
          if (source === 'dashboard') return 'overridden';
          if (source === 'inherited') return 'inherited';
          const sourcesHost = record.isRemote() ? parentItem : item;
          if (isPropertySourceDefault(sourcesHost, property, source)) return 'default';
          return 'manual';
        },
        remoteStatus() {
          if (!record.isRemote()) return null;
          if (_.isEmpty(this.value())) return null;
          if (this.source() !== this.parentSource()) return 'inactive';
          if (this.value() !== this.parentValue()) return 'inactive';
          if (!this.similarRemotesCount()) return 'inactive';
          if (this.isDefault()) return 'default';
          return 'overridden';
        },
        similarRemotesCount() {
          if (!record.isRemote()) return null;
          const itemSource = this.source();
          const itemValue = this.value();
          const remotes = rootGetters['records/remotes/remotes'](parentItem) || [];
          const similarRemotes = remotes
            .filter(remote => remote.source === itemSource)
            .filter(remote => _.isEqual(getValue(remote), itemValue));
          return similarRemotes.length - 1;
        },
        isOverridden() {
          return this.sourceType() === 'overridden';
        },
        isInherited() {
          return this.sourceType() === 'inherited';
        },
        isDefault() {
          return this.sourceType() === 'default';
        },
        isManual() {
          return this.sourceType() === 'manual';
        },
        isHighlighted() {
          return isPropertyHighlighted({ item, property });
        },
      };
    };
  },
});

export default {
  getters,
};
