const _ = require('lodash');
const records = require('./records');

const fieldsDefinitions = {
  records,
};

class HashService {
  constructor(fieldsDefinition) {
    this.fieldsDefinition = fieldsDefinition;
  }

  static urlHashParams() {
    return new URLSearchParams(window.location.hash.substr(1));
  }

  static property(name) {
    return _.kebabCase(name);
  }

  static updateURL(urlHashParams) {
    window.location.hash = urlHashParams.toString();
  }

  validate(name) {
    if (!this.fieldsDefinition[name]) throw new Error(`Unknown hash parameter "${name}"`);
  }

  set(name, value) {
    this.validate(name);
    const urlHashParams = HashService.urlHashParams();
    if (value === null) urlHashParams.delete(HashService.property(name));
    else urlHashParams.set(HashService.property(name), value);
    HashService.updateURL(urlHashParams);
  }

  setAll(values) {
    const urlHashParams = HashService.urlHashParams();
    _.forEach(values, (value, name) => {
      this.validate(name);
      if (value === null) urlHashParams.delete(HashService.property(name));
      else urlHashParams.set(HashService.property(name), value);
    });
    HashService.updateURL(urlHashParams);
  }

  parseSearchParam(urlHashParams, name) {
    const value = urlHashParams.get(HashService.property(name));
    if (value === null) return value;
    const fieldDefinition = this.fieldsDefinition[name];
    if (fieldDefinition.type === Boolean) {
      return value === 'true';
    }
    return value;
  }

  get(name) {
    this.validate(name);
    const urlHashParams = HashService.urlHashParams();
    return this.parseSearchParam(urlHashParams, name);
  }

  getAll() {
    const urlHashParams = HashService.urlHashParams();
    return _(this.fieldsDefinition)
      .mapValues((type, name) => this.parseSearchParam(urlHashParams, name))
      .omitBy(_.isNull)
      .value();
  }

  clearAll() {
    const urlHashParams = HashService.urlHashParams();
    _.forEach(this.fieldsDefinition, (definition, name) => {
      urlHashParams.delete(HashService.property(name));
    });
    HashService.updateURL(urlHashParams);
  }
}

const createHashService = (type) => {
  if (!fieldsDefinitions[type]) throw new Error(`Unknown hash service type "${type}"`);
  return new HashService(fieldsDefinitions[type]);
};

module.exports = {
  createHashService,
  HashService,
};
