const featureFlag = name => `_featureFlag_${name}`;

export default () => ({
  namespaced: true,

  state: () => ({
    items: {},
    loaded: false,
  }),

  getters: {
    featureEnabled: state => name => !!state.items[name],
    features: state => Object.keys(state.items).map(name => ({ name, value: state.items[name] })),
  },

  mutations: {
    setFeature(state, { name, value }) {
      state.items[name] = value;
    },
    setLoaded(state, value = true) {
      state.loaded = value;
    },
  },

  actions: {
    loadFeatures({ state, commit }) {
      if (state.loaded) return;
      Object.keys(state.items).forEach((name) => {
        if (localStorage.getItem(featureFlag(name))) commit('setFeature', { name, value: true });
      });
      commit('setLoaded');
    },
    toggleFeature({ state, commit }, name) {
      const value = !state.items[name];
      const flag = featureFlag(name);
      if (value) {
        localStorage.setItem(flag, true);
      } else {
        localStorage.removeItem(flag);
      }
      commit('setFeature', { name, value });
    },
  },
});
