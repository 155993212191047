import {
  format, formatDistanceToNow, differenceInHours, parseISO, isDate,
} from 'date-fns';

const shortDateFormat = 'PP';
const shortTimeFormat = 'p';

const toDate = date => (isDate(date) ? date : parseISO(date));
const formatDateWith = formatString =>
  timestamp => timestamp && format(toDate(timestamp), formatString);

export const datetimeFilters = {
  formatDate: formatDateWith(shortDateFormat),
  formatTime: formatDateWith(shortTimeFormat),
  formatDateTime: formatDateWith(`${shortDateFormat} ${shortTimeFormat}`),
  formatOnDateAtTime: timestamp => timestamp && `on ${formatDateWith(shortDateFormat)(timestamp)} at ${formatDateWith(shortTimeFormat)(timestamp)}`,
  relativeDate: (timestamp) => {
    if (!timestamp) {
      return timestamp;
    }

    if (differenceInHours(new Date(), toDate(timestamp)) > 24) {
      return formatDateWith(shortDateFormat)(timestamp);
    }

    return formatDistanceToNow(toDate(timestamp), { addSuffix: true });
  },
  fromNow: timestamp =>
    timestamp && formatDistanceToNow(toDate(timestamp), { addSuffix: true }),
};

const install = (Vue) => {
  // eslint-disable-next-line
  Vue.prototype.$datetime = datetimeFilters;

  Object.keys(datetimeFilters).forEach((key) => {
    Vue.filter(key, datetimeFilters[key]);
  });
};

export default install;
