import RecordsTableGenresCell from '../_Cells/GenresCell';
import RecordsTableConradStatusCell from '../_Cells/ConradStatusCell';
import RecordsTableStreamsCell from '../_Cells/StreamsCell';

/* eslint-disable object-curly-newline */
const columns = [
  { name: 'name', defaultWidth: 200, sortable: true, exportable: true },
  { name: 'country', defaultWidth: 65, sortable: true, exportable: true },
  { name: 'city', defaultWidth: 150, sortable: true, exportable: true },
  { name: 'regions', defaultWidth: 150, header: 'Region', sortable: true, exportable: true },
  { name: 'updated_at', type: 'datetime', defaultWidth: 250, header: 'Updated', sortable: false, initialOrder: 'desc', exportable: true },
  { name: 'status', component: RecordsTableConradStatusCell, defaultWidth: 100, header: 'Status', sortable: true, exportable: true },
  // eslint-disable-next-line max-len
  // { name: 'active', type: 'status', defaultWidth: 160, header: 'Active', sortable: true, params: { enabledLabel: 'Active', disabledLabel: 'Inactive' }, exportable: true },
  { name: 'format', defaultWidth: 210, sortable: true, exportable: true },
  { name: 'slogan', defaultWidth: 210, exportable: true },
  { name: 'genre_ids', component: RecordsTableGenresCell, header: 'Genres', defaultWidth: 200, type: 'tag', exportable: true },
  { name: 'call_sign', defaultWidth: 98, sortable: true, exportable: true },
  { name: 'languages', defaultWidth: 100, type: 'tag', exportable: true },
  { name: 'description', defaultWidth: 180, exportable: true },
  { name: 'long_description', defaultWidth: 280, exportable: true },
  { name: 'local_name', defaultWidth: 120, sortable: true, exportable: true },
  { name: 'address', defaultWidth: 280, exportable: true },
  { name: 'streams', header: 'Online stream', component: RecordsTableStreamsCell, defaultWidth: 460, exportable: true },
  { name: 'email', type: 'tag', defaultWidth: 250, params: { icon: 'email', navigation: true }, exportable: true },
  { name: 'phone', type: 'tag', defaultWidth: 200, params: { icon: 'phone' }, exportable: true },
  { name: 'sms', type: 'tag', defaultWidth: 200, params: { icon: 'sms' }, exportable: true },
  { name: 'facebook_urls', type: 'tag', defaultWidth: 300, params: { icon: 'facebook', navigation: true }, exportable: true },
  { name: 'website_urls', type: 'tag', defaultWidth: 300, params: { icon: 'website', navigation: true }, exportable: true },
  { name: 'owner', defaultWidth: 250, sortable: true, exportable: true },
  { name: 'twitter_urls', type: 'tag', defaultWidth: 300, params: { icon: 'twitter', navigation: true }, exportable: true },
];

/* eslint-disable import/prefer-default-export */
export { columns };
