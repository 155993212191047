import items from '@/store/items.module';
import organizationCommon from '@/store/organization/common.module';

export default () => ({
  namespaced: true,
  state: items.state(),
  getters: {
    ...items.getters({ resourceType: 'users' }),
    ...organizationCommon.getters(),
  },
  mutations: items.mutations(),
  actions: items.actions({ resourceType: 'users' }),
});
