/* eslint-disable no-shadow */
import _ from 'lodash';
import { createRecordId } from '@ibiquity/dashboard.conrad-events';
import api from '@/services/api';
import recordsApi from '@/services/api/records';
import notifications, { Notifications } from '@/services/notifications';
import stationCommon from './common.module';
import broadcast from './broadcast';
import activities from './activities';

const resourceType = 'records';
const idKey = 'id';

export default () => ({
  namespaced: true,

  state: stationCommon.state(),

  getters: {
    ...stationCommon.getters({ resourceType, idKey }),

    updateExpectation(state, getters) {
      return () => {
        const { content_id, broadcasts, remotes } = state.item;

        return [{
          operation: 'content.update',
          handler: async () => {
            const content = await recordsApi.getContent(content_id);
            const areAllPending = _(getters.itemUpdateObject)
              .keys()
              .every(property => _.has(content.pending_updates, property));
            if (!areAllPending) return false;
            return { ...content, broadcasts, remotes };
          },
        }];
      };
    },

    updateSourceExpectation(state, getters) {
      return () => {
        const { content_id, broadcasts, remotes } = state.item;

        return [{
          operation: 'content.source_priorities_change',
          handler: async () => {
            const content = await recordsApi.getContent(content_id);
            const areAllPending = _(getters.sourceChangesObject)
              .keys()
              .every(property => _.has(content.pending_priority_updates, property));
            if (!areAllPending) return false;
            return { ...content, broadcasts, remotes };
          },
        }];
      };
    },

    broadcasts(state) {
      const { item = {} } = state;
      return item.broadcasts || [];
    },

    broadcastCount(state, getters) {
      return getters.broadcasts.length;
    },

    hasBroadcastsWithOverrides(state, getters) {
      return (property) => {
        const overridesCount = _(getters.broadcasts)
          .map(broadcast =>
            _.get(broadcast, `sources.${property}.source`) === 'dashboard')
          .compact()
          .size();
        return overridesCount > 0;
      };
    },

    hasHdBroadcasts(state) {
      const { broadcast_types = [] } = state.item;
      return broadcast_types.some(type => type.startsWith('hd'));
    },
  },

  mutations: stationCommon.mutations(),

  actions: {
    ...stationCommon.actions({ idKey }),

    async loadItem({ state, commit }, content_id) {
      const id = createRecordId({ content_id });
      if (id === state.item[idKey]) return;

      commit('startLoading');
      try {
        const contentWithChildren = await recordsApi.getContentWithChildren(content_id);
        commit('setItem', contentWithChildren);
      } finally {
        commit('endLoading');
      }
    },

    async propagateUpdate({ commit }, contentWithChildren) {
      const { [idKey]: id } = contentWithChildren;
      commit('stations/swapItem', { [idKey]: id, item: contentWithChildren }, { root: true });
      commit('setItem', contentWithChildren);
    },

    async reloadItem({ dispatch, state }) {
      const { content_id } = state.item;
      const contentWithChildren = await recordsApi.getContentWithChildren(content_id);
      await dispatch('propagateUpdate', contentWithChildren);
    },

    async reassign({ commit, state, dispatch }, updatedOrganizationId) {
      commit('setSaving', true);
      try {
        const {
          [idKey]: id,
          content_id,
          organization_id: previousOrganizationId,
        } = state.item;
        const content_ids = [content_id];

        const manageAssign = async (organizationId, action) => {
          const organizationTopic = action === 'assign' ? organizationId : 'xperi';
          const topic = Notifications.topic(resourceType, id, organizationTopic);
          const wait = await notifications.createWait(topic);
          await api[`${action}Items`]('organizations', organizationId, 'stations', { content_ids });
          await wait(async () => {
            const content = await recordsApi.getContent(content_id);
            return action === 'assign'
              ? content.organization_id === organizationId
              : content.organization_id === null;
          });
        };

        if (previousOrganizationId) await manageAssign(previousOrganizationId, 'unassign');
        if (updatedOrganizationId) await manageAssign(updatedOrganizationId, 'assign');

        const contentWithChildren = await recordsApi.getContentWithChildren(content_id);
        await dispatch('propagateUpdate', contentWithChildren);
      } finally {
        commit('setSaving', false);
      }
    },
  },

  modules: {
    broadcast: broadcast(),
    activities: activities(),
  },
});
