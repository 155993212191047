import api from '@/services/api';
import RecordsTableBroadcastTypeCell from '../_Cells/BroadcastTypeCell';
import RecordsTableIdCell from '../_Cells/IdCell';
import { columns as sharedColumns } from './shared';
import RecordsTableLogoCell from '../_Cells/LogoCell';

/* eslint-disable object-curly-newline, global-require */
const columns = [
  {
    name: 'broadcast_id',
    header: 'ID #',
    defaultWidth: 166,
    sortable: true,
    exportable: true,
    fixedLeft: true,
    component: RecordsTableIdCell,
  },
  {
    name: 'logo',
    header: 'Logotype',
    component: RecordsTableLogoCell,
    defaultWidth: 75,
    exportable: true,
  },
  {
    name: 'type',
    component: RecordsTableBroadcastTypeCell,
    defaultWidth: 70,
    sortable: true,
    exportable: true,
  },
  {
    name: 'freq',
    header: 'Frequency',
    defaultWidth: 120,
    sortable: true,
    params: {
      process: (value, item) => `${api.getHumanFrequency(item.band, item.freq)} ${item.band}`,
    },
    exportable: true,
  },
  { name: 'channel', defaultWidth: 80, sortable: true, exportable: true },
  { name: 'band', defaultWidth: 65, sortable: true, exportable: true },
  { name: 'broadcast_type', defaultWidth: 115, sortable: true, exportable: true },
  { name: 'ecc', defaultWidth: 70, header: 'ECC', sortable: true, exportable: true },
  { name: 'gcc', defaultWidth: 70, header: 'GCC', sortable: true, exportable: true },
  { name: 'pi_code', defaultWidth: 75, header: 'PI Code', sortable: true, exportable: true },
  { name: 'bandwidth', defaultWidth: 70, sortable: true, exportable: true },
  { name: 'eid', defaultWidth: 70, header: 'EID', sortable: true, exportable: true },
  { name: 'sid', defaultWidth: 70, header: 'SID', sortable: true, exportable: true },
  { name: 'scids', defaultWidth: 70, header: 'SCIDs', sortable: true, exportable: true },
  { name: 'fcc_id', defaultWidth: 70, header: 'FCC ID', sortable: true, exportable: true },
  { name: 'hd_location', defaultWidth: 90, header: 'HD Location', exportable: true },
  { name: 'has_live_data', type: 'status', defaultWidth: 100, header: 'Use live data', exportable: true },
  { name: 'use_live_unverified', type: 'status', defaultWidth: 150, header: 'Use live unverified', exportable: true },
  ...sharedColumns,
];

const order = [
  'broadcast_id',
  'logo',
  'name',
  'call_sign',
  'type',
  'freq',
  'country',
  'regions',
  'city',
  'status',
  'updated_at',
  'slogan',
  'genre_ids',
  'languages',
  'streams',
  'broadcast_type',
  'band',
  'channel',
  'ecc',
  'gcc',
  'pi_code',
  'bandwidth',
  'eid',
  'sid',
  'scids',
  'fcc_id',
  'hd_location',
  'has_live_data',
  'use_live_unverified',
  'format',
  'description',
  'long_description',
  'website_urls',
  'facebook_urls',
  'twitter_urls',
  'address',
  'local_name',
  'owner',
  'email',
  'phone',
  'sms',
  'actions',
];

export { columns, order };
