export default {
  data() {
    return {
      sortedColumn: {},
    };
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(query) {
        if (query.s) {
          const [sortKey, order] = query.s.split(':');
          this.sortedColumn = { sortKey, order };
        } else {
          this.sortedColumn = {};
        }
      },
    },
  },
  methods: {
    sort({ sortKey, initialOrder = 'asc' }) {
      let order;
      if (this.sortedColumn.sortKey !== sortKey) {
        order = initialOrder;
      } else if (this.sortedColumn.order === initialOrder) {
        order = this.sortedColumn.order === 'asc' ? 'desc' : 'asc';
      }
      this.$emit('sort', order && `${sortKey}:${order}`);
      this.sortedColumn = { sortKey, order };
    },
  },
};
