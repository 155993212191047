/* eslint-disable no-param-reassign, no-shadow */
import { Notifications, topicWildcard } from '@/services/notifications';
import items from '@/store/items.module';

export default () => ({
  namespaced: true,
  state: items.state(),
  getters: {
    ...items.getters(),
    notificationTopic() {
      return Notifications.topic('organizations', topicWildcard, null);
    },
  },
  mutations: items.mutations(),
  actions: items.actions({ resourceType: 'organizations' }),
});
