/* eslint-disable no-multi-spaces */
import _                from 'lodash';
import PanelItem        from '@/components/common/PanelItem';
import SourceIndicator  from '@/components/common/SourceIndicator';
import SourcePicker     from '@/components/common/SourcePicker';
import FieldActions     from './FieldActions';
/* eslint-enable no-multi-spaces */

export default {
  components: {
    PanelItem,
    SourceIndicator,
    SourcePicker,
    FieldActions,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: '',
    },
    isUpdated: {
      type: Boolean,
      default: false,
    },
    narrow: {
      type: Boolean,
      default: false,
    },
    narrowFrom: {
      type: String,
      default: '',
    },
    large: {
      type: Boolean,
      default: false,
    },
    rawData: {
      type: Array,
      default: () => [],
    },
    isSourceDefault: {
      type: Boolean,
      default: false,
    },
    isRestoreDefaultAvailable: {
      type: Boolean,
      default: false,
    },
    isRestoreInheritedAvailable: {
      type: Boolean,
      default: false,
    },
    withActions: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    label() {
      const locale = `station.itemProperties.${this.id}`;
      return this.$te(locale) ? this.$t(locale) : _.startCase(this.id);
    },
    withRestoreDefault() {
      return this.isRestoreDefaultAvailable;
    },
    withRestoreInherited() {
      return this.isRestoreInheritedAvailable;
    },
  },
};
