import _ from 'lodash';
import { createRecordId } from '@ibiquity/dashboard.conrad-events';
import recordsApi from '@/services/api/records';
import stationCommon from '../common.module';
import activities from './activities';

const resourceType = 'records';
const idKey = 'id';

export default () => ({
  namespaced: true,

  state: stationCommon.state(),

  getters: {
    ...stationCommon.getters({ resourceType, idKey }),

    contentId(state) {
      return _.get(state, 'item.content_id');
    },

    updateExpectation(state, getters) {
      return () => {
        const { broadcast_id, remotes } = state.item;

        return [{
          operation: ['broadcast.update', 'broadcast.remap'],
          handler: async () => {
            const broadcast = await recordsApi.getBroadcast(broadcast_id);
            const areAllPending = _(getters.itemUpdateObject)
              .keys()
              .every(property => _.has(broadcast.pending_updates, property));
            if (!areAllPending) return false;
            return { ...broadcast, remotes };
          },
        }];
      };
    },

    updateSourceExpectation(state, getters) {
      return () => {
        const { broadcast_id, remotes } = state.item;

        return [{
          operation: 'broadcast.source_priorities_change',
          handler: async () => {
            const broadcast = await recordsApi.getBroadcast(broadcast_id);
            const areAllPending = _(getters.sourceChangesObject)
              .keys()
              .every(property => _.has(broadcast.pending_priority_updates, property));
            if (!areAllPending) return false;
            return { ...broadcast, remotes };
          },
        }];
      };
    },

    bandOptions: (state, getters) => {
      const broadcastType = getters.editedItem.broadcast_type;
      if (broadcastType === 'dab') return ['DAB'];
      if (broadcastType === 'hdradio' || broadcastType === 'analog') return ['AM', 'FM'];
      return [];
    },

    property(state, getters, rootState, rootGetters) {
      return (property) => {
        const broadcastValue = getters.itemProperty(property);
        if (!_.isNull(broadcastValue) && !_.isUndefined(broadcastValue)) return broadcastValue;
        const contentValue = rootGetters['station/itemProperty'](property);
        if (contentValue) return contentValue;
        return undefined;
      };
    },

    isInheritedProperty(state, getters, rootState, rootGetters) {
      return (property) => {
        const broadcastValue = getters.itemProperty(property);
        if (!_.isNull(broadcastValue) && !_.isUndefined(broadcastValue)) return false;
        const contentValue = rootGetters['station/itemProperty'](property);
        if (contentValue) return true;
        return false;
      };
    },

    isRestoreInheritedAvailable(state, getters) {
      return (property) => {
        if (getters.rawDataForField(property).length) return false;
        return getters.isPropertyOverridden(property);
      };
    },
  },

  mutations: {
    ...stationCommon.mutations({ stripEmptyOnChange: false }),
  },

  actions: {
    ...stationCommon.actions({ idKey }),

    async loadItem({ state, commit }, broadcast_id) {
      const id = createRecordId({ broadcast_id });
      if (id === state.item[idKey]) return;

      commit('startLoading');
      try {
        const broadcastWithChildren = await recordsApi.getBroadcastWithChildren(broadcast_id);
        commit('setItem', broadcastWithChildren);
      } finally {
        commit('endLoading');
      }
    },

    async propagateUpdate({ commit }, broadcastWithChildren) {
      commit('setItem', broadcastWithChildren);
    },

    async reloadItem({ dispatch, state }) {
      const { broadcast_id } = state.item;
      const broadcastWithChildren = await recordsApi.getBroadcastWithChildren(broadcast_id);
      await dispatch('propagateUpdate', broadcastWithChildren);
    },
  },

  modules: {
    activities: activities(),
  },
});
