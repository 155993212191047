export default {
  methods: {
    /* this is supposed to be used solely for engineering features,
     * not for managing selling features etc. */
    featureEnabled(name) {
      const featureFlag = localStorage.getItem(`_featureFlag_${name}`);
      if (featureFlag) {
        // eslint-disable-next-line
        console.info(`Feature ${name} turned on.`);
      }
      return !!featureFlag;
    },
  },
};
