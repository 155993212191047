import axios from 'axios';
import axiosInterceptor from '@ibiquity/dashboard.rollbar/src/axiosInterceptor';

const axiosInstance = axios.create();
axiosInterceptor.install(axiosInstance);

const getDocument = async (language, documentCode) => {
  const result = await axiosInstance.get(`/docs/${language}/${documentCode}.html`);
  return result.data;
};

export default {
  getDocument,
  axiosInstance, // for unit tests purpose
};
