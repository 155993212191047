export default {
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    contentId: {
      type: String,
      default: '',
    },
    isBroadcastOverridesMessageVisible: {
      type: Boolean,
      default: false,
    },
  },
};
