const isBottom = (offset = 0) => {
  const element = document.documentElement;
  return element.scrollTop + window.innerHeight + offset >= element.offsetHeight;
};

const goToTheTop = () => {
  document.documentElement.scrollTop = 0;
};

export default {
  isBottom,
  goToTheTop,
};
