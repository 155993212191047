import items from '@/store/items.module';
import csvExport from '@/store/csv-export.module';

const resourceType = 'records';

/* eslint-disable no-param-reassign, no-shadow */
export default () => ({
  namespaced: true,
  state: {
    ...items.state(),
    ...csvExport.state(),
  },
  mutations: {
    ...items.mutations(),
    ...csvExport.mutations(),
  },
  actions: {
    ...items.actions({
      resourceType,
      defaultPageSize: 50,
    }),
    ...csvExport.actions({
      resourceType: `${resourceType}/combined`,
    }),
  },
  getters: {
    ...items.getters({ resourceType }),
    searchParams: () => ({
      mode: 'contents',
      scopes: 'content,broadcast',
      include_children: '',
    }),
    baseQuery: () =>
      (query) => {
        const base = 'status:active AND NOT active_broadcast_count:0';
        if (!query) return base;
        return `${query} AND ${base}`;
      },
  },
});
