import Vue from 'vue';
import _ from 'lodash';
import api from '@/services/api';
import { createFromRecordId, typeFromRecordId } from '@ibiquity/dashboard.conrad-events';
import lucene from '@/utils/lucene';

export default () => ({
  namespaced: true,
  state: () => ({
    remotes: {},
    loading: [],
  }),
  mutations: {
    startLoading(state, recordId) {
      if (state.loading.includes(recordId)) return;
      state.loading.push(recordId);
    },
    endLoading(state, recordId) {
      if (!state.loading.includes(recordId)) return;
      const index = state.loading.findIndex(id => id === recordId);
      state.loading.splice(index, 1);
    },
    addRemotes(state, { recordId, remotes = [] }) {
      state.remotes = {
        ...state.remotes,
        [recordId]: remotes,
      };
    },
    removeRemotes(state, { recordId }) {
      Vue.delete(state.remotes, recordId);
    },
    clearRemotes(state) {
      state.remotes = {};
    },
  },
  actions: {
    async loadRemotes({ commit, getters }, recordId) {
      commit('startLoading', recordId);
      try {
        const recordType = typeFromRecordId(recordId);
        const recordIdKey = `${recordType}_id`;
        const { [recordIdKey]: parentId } = createFromRecordId(recordId);
        const parentQuery = `${recordIdKey}:${parentId}`;
        const mode = `remote_${recordType}s`;

        const result = await Promise.all([
          parentQuery,
          lucene.join(_.compact([parentQuery, getters.searchQuery])),
        ].map(query => api.getItems('records', {
          withHighlights: true,
          pageSize: 1000,
          query,
          mode,
        })));
        const [all, matching] = result.map(({ data = [] } = {}) => data);
        const matchingRecordIds = matching.map(({ id }) => id);
        const notMatching = all.filter(({ id }) => !matchingRecordIds.includes(id));
        const remotes = matching.concat(notMatching);
        commit('addRemotes', { recordId, remotes });
      } finally {
        commit('endLoading', recordId);
      }
    },
  },
  getters: {
    remotes(state) {
      return (record) => {
        if (!record?.id) return [];
        const type = typeFromRecordId(record.id);
        const matchedRemotes = record[`remote_${type}s`] || null;
        const loadedRemotes = state.remotes[record.id] || null;

        if (!loadedRemotes) return matchedRemotes;
        if (!matchedRemotes) return loadedRemotes;

        const matchedRemoteIds = matchedRemotes.map(remote => remote.id);
        return [
          ...matchedRemotes,
          ...loadedRemotes.filter(remote => !matchedRemoteIds.includes(remote.id)),
        ];
      };
    },
    loading(state) {
      return recordId => state.loading.includes(recordId);
    },
    searchQuery(state, getters, rootState) {
      const { mode } = rootState?.records || {};
      if (!mode || !rootState?.records?.[mode]) return null;
      return rootState.records[mode]?.query || null;
    },
  },
});
