import { validationMixin } from 'vuelidate';

const errorResources = 'common.forms.errors';

export default {
  mixins: [validationMixin],

  methods: {
    touch(validation) {
      return validation && validation.$touch && validation.$touch();
    },

    errorMessage(validation) {
      if (!validation || validation.$error === false) return false;

      if (validation.required === false) {
        return this.$t(`${errorResources}.required`);
      }

      if (validation.email === false) {
        return this.$t(`${errorResources}.email`);
      }

      if (validation.minLength === false) {
        return this.$t(`${errorResources}.minLength`, { minLength: validation.$params.minLength.min });
      }

      return this.$t(`${errorResources}.default`);
    },
  },
};
