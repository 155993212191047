/**
 * Makes an element resizable.
 * Note: So far only supports resizing by adding a drag handle to the right side of the element
 * which is sufficient for resizing table cells.
 *
 * Usage:
 *   <th
 *     v-resizable
 *     @resize="handler"
 *   >
 *
 * Options:
 *   - resizable: set to false to disable resizing completely
 *
 * Events:
 *   - resize: calls while resizing/dragging with one argument - newWidth
 */

const widthLimit = 100;
const handleWidth = 6;
const dropWidth = 1;

let onDocumentMouseMove = null;
let newWidth;
let $overlay;

const emit = (vnode, event, payload) => {
  const handler = vnode.data?.on?.[event] || vnode.componentOptions?.listeners?.[event] || {};
  if (handler.fns) handler.fns(payload);
};

const resizeElement = (vnode, width) => {
  if (width) emit(vnode, 'resize', width);
};

const activeHandleExists = () => document.getElementById('resize-handle');

const createOverlay = () => {
  const overlay = document.createElement('div');
  overlay.id = 'resize-overlay';
  overlay.style.position = 'fixed';
  overlay.style.top = '0';
  overlay.style.left = '0';
  overlay.style.width = '100%';
  overlay.style.height = '100%';
  overlay.style.zIndex = '1000';
  overlay.style.backgroundColor = 'transparent';
  overlay.style.overflow = 'hidden';
  document.body.appendChild(overlay);
  const disableScrolling = event => event.preventDefault();
  overlay.onwheel = disableScrolling;
  overlay.pageDown = disableScrolling;
  overlay.pageUp = disableScrolling;
  return overlay;
};

const removeOverlay = () => {
  const overlay = document.getElementById('resize-overlay');
  if (overlay) overlay.remove();
};

const createStartHandle = (root) => {
  const element = document.createElement('div');
  element.className = 'resize-handle';
  element.style.position = 'absolute';
  element.style.top = 0;
  element.style.right = 0;
  element.style.zIndex = '1';
  element.style.width = `${handleWidth}px`;
  element.style.height = '100%';
  element.style.cursor = 'ew-resize';
  root.appendChild(element);
  element.onmouseover = () => {
    if (!activeHandleExists()) element.style.backgroundColor = '#F26A28';
  };
  element.onmouseout = () => {
    element.style.backgroundColor = '';
  };
  element.onclick = () => {
    element.style.backgroundColor = '';
  };
  return element;
};

const createActiveHandle = (overlay, root) => {
  const { top: parentTop, right: parentRight } = root.getBoundingClientRect();
  const element = document.createElement('div');
  element.className = 'resize-handle';
  element.style.position = 'absolute';
  element.style.top = `${parentTop + 1}px`;
  element.style.left = `${parentRight - handleWidth}px`;
  element.style.zIndex = '99';
  element.style.width = `${handleWidth - dropWidth}px`;
  element.style.height = `${root.clientHeight}px`;
  element.style.cursor = 'ew-resize';
  element.style.backgroundColor = '#F26A28';
  overlay.appendChild(element);
  return element;
};

const createDrop = (overlay, root) => {
  const { top, right } = root.getBoundingClientRect();
  const element = document.createElement('div');
  element.className = 'resize-drop';
  element.style.position = 'absolute';
  element.style.top = `${top + 1}px`;
  element.style.left = `${right - dropWidth}px`;
  element.style.width = `${dropWidth}px`;
  element.style.height = `${window.innerHeight}px`;
  element.style.backgroundColor = '#F26A28';
  overlay.appendChild(element);
  return element;
};

export default {
  bind($element, binding, vnode) {
    if (binding.value.resizable === false) return;

    const $startHandle = createStartHandle($element);

    const onDocumentMouseUp = () => {
      document.removeEventListener('mousemove', onDocumentMouseMove);
      document.removeEventListener('touchmove', onDocumentMouseMove);
      document.removeEventListener('mouseup', onDocumentMouseUp);
      document.removeEventListener('touchend', onDocumentMouseUp);
      resizeElement(vnode, newWidth);
      removeOverlay();
      onDocumentMouseMove = null;
      newWidth = null;
    };

    const onMouseDown = (event) => {
      event.stopPropagation();

      $overlay = createOverlay();
      const $drop = createDrop($overlay, $element);
      const $activeHandle = createActiveHandle($overlay, $element);

      const { left: dropInitialLeft } = $drop.getBoundingClientRect();
      const { left: handleInitialLeft } = $activeHandle.getBoundingClientRect();
      const { width: elementWidth, left: elementLeft } = $element.getBoundingClientRect();
      const minWidth = Math.min(elementWidth, widthLimit);
      const minX = elementLeft + minWidth;
      const initialPageX = event.pageX;

      onDocumentMouseMove = ({ pageX }) => {
        const shift = pageX - initialPageX;
        newWidth = Math.max(elementWidth + shift, minWidth);
        const dropLanding = Math.max(minX - dropWidth, dropInitialLeft + shift);
        $drop.style.left = `${dropLanding}px`;
        const handleLanding = Math.max(minX - handleWidth, handleInitialLeft + shift);
        $activeHandle.style.left = `${handleLanding}px`;
      };

      document.addEventListener('mousemove', onDocumentMouseMove);
      document.addEventListener('touchmove', onDocumentMouseMove);
      document.addEventListener('mouseup', onDocumentMouseUp);
      document.addEventListener('touchend', onDocumentMouseUp);
    };

    $startHandle.onmousedown = onMouseDown;
    $startHandle.ontouchstart = onMouseDown;
    $startHandle.onclick = (event) => {
      event.stopPropagation();
    };
  },
};
