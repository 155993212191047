import _ from 'lodash';
import { Notifications } from '@/services/notifications';
import cognito from '@/services/cognito';
import api from '@/services/api';
import item from '@/store/item.module';
import itemFromApi from '@/store/item-from-api.module';
import organizationCommon from '@/store/organization/common.module';

export default () => ({
  namespaced: true,
  state: item.state(),
  getters: {
    ...item.getters({ resourceType: 'users' }),
    ...organizationCommon.getters(),
    updateNotificationTopic(state, getters) {
      const { organizationId } = getters;
      const { id } = state.item;
      return Notifications.topic('users', id, organizationId);
    },
  },
  mutations: item.mutations(),
  actions: {
    ...item.actions(),
    ...itemFromApi.actions({ resourceType: 'users' }),

    async propagateUpdate({ state, commit, rootState }) {
      const { id } = state.item;

      const { data: user } = await api.getItemDetails('users', id);
      commit('setItem', user);

      const organizationMembers = _.get(rootState, 'organization.members.items');
      if (!_.find(organizationMembers, { id })) return;
      commit('organization/members/swapItem', { item: state.item }, { root: true });
    },

    requestPasswordChange: async ({ state }) => {
      await cognito.requestPasswordChange(state.item.email);
    },
  },
});
