import _ from 'lodash';
import operators from './operators';

const supportedCustomSearchTypes = [
  // add here sources, updated and location, accordingly
  'sources',
  'updated-basic',
  'updated-per-field',
  'location',
];

const ifSupported = (searchType, value = []) =>
  (supportedCustomSearchTypes.includes(searchType) ? value : []);

const sources = [
  'dashboard',
  'stations',
  'radioapp',
  'radioplayer',
  'radiodns',
  'tagstation',
  'tunein',
  'fmlist',
  'ximalaya',
  'iheart',
];

const countryCodesISO = [
  'AT', 'AUT',
  'AU', 'AUS',
  'CA', 'CAN',
  'CH', 'CHE',
  'CN', 'CHN',
  'DE', 'DEU',
  'DK', 'DNK',
  'ES', 'ESP',
  'FR', 'FRA',
  'GB', 'GBR',
  'IE', 'IRL',
  'IT', 'ITA',
  'JP', 'JPN',
  'KR', 'KOR',
  'MX', 'MEX',
  'NL', 'NLD',
  'NO', 'NOR',
  'NZ', 'NZL',
  'PL', 'POL',
  'PT', 'PRT',
  'SE', 'SWE',
  'US', 'USA',
];

const countryCodesISOHighlighter = (keyword, substr, template) => {
  const parts = substr.split(':');
  if (parts.length === 1) return keyword.replace(substr, template);
  parts.push(parts.pop().toUpperCase());
  return keyword.replace(parts.join(':'), template);
};

const timestampPlaceholder = 'yyyy-mm-dd';
const timestampRangePlaceholder = `${timestampPlaceholder},${timestampPlaceholder}`;

const sourceExpansions = ifSupported('sources', [{
  suffixes: ['source', 'has.data'],
  values: sources,
  condition: ({ scope }) => scope !== 'sources',
  importance: 3,
}]);

const lastUpdatedExpansions = ifSupported('updated-per-field', [
  {
    suffixes: ['updated.before', 'updated.after'],
    placeholder: timestampPlaceholder,
    importance: 4,
  },
  {
    suffixes: ['updated.between'],
    placeholder: timestampRangePlaceholder,
    importance: 4,
  },
]);

const emptyExpansion = { values: ['empty'], importance: 2 };

const defaultExpansions = [
  emptyExpansion,
  ...lastUpdatedExpansions,
  ...sourceExpansions,
];

const booleanValues = ['active', 'disabled'];

const searchTerms = [
  { keywords: ['id'] },
  { keywords: ['cid', 'content_id'], expansions: [emptyExpansion, ...lastUpdatedExpansions] },
  { keywords: ['bid', 'broadcast_id'], expansions: [emptyExpansion] },
  { keywords: ['rcid', 'remote_content_id'] },
  { keywords: ['rbid', 'remote_broadcast_id'], expansions: [emptyExpansion] },
  { keywords: ['name'], expansions: defaultExpansions },
  { keywords: ['slogan'], expansions: defaultExpansions },
  { keywords: ['call_sign'], expansions: defaultExpansions },
  { keywords: ['type'], values: ['am', 'fm', 'dab', 'hd', 'hd1', 'hd2', 'hd3', 'hd4'] },
  { keywords: ['broadcast_type'], expansions: defaultExpansions, values: ['analog', 'dab', 'hdradio'] },
  { keywords: ['band'], expansions: defaultExpansions, values: ['am', 'fm'] },
  { keywords: ['languages'], expansions: defaultExpansions },
  { keywords: ['format'], expansions: defaultExpansions },
  { keywords: ['description'], expansions: defaultExpansions },
  { keywords: ['long_description'], expansions: defaultExpansions },
  { keywords: ['email'], expansions: defaultExpansions },
  { keywords: ['phone'], expansions: defaultExpansions },
  { keywords: ['sms'], expansions: defaultExpansions },
  { keywords: ['status'], values: ['active', 'disabled', 'proposed'], expansions: [emptyExpansion, ...lastUpdatedExpansions] },
  { keywords: ['active'], expansions: defaultExpansions, values: booleanValues },
  { keywords: ['freq'], expansions: defaultExpansions },
  { keywords: ['ecc'], expansions: defaultExpansions },
  { keywords: ['channel'], expansions: defaultExpansions },
  { keywords: ['pi_code'], expansions: defaultExpansions },
  { keywords: ['eid'], expansions: defaultExpansions },
  { keywords: ['hd_station_id'], expansions: defaultExpansions },
  { keywords: ['website', 'website_urls'], expansions: defaultExpansions },
  { keywords: ['facebook', 'facebook_urls'], expansions: defaultExpansions },
  { keywords: ['twitter', 'twitter_urls'], expansions: defaultExpansions },
  { keywords: ['region', 'regions'], expansions: defaultExpansions },
  { keywords: ['use_live_unverified'], expansions: defaultExpansions, values: booleanValues },
  { keywords: ['has_live_data'], expansions: defaultExpansions, values: booleanValues },
  { keywords: ['sid'], expansions: defaultExpansions },
  { keywords: ['logo'], expansions: defaultExpansions },
  { keywords: ['scid', 'scids'], expansions: defaultExpansions },
  { keywords: ['source'], values: sources, placeholder: 'source' },
  { keywords: ['live_data_source'], placeholder: 'source' },
  { keywords: ['has.data'], values: sources, placeholder: 'source' },
  ...ifSupported('updated-basic', [
    { keywords: ['updated.before'], placeholder: timestampPlaceholder },
    { keywords: ['updated.after'], placeholder: timestampPlaceholder },
    { keywords: ['updated.range'], placeholder: timestampRangePlaceholder },
  ]),
  ...ifSupported('location', [
    { keywords: ['location'], placeholder: 'lat,long,radius' },
    { keywords: ['location'], placeholder: 'lat,long' },
    { keywords: ['location'], placeholder: 'name' },
    { keywords: ['location'], placeholder: 'iso-code' },
    {
      keywords: ['location'],
      values: countryCodesISO,
      keywordHighlighter: countryCodesISOHighlighter,
    },
  ]),
];

const createKeyword = (keyword, suffix, value) => {
  const keywordPart = suffix ? [keyword, suffix].join('.') : keyword;
  if (!value) return keywordPart;
  return [keywordPart, value].join(':');
};

const suggestionsFromExpansions = (expansions, keyword, { term, scope }) =>
  expansions.filter(({ condition }) => !condition || condition({ term, scope }))
    .flatMap(({
      importance, suffixes = [null], placeholder = null, values = [null],
    }) =>
      values.flatMap(value =>
        suffixes.map(suffix => ({
          keyword: createKeyword(keyword, suffix, value),
          placeholder,
          importance,
        }))));

const suggestionExpanded = ({
  keywords = [], values, placeholder = 'value', expansions = [], keywordHighlighter,
}, { term, scope }) => {
  const result = [];
  const matchingKeyword = keywords
    .find(keyword => keyword.includes(term)) || keywords[0];

  if (!values) {
    const suggestion = { keyword: matchingKeyword, placeholder, importance: 0 };
    if (keywordHighlighter) suggestion.keywordHighlighter = keywordHighlighter;
    result.push(suggestion);
  } else {
    result.push(...values.map((value) => {
      const suggestion = { keyword: `${matchingKeyword}:${value}`, importance: 0 };
      if (keywordHighlighter) suggestion.keywordHighlighter = keywordHighlighter;
      return suggestion;
    }));
  }

  // expand by custom expansions
  result.push(...suggestionsFromExpansions(expansions, matchingKeyword, { term, scope }));
  return result;
};

const filter = (term) => {
  const pathSegment = term.split(':').shift();
  const firstSegment = pathSegment.split('.').shift();

  const strictKeywordMatches = searchTerms
    .filter(({ keywords }) =>
      keywords.some(keyword => keyword === firstSegment)
      || keywords.some(keyword => keyword === pathSegment));
  const looseKeywordMatches = searchTerms
    .filter(({ keywords }) =>
      keywords.some(keyword => keyword.includes(firstSegment))
      || keywords.some(keyword => keyword.includes(pathSegment)));
  const valueMatches = searchTerms
    .filter(({ values = [] }) => values.some(value => value.includes(term)));
  const operatorMatches = operators
    .filter(({ keyword }) => keyword.toLowerCase().includes(term));
  if (strictKeywordMatches.length === 1) {
    return {
      expandableSuggestions: strictKeywordMatches,
      operatorSuggestions: operatorMatches,
      other: _(looseKeywordMatches)
        .union(valueMatches)
        .difference(strictKeywordMatches)
        .value(),
    };
  }
  if (!strictKeywordMatches.length && looseKeywordMatches.length === 1) {
    return {
      expandableSuggestions: looseKeywordMatches,
      operatorSuggestions: operatorMatches,
      other: _.difference(valueMatches, looseKeywordMatches),
    };
  }
  return {
    operatorSuggestions: operatorMatches,
    expandableSuggestions: strictKeywordMatches,
    other: _(looseKeywordMatches)
      .union(valueMatches)
      .difference(strictKeywordMatches)
      .value(),
  };
};

const all = (term, { scope = false } = {}) => {
  const {
    expandableSuggestions = [], operatorSuggestions = [], other = [],
  } = filter(term);
  const suggestions = [];

  suggestions.push(...expandableSuggestions.flatMap(suggestion =>
    suggestionExpanded(suggestion, { term, scope })));

  suggestions.push(...other.map(({
    keywords, keywordHighlighter, values, placeholder = 'value',
  }) => {
    const keyword = keywords.find(item => item.includes(term)) || keywords[0];
    const value = values?.find(item => item.includes(term));
    const suggestion = { importance: 1 };
    if (keywordHighlighter) suggestion.keywordHighlighter = keywordHighlighter;
    if (value) return { keyword: [keyword, value].join(':'), ...suggestion };
    return { keyword, placeholder, ...suggestion };
  }));
  suggestions.push(...operatorSuggestions);

  return suggestions;
};

export default {
  all,
};
