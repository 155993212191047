export default {
  data() {
    return {
      switchValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.switchValue = newValue;
    },
    editMode(newEditMode) {
      if (newEditMode) {
        this.switchValue = this.value;
      }
    },
  },
  methods: {
    handleSwitchChange(value) {
      if (this.editMode && this.switchValue !== value) {
        this.switchValue = value;
        this.emitChangedValue(value);
      }
    },
  },
};
