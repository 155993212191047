/* eslint-disable no-multi-spaces */
import ArrayedEmailField       from '../_Fields/ArrayedEmailField';
import ArrayedFacebookUrlField from '../_Fields/ArrayedFacebookUrlField';
import ArrayedPhoneField       from '../_Fields/ArrayedPhoneField';
import ArrayedSmsField         from '../_Fields/ArrayedSmsField';
import ArrayedStreamField      from '../_Fields/ArrayedStreamField';
import ArrayedTwitterUrlField  from '../_Fields/ArrayedTwitterUrlField';
import ArrayedWebsiteUrlField  from '../_Fields/ArrayedWebsiteUrlField';
import ChannelField            from '../_Fields/ChannelField';
import FancySwitchField        from '../_Fields/FancySwitchField';
import FreqField               from '../_Fields/FreqField';
import BandField               from '../_Fields/BandField';
import GenresField             from '../_Fields/GenresField';
import LanguagesField          from '../_Fields/LanguagesField';
import LocationField           from '../_Fields/LocationField';
import OnAirField              from '../_Fields/OnAirField';
import TextField               from '../_Fields/TextField';
/* eslint-enable no-multi-spaces */

const componentByFieldId = {
  name: TextField,
  slogan: TextField,
  genre_ids: GenresField,
  languages: LanguagesField,
  format: TextField,
  description: TextField,
  long_description: TextField,
  email: ArrayedEmailField,
  phone: ArrayedPhoneField,
  sms: ArrayedSmsField,
  streams: ArrayedStreamField,
  website_urls: ArrayedWebsiteUrlField,
  facebook_urls: ArrayedFacebookUrlField,
  twitter_urls: ArrayedTwitterUrlField,
  has_live_data: FancySwitchField,
  use_live_unverified: FancySwitchField,
  status: OnAirField,
  call_sign: TextField,
  freq: FreqField,
  band: BandField,
  regions: TextField,
  ecc: TextField,
  channel: ChannelField,
  bandwidth: TextField,
  eid: TextField,
  pi_code: TextField,
  hd_station_id: TextField,
  hd_location: LocationField,
  album_art_display: FancySwitchField,
  sid: TextField,
  scids: TextField,
};

export default {
  componentByFieldId,
};
