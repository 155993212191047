import items from '@/store/items.module';
import organizationCommon from '@/store/organization/common.module';
import recordsCommonPartial from '@/store/records/common.module';

const resourceType = 'records';

export default () => ({
  namespaced: true,
  state: items.state(),
  getters: {
    ...items.getters({ resourceType }),
    ...recordsCommonPartial.getters({
      mode: 'contents',
      scopes: { records: ['content', 'broadcast'] },
      includeChildren: false,
    }),
    ...organizationCommon.getters(),
  },
  mutations: items.mutations(),
  actions: items.actions({
    resourceType,
    defaultPageSize: 50,
  }),
});
