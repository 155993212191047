import _ from 'lodash';
import { sourceFromRecord } from '@/lib/recordSources';
import itemFromApiPartial from '../item-from-api.module';
import recordCommonsPartial from './common.module';

const resourceType = 'records';
const idKey = 'id';

const cachedItem = (rootState, id) => {
  const { items: broadcasts = [] } = rootState.records?.viewer || {};
  const broadcast = _.find(broadcasts, { id });
  if (broadcast) return broadcast;
  const { items: editorRecords = [] } = rootState.records?.editor || {};
  const broadcastsFromEditor = _(editorRecords).map('broadcasts').flatten().value();
  const broadcastFromEditor = _.find(broadcastsFromEditor, { id });
  if (broadcastFromEditor) return broadcastFromEditor;
  const { item: editorRecord = {} } = rootState.record?.editor || {};
  return _.find(editorRecord.broadcasts, { id });
};

export default () => ({
  namespaced: true,

  state: recordCommonsPartial.state(),

  getters: {
    ...recordCommonsPartial.getters({ resourceType, idKey }),

    bandOptions: (state, getters) => {
      const broadcastType = getters.editedItem.broadcast_type;
      if (broadcastType === 'dab') return ['DAB'];
      if (broadcastType === 'hdradio' || broadcastType === 'analog') return ['AM', 'FM'];
      return [];
    },

    property(state, getters) {
      return (property) => {
        const broadcastValue = getters.itemProperty(property);
        if (broadcastValue !== null) return broadcastValue;
        if (getters.isInheritedProperty(property)) {
          return getters.inheritedValue(property);
        }
        return getters.defaultValue(property);
      };
    },

    isInheritedProperty(state, getters) {
      return (property) => {
        if (getters.isEditedItemPropertyNullified(property)) {
          return !getters.defaultValue(property);
        }
        if (getters.isPropertyValueBeingUpdated(property)) {
          const pendingValue = getters.pendingValue(property);
          if (pendingValue !== null) return false;
          return !getters.defaultValue(property);
        }
        return state.item.sources[property] === 'inherited';
      };
    },

    inheritedValue(state, getters, rootState) {
      return (property) => {
        const content = rootState.record.station.item || {};
        if (content.content_id !== state.item.content_id) return '';
        return content[property] ?? '';
      };
    },

    defaultValue(state, getters) {
      return (property) => {
        const defaultSource = state.item?.default_sources?.[property];
        if (!defaultSource) return undefined;
        return getters.propertyValueBySource(property, defaultSource) ?? '';
      };
    },

    isRestoreInheritedAvailable(state, getters) {
      return property =>
        !getters.isRestoreDefaultAvailable(property)
          && !getters.isEditedItemPropertyNullified(property)
          && (sourceFromRecord(state.item, property) === 'dashboard'
            || getters.isEditedItemPropertyChanged(property));
    },
  },

  mutations: {
    ...recordCommonsPartial.mutations({ stripEmptyOnChange: false }),
  },

  actions: {
    ...recordCommonsPartial.actions({ idKey }),
    ...itemFromApiPartial.actions({
      idKey: 'id',
      cachedItem,
      resourceType,
    }),
  },
});
