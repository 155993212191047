import Vue from 'vue';
import _ from 'lodash';
import api from '@/services/api';

const legacyModesMap = {
  broadcasts: 'viewer',
  combined: 'editor',
};
const allLegacyModes = ['broadcasts', 'combined', 'stations'];

export default () => ({
  namespaced: true,

  state: () => ({
    mode: '', // "viewer", "editor"
    configuration: [], // configuration for current mode
  }),

  actions: {
    loadTableConfiguration({ commit, rootState }, mode) {
      commit('setMode', mode);
      const configuration = _(_.cloneDeep(rootState.me.table_configuration))
        .mapKeys((value, key) => legacyModesMap[key] || key)
        .value();
      commit('setTableConfiguration', configuration[mode] || []);
    },
    async saveTableConfiguration({ state, commit, rootState }, configuration) {
      configuration = configuration || state.configuration; // eslint-disable-line no-param-reassign
      const { me } = rootState;
      commit('setTableConfiguration', configuration);
      const table_configuration = {
        ..._.omit(me.table_configuration, allLegacyModes),
        [state.mode]: configuration,
      };
      commit('me/setTableConfiguration', table_configuration, { root: true });
      await api.throttledUpdateItem('users', me.id, { table_configuration });
    },
  },

  mutations: {
    setMode(state, mode) {
      state.mode = mode;
    },
    setTableConfiguration(state, configuration) {
      state.configuration = configuration;
    },
    updateColumnConfig(state, {
      columnName, key, value, configuration = null,
    }) {
      configuration = configuration || state.configuration; // eslint-disable-line no-param-reassign
      if (!configuration) return;
      const columnConfigIndex = configuration.findIndex(cs => cs.name === columnName);
      if (columnConfigIndex !== -1) {
        const columnConfiguration = configuration[columnConfigIndex];
        if (value === undefined) {
          // delete value
          Vue.set(configuration, columnConfigIndex, _.omit(columnConfiguration, [key]));
          // If only "name" property left, remove the entire object
          if (Object.keys(configuration[columnConfigIndex]).length === 1) {
            configuration.splice(columnConfigIndex, 1);
          }
        } else if (value !== columnConfiguration[key]) {
          Vue.set(configuration[columnConfigIndex], key, value);
        }
      } else {
        configuration.push({ name: columnName, [key]: value });
      }
    },
  },
});
