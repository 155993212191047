// eslint-disable-next-line
import VueMultiselect from 'vue-multiselect/src/Multiselect';

export default {
  components: {
    VueMultiselect,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    labelProperty: {
      type: String,
      default: '',
    },
    valueProperty: {
      type: String,
      default: '',
    },
    classProperty: {
      type: String,
      default: '',
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    optionLabel(option) {
      if (this.labelProperty && option && option[this.labelProperty]) {
        return option[this.labelProperty];
      }
      if (this.valueProperty && option && option[this.valueProperty]) {
        return option[this.valueProperty];
      }
      return option;
    },
    optionClass(option) {
      if (this.classProperty && option && option[this.classProperty]) {
        return option[this.classProperty];
      }
      return '';
    },
  },
};
