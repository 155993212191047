/* Application awareness of screen resolution is facilitated by using vuex store module
 * to achieve proper reactivity on app-level (contrary to just per component-level) */
import json2mq from 'json2mq';

const gap = 10;
const configuration = [
  { name: 'mobile', query: { maxWidth: 767 } },
  { name: 'tablet', query: { minWidth: 768 } },
  { name: 'laptop', query: { minWidth: 960 + (2 * gap) } },
  { name: 'laptopL', query: { minWidth: 1152 + (2 * gap) } },
  { name: 'display', query: { minWidth: 1344 + (2 * gap) } },
  { name: 'displayL', query: { minWidth: 1600 + (2 * gap) } },
  { name: 'widescreen', query: { minWidth: 1920 + (2 * gap) } },
];

const state = {
  initialized: false,
  mobile: false,
  tablet: false,
  laptop: false,
  laptopL: false,
  display: false,
  displayL: false,
  widescreen: false,
};

/* eslint-disable no-param-reassign, no-shadow */
export const actions = {
  init({ commit, state }) {
    if (!state.initialized) {
      configuration.forEach((config) => {
        const mq = window.matchMedia(json2mq(config.query));
        commit('updateScreen', { name: config.name, matches: mq.matches });
        config.listener = event => commit('updateScreen', { name: config.name, matches: event.matches });
        mq.addListener(config.listener);
      });
      commit('initialize');
    }
  },
};

export const mutations = {
  initialize() {
    state.initialized = true;
  },
  updateScreen(state, { name, matches }) {
    state[name] = matches;
  },
};

/* eslint-enable no-param-reassign, no-shadow */

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
