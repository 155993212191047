import _ from 'lodash';

export default {
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  computed: {
    producer() {
      const { producer = {} } = this.activity;
      return producer.type === 'conrad' ? 'CONRAD' : (producer.name || producer.email);
    },
    target() {
      return _.get(this.activity, ['target', 'name']);
    },
    source() {
      return _.get(this.activity, ['source', 'name']);
    },
  },
};
