export default {
  computed: {
    actionsStyle() {
      return {
        width: this.mode === 'viewer' ? '210px' : '181px',
      };
    },
    columnStyle() {
      return width => ({
        width: `${width || 200}px`,
      });
    },
  },
};
