import { render, staticRenderFns } from "./BandSwitch.vue?vue&type=template&id=28ee5af0&scoped=true&"
import script from "./BandSwitch.vue?vue&type=script&lang=js&"
export * from "./BandSwitch.vue?vue&type=script&lang=js&"
import style0 from "./BandSwitch.vue?vue&type=style&index=0&id=28ee5af0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ee5af0",
  null
  
)

export default component.exports