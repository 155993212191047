import InfiniteScrollSpinner from './InfiniteScrollSpinner';

export default {
  components: {
    InfiniteScrollSpinner,
  },

  data() {
    return {
      infinitePage: 1,
    };
  },

  computed: {
    hasMoreItems() {
      return this.items.length && this.total > this.items.length;
    },
  },

  methods: {
    resetInfiniteScroll() {
      this.infinitePage = 1;
    },
    loadMoreWithInfiniteScroll() {
      this.infinitePage += 1;

      this.searchItems({
        page: this.infinitePage,
        addItems: true,
      });
    },
  },
};
