import _ from 'lodash';
import itemFromApiPartial from '../item-from-api.module';
import recordCommonsPartial from './common.module';

const resourceType = 'records';
const idKey = 'id';

const cachedItem = (rootState, id) => {
  const { items: stations = [] } = rootState.records?.stations || {};
  const station = _.find(stations, { id });
  if (station) return station;
  const { items: editorRecords = [] } = rootState.records?.editor || {};
  return _.find(editorRecords, { id });
};

export default () => ({
  namespaced: true,

  state: recordCommonsPartial.state(),

  getters: {
    ...recordCommonsPartial.getters({ resourceType, idKey }),

    broadcasts(state) {
      const { item = {} } = state;
      return item.broadcasts || [];
    },
    broadcastCount(state, getters) {
      return getters.broadcasts.length;
    },
    hasHdBroadcasts(state) {
      const { broadcast_types = [] } = state.item;
      return broadcast_types.some(type => type.startsWith('hd'));
    },
  },

  mutations: recordCommonsPartial.mutations(),

  actions: {
    ...recordCommonsPartial.actions({ idKey }),
    ...itemFromApiPartial.actions({ idKey: 'id', cachedItem, resourceType }),
  },
});
