import { render, staticRenderFns } from "./_StreamItemEdit.vue?vue&type=template&id=15d64dd2&scoped=true&"
import script from "./_StreamItemEdit.vue?vue&type=script&lang=js&"
export * from "./_StreamItemEdit.vue?vue&type=script&lang=js&"
import style0 from "./_StreamItemEdit.vue?vue&type=style&index=0&id=15d64dd2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15d64dd2",
  null
  
)

export default component.exports