/* eslint-disable no-shadow */
import _ from 'lodash';
import lucene from '@/utils/lucene';

const getters = () => ({
  organizationId(state, getters, rootState) {
    return _.get(rootState, 'organization.item.id');
  },
  extendedQuery(state, getters) {
    return (query) => {
      const { organizationId } = getters;
      return lucene.join(_.compact([query, `organization_id:"${organizationId}"`]));
    };
  },
});

export default { getters };
