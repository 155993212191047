import _ from 'lodash';

const defaults = {
  sources: ['local'],
  resource_type: 'image',
  multiple: false,
  client_allowed_formats: ['png', 'jpeg', 'jpg'],
  styles: {
    palette: {
      window: '#fff',
      sourceBg: '#fff',
      textDark: '#76767c',
      textLight: '#fff',
      tabIcon: '#f26a28',
      link: '#f26a28',
      menuIcons: '#77777b',
    },
  },
};

const translateCloudinaryResult = (result) => {
  const file = _.get(result, 'info.files.0.uploadInfo', {});
  return {
    url: file.secure_url,
    ..._.pick(file, ['bytes', 'format', 'width', 'height']),
  };
};

const validateFolderName = (str) => {
  if (['staging', 'production'].includes(str) || str.startsWith('dashboard-')) return;
  throw new Error(`Invalid cloudName provided: ${str}`);
};

class CloudinaryWrapper {
  constructor(cloudName, preset, folder) {
    validateFolderName(folder);
    this.cloudName = cloudName;
    this.preset = preset;
    this.folder = folder;
    cloudinary.setCloudName(cloudName);
  }

  async openModal(options) {
    const widgetOptions = _.defaults(options, defaults, {
      upload_preset: this.preset,
      folder: this.folder,
    });

    const queuesEndEvent = await new Promise((resolve, reject) => {
      cloudinary.openUploadWidget(widgetOptions, (error, result) => {
        if (error) reject(error);
        if (result.event === 'queues-end') resolve(result);
      });
    });
    return translateCloudinaryResult(queuesEndEvent);
  }
}

/* eslint-disable no-param-reassign,no-console */
const install = (Vue, { cloudName, preset, folder }) => {
  if (!cloudinary || !cloudName) {
    Vue.prototype.$upload = null;
    console.warn('Upload module not installed');
    return;
  }
  Vue.prototype.$upload = new CloudinaryWrapper(cloudName, preset, folder);
};
/* eslint-enable no-param-reassign,no-console */

export default install;
