import { typeFromRecord } from '@ibiquity/dashboard.conrad-events';
import _ from 'lodash';
import { columns as Shared } from './_Columns/shared';

const justNames = list => list.map(({ name }) => name);

const contentProperties = _.uniq([
  ...justNames(Shared),
]);

const remoteContentProperties = _.difference(
  contentProperties, [
    'organization_id',
    'broadcasts',
    'status',
  ],
);

const commonBroadcast = _.uniq([
  ...justNames(Shared),
  'content_id',
  'name',
  'broadcast_id',
  'has_live_data',
  'use_live_unverified',
  'type',
  'broadcast_type',
]);

const hdRadioProperties = ['channel', 'pi_code', 'bandwidth', 'hd_station_id', 'hd_location', 'album_art_display'];
const dabProperties = ['channel', 'eid', 'sid', 'scids', 'ecc'];
const analogProperties = ['ecc', 'pi_code'];

export default {
  methods: {
    expectedProperties({ broadcast_type, ...record }) {
      const type = typeFromRecord(record);

      if (type === 'content') return contentProperties;
      if (type === 'remote_content') return remoteContentProperties;

      const broadcastProperties = [...commonBroadcast];
      if (!broadcast_type) return broadcastProperties;

      broadcastProperties.push('band', 'freq', 'gcc');

      if (broadcast_type === 'analog') broadcastProperties.push(...analogProperties);
      if (broadcast_type === 'hdradio') broadcastProperties.push(...hdRadioProperties);
      if (broadcast_type === 'dab') broadcastProperties.push(...dabProperties);

      if (type === 'broadcast') return broadcastProperties;
      return _.difference(broadcastProperties, ['status']);
    },
  },
};
