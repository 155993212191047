import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('station/broadcast', {
      editedBroadcast: 'editedItem',
      editedBroadcastProperty: 'property',
      editedBroadcastPropertySource: 'propertySource',
      isPropertyBeingUpdated: 'isPropertyBeingUpdated',
      rawDataForBroadcastField: 'rawDataForField',
      isBroadcastPropertySourceDefault: 'isPropertySourceDefault',
      isRestoreDefaultAvailable: 'isRestoreDefaultAvailable',
      isRestoreInheritedAvailable: 'isRestoreInheritedAvailable',
    }),
  },
};
