/* eslint-disable  */ // no-param-reassign

const prettify = obj => JSON.stringify(obj, null, '  ')

const explain = ({ value, description, details } = {}) => {
  if (details && details.length) {
    console[value === 0 ? 'groupCollapsed' : 'group'](`${value} ==> ${description}`);
    details.forEach(explain);
    console.groupEnd();
  } else {
    console.log(`${value} ==> ${description}`);
  }
}

export default {
  path: '/records',
  request: (req) => {
    req.params.track_scores = true;
    req.params.explain = true;
    return req;
  },
  response: (data) => {
    window.last_records_result = data;

    const { query } = data?.[0]?.metadata?.query?.body || {};
    if (!data || !data.length || !query) return;

    console.log(
      '%cRecords Explain here',
      'font-family: sans-serif; font-size: large; font-weight: bold; color: #600',
    );
    console.log(
      `%cQuery Body: %c${prettify(query)}`,
      'font-family: sans-serif; font-size: medium; font-weight: bold; color: #000',
      'font-family: monospace'
    );

    const metadata = data.map(({ id, metadata: { score, explanation } = {} }) =>
      ({ id, score, explanation }));
    metadata.forEach(({ id, score, explanation }, index) => {
      if (!score && !explanation) return;
      console.log(
        `%c[${index+1}] ID: ${id}, Score: ${score}, Explanation:`,
        'font-family: sans-serif; font-size: medium; font-weight: bold; color: #000',
      );
      explain(explanation);
    });
  },
};
