import ArrayedFieldMixin from '@/components/views/Station/_Fields/ArrayedFieldMixin';
import ArrayedTextField from './ArrayedTextField';
import FieldMixin from './FieldMixin';

export default {
  mixins: [
    FieldMixin,
    ArrayedFieldMixin,
  ],
  components: {
    ArrayedTextField,
  },
};
