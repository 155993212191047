import _ from 'lodash';
import { createRecordId } from '@ibiquity/dashboard.conrad-events';
import lucene from '@/utils/lucene';
import activitiesCommon from '../activities.module';

export default () => ({
  namespaced: true,

  state: activitiesCommon.state(),

  getters: {
    extendedQuery(state, getters, rootState) {
      return () => {
        const { broadcast_id } = _.get(rootState, 'station.broadcast.item');
        const conditions = [
          lucene.join([
            'type:broadcast.update AND updated_fields:*',
            'type:broadcast.source_priorities_change AND new_priorities:*',
            'type:(broadcast.station_assign OR broadcast.publisher_assign OR broadcast.publisher_unassign)',
          ], 'OR'),
          'fired_at:*',
          'NOT manual_sync:true',
          `broadcast_id:${broadcast_id}`,
        ];
        if (state.searchQuery) conditions.push(state.searchQuery);
        if (!state.includeConrad) conditions.push('NOT producer.type:conrad');
        return conditions.length ? lucene.join(conditions) : '';
      };
    },

    resourcePath(state, getters, rootState) {
      const { broadcast_id } = _.get(rootState, 'station.broadcast.item');
      const record_id = createRecordId({ broadcast_id });
      return `activities/${record_id}`;
    },
  },

  actions: activitiesCommon.actions({ parentScope: 'station/broadcast' }),

  mutations: activitiesCommon.mutations(),
});
