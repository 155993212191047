import _ from 'lodash';
import Vue from 'vue';
import api from '@/services/api';
import items from '@/store/items.module';
import recordModule from '@/store/record.module';
import csvExport from '@/store/csv-export.module';
import recordsCommonPartial from '@/store/records/common.module';

const resourceType = 'records';
const context = 'matching';
const maxUnmatchedBroadcasts = 1000;
const matchedBroadcastsPerPage = 100;

const records = ['content', 'broadcast'];
const sources = ['remote_content', 'remote_broadcast'];
const scopes = { records, sources, all: records.concat(sources) };

const itemsActions = items.actions({
  resourceType,
  defaultPageSize: 20,
  withHighlights: true,
});

/* eslint-disable no-param-reassign, no-shadow */
export default () => ({
  namespaced: true,
  state: () => ({
    ...items.state(),
    ...csvExport.state(),
    contentBroadcasts: {},
    loadingContentBroadcasts: {},
    loadingMoreMatchedBroadcasts: {},
    childrenTotal: 0,
  }),
  mutations: {
    ...items.mutations(),
    ...csvExport.mutations(),
    setMetadata(state, metadata = {}) {
      const { total = 0, childrenTotal = 0 } = metadata;
      state.total = total;
      state.childrenTotal = childrenTotal;
    },
    setContentBroadcasts(state, { contentId, broadcasts }) {
      Vue.set(state.contentBroadcasts, contentId, broadcasts);
    },
    removeContentBroadcasts(state, { contentId }) {
      Vue.delete(state.contentBroadcasts, contentId);
      Vue.delete(state.loadingContentBroadcasts, contentId);
    },
    appendContentBroadcasts(state, { contentId, broadcasts }) {
      const content = _.find(state.items, { content_id: contentId });
      content.broadcasts.push(...broadcasts);
    },
    clearContentBroadcasts(state) {
      state.contentBroadcasts = {};
      state.loadingContentBroadcasts = {};
    },
    setLoadingContentBroadcasts(state, { contentId, loading }) {
      Vue.set(state.loadingContentBroadcasts, contentId, loading);
    },
    setLoadingMoreMatchedBroadcasts(state, { contentId, loading }) {
      Vue.set(state.loadingMoreMatchedBroadcasts, contentId, loading);
    },
    appendItemBroadcasts({ state }, broadcasts) {
      state.item.broadcasts.push(...broadcasts);
    },
  },
  actions: {
    ...itemsActions,
    ...csvExport.actions({ resourceType: `${resourceType}/combined` }),
    async loadItems(context, params) {
      const { commit } = context;
      commit('clearContentBroadcasts');
      await itemsActions.loadItems(context, params);
    },
    async loadContentBroadcasts({ commit, state }, contentId) {
      try {
        commit('setLoadingContentBroadcasts', { contentId, loading: true });
        const params = {
          mode: 'broadcasts',
          scopes: 'broadcast',
          query: `content_id:${contentId}`,
          pageSize: maxUnmatchedBroadcasts,
          sort: state.sort,
        };
        const { data: broadcasts } = await api.getItems('records', params);
        commit('setContentBroadcasts', { contentId, broadcasts });
      } catch (error) {
        commit('setError', `Error while retrieving unmatched broadcasts for ${contentId}`);
        throw error;
      } finally {
        commit('setLoadingContentBroadcasts', { contentId, loading: false });
      }
    },
    async loadMoreMatchedBroadcasts({ commit, state }, contentId) {
      const content = _.find(state.items, { content_id: contentId });
      const page = Math.floor(content.broadcasts.length / matchedBroadcastsPerPage) + 1;
      commit('setLoadingMoreMatchedBroadcasts', { contentId, loading: true });
      try {
        const params = {
          mode: 'broadcasts',
          scopes: 'broadcast',
          query: `content_id:${contentId} ${state.query}`,
          page,
          pageSize: matchedBroadcastsPerPage,
          sort: state.sort,
          withHighlights: true,
        };
        const { data: broadcasts } = await api.getItems(resourceType, params);
        commit('appendContentBroadcasts', { contentId, broadcasts });
      } catch (error) {
        commit('setError', `Error while retrieving more matched broadcasts for ${contentId}`);
        throw error;
      } finally {
        commit('setLoadingMoreMatchedBroadcasts', { contentId, loading: false });
      }
    },
  },
  getters: {
    ...items.getters({ resourceType }),
    ...recordModule.getters(),
    ...recordsCommonPartial.getters({ mode: 'combined', scopes, context }),
    loadingContentBroadcasts(state) {
      return id => !!state.loadingContentBroadcasts[id];
    },
    hasUnmatchedContentBroadcasts(state) {
      return id => state.contentBroadcasts[id] || state.loadingContentBroadcasts[id];
    },
    loadingMoreMatchedBroadcasts(state) {
      return id => !!state.loadingMoreMatchedBroadcasts[id];
    },
    matchedBroadcastsToLoadCount(state) {
      return (id) => {
        const content = _.find(state.items, { content_id: id });
        return Math.min(matchedBroadcastsPerPage,
          content.matched_broadcast_count - content.broadcasts.length);
      };
    },
    contentBroadcasts(state) {
      return (contentId) => {
        const allBroadcasts = state.contentBroadcasts[contentId];
        const content = _.find(state.items, { content_id: contentId });
        if (!content) return allBroadcasts || [];

        const { broadcasts: matchedBroadcasts } = content;
        if (!allBroadcasts) return matchedBroadcasts;

        if (state.sort) {
          return allBroadcasts.map((broadcast) => {
            const { broadcast_id } = broadcast;
            const matchedBroadcast = _.find(matchedBroadcasts, { broadcast_id });
            return matchedBroadcast || broadcast;
          });
        }

        const matchedBroadcastIds = _.map(matchedBroadcasts, 'broadcast_id');
        return [
          ...matchedBroadcasts,
          ...allBroadcasts.filter(({ broadcast_id }) =>
            !matchedBroadcastIds.includes(broadcast_id)),
        ];
      };
    },
  },
});
