import viewer from './viewer';
import editor from './editor';
import stations from './stations';
import remotes from './remotes';
import solution from './solution';

export default () => ({
  namespaced: true,
  state: {
    mode: null, // this is purely to keep store in sync with the router
  },
  mutations: {
    setMode(state, mode) {
      state.mode = mode;
    },
  },
  modules: {
    viewer: viewer(),
    editor: editor(),
    recordStructure: editor(),
    stations: stations(),
    remotes: remotes(),
    solution: solution(),
  },
});
