// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vue2Filters from 'vue2-filters';
import VueI18n from 'vue-i18n';
import VueModal from 'vue-js-modal';
import VTooltip from 'v-tooltip';
import vClickOutside from 'v-click-outside';
import VueSimpleSVG from 'vue-simple-svg';
import VueAnalytics from 'vue-analytics';
import Rollbar from '@ibiquity/dashboard.rollbar';
import cognito from '@/services/cognito';
import notifications from '@/services/notifications';

import App from './App';
import router from './router';
import store from './store';
import en from './locale/en';
import installDatetimeFilters from './datetimeFilters';
import Cloudinary from './cloudinary';

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(Vue2Filters);
Vue.use(VueModal, { dynamic: true });
Vue.use(VTooltip, {
  defaultTemplate: '<div class="v-tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
  defaultPopperOptions: {
    modifiers: {
      preventOverflow: { enabled: false },
      hide: { enabled: false },
      flip: { enabled: false },
      offset: { offset: '0,-3' },
    },
  },
  popover: {
    defaultPopperOptions: {
      modifiers: {
        preventOverflow: { enabled: false },
        hide: { enabled: false },
        flip: { enabled: false },
      },
    },
  },
});
Vue.use(vClickOutside);
Vue.use(installDatetimeFilters);
Vue.use(Rollbar);
Vue.use(VueSimpleSVG);

if (process.env.VUE_APP_DISABLE_TRANSITIONS) {
  Vue.component('Transition', {
    render() {
      if (!this.$slots.default) return null;
      return this.$slots.default[0];
    },
  });
}

if (process.env.VUE_APP_GOOGLE_ANALYTICS_ID) {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
    router,
  });
} else {
  Vue.prototype.$ga = {
    event: () => {},
  };
}

Vue.use(Cloudinary, {
  cloudName: process.env.VUE_APP_CLOUDINARY_CLOUDNAME,
  preset: process.env.VUE_APP_CLOUDINARY_PRESET,
  folder: process.env.VUE_APP_CLOUDINARY_FOLDER,
});

const i18n = new VueI18n({
  locale: 'en', // set locale
  messages: {
    en,
  }, // set locale messages
});

cognito.on('refresh:failed', () => {
  cognito.logout();
  notifications.clear();
  if (window.location.pathname.match(/^\/login|^\/forgot-password|^\/expired-password/)) return;
  window.location.reload();
});

document.addEventListener('visibilitychange', () => {
  if (document.visibilityState !== 'visible') return;
  cognito.scheduleRefresh({ force: true });
});

/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');

const {
  VUE_APP_STRING_TIME: buildTime,
  VUE_APP_COMMIT_ID: commitId,
} = process.env;

if (buildTime || commitId) {
  // eslint-disable-next-line no-console
  console.log(
    `%cDashboard UI, Build Time: ${buildTime}, Commit ID: ${commitId}`,
    'font-size: medium; font-weight: bold',
  );

  Rollbar.updateConfig({
    payload: {
      client: { javascript: { code_version: commitId } },
    },
  });
}
