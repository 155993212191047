/* eslint-disable no-param-reassign,no-shadow */
import _ from 'lodash';
import { typeFromRecordId } from '@ibiquity/dashboard.conrad-events';
import api from '@/services/api';
import itemPartial from '@/store/item.module';
import notifications, { Notifications } from '@/services/notifications';
import { PrioritiesEnum, propertyPriorityBySource } from '@/lib/recordPriorities';
import { toRawProperty } from '@/lib/recordProperties';
import {
  isPropertySourceDefault,
  sourceFromRecord,
  propertySources,
  remoteBySource,
  defaultPropertySource,
  remoteSourceValueProperty,
} from '@/lib/recordSources';

const createState = () => ({
  ...itemPartial.state(),
  sourceChanges: [],
});

const getters = ({ resourceType, idKey = 'id' } = {}) => ({
  ...itemPartial.getters({ resourceType, idKey }),

  value(state) {
    return property => _.get(state.item, property);
  },
  remotes(state, getters, rootState, rootGetters) {
    return rootGetters['records/remotes/remotes'](state.item);
  },
  source(state) {
    return property => sourceFromRecord(state.item, property);
  },
  propertyPriorityBySource(state) {
    return (property, source) => propertyPriorityBySource(state.item, property, source);
  },
  valuesChanged(state) {
    return state.changes.length > 0;
  },
  sourcesChanged(state) {
    return state.sourceChanges.length > 0;
  },
  isAnyChangeMade(state, getters) {
    return getters.valuesChanged || getters.sourcesChanged;
  },
  changedSources(state) {
    return state.sourceChanges.map(s => s.name);
  },
  isEditedItemSourceChanged(state, getters) {
    return property => getters.changedSources.includes(property);
  },
  changedPropertySource(state) {
    return (property) => {
      const changedItem = state.sourceChanges.find(item => item.name === property) || {};
      return changedItem.source;
    };
  },
  sourceChangesObject(state, getters) {
    const changes = state.sourceChanges.map(({ name: property, source }) => {
      if (source === null) return [property, null];

      const priorities = {};
      const propertySources = getters.propertySources(property);
      propertySources.forEach((eachRemoteSource) => {
        const priority = propertyPriorityBySource(state.item, property, eachRemoteSource);
        if (priority === undefined) return;
        priorities[eachRemoteSource] = priority === PrioritiesEnum.Manual ? null : priority;
      });
      priorities[source] = PrioritiesEnum.Manual;
      return [property, priorities];
    });
    return _.fromPairs(changes);
  },

  itemUpdateObject(state, getters) {
    const changes = getters.changesObject;
    const result = _.omit(changes, ['logo']);
    if (_.has(changes, 'logo')) {
      result.logo_source_urls = changes.logo ? _.compact([changes.logo.stdres_url]) : null;
    }
    return result;
  },

  pendingPropertySource(state, getters) {
    return (property) => {
      const pendingPriorityUpdate = getters.editedItem?.pending_priority_updates?.[property];
      if (_.isEmpty(pendingPriorityUpdate)) return getters.defaultPropertySource(property);
      const prioritySource = _.findKey(pendingPriorityUpdate,
        priority => priority === PrioritiesEnum.Manual);
      return prioritySource;
    };
  },

  propertySource(state, getters) {
    return (property) => {
      if (getters.isPropertySourceBeingUpdated(property)) {
        return getters.pendingPropertySource(property);
      }

      if (getters.isPropertyOverridden(property)) return 'override';
      if (getters.isInheritedProperty(property)) return 'inherited'; // just for broadcast

      const source = getters.changedPropertySource(property);
      if (source === null) return getters.defaultPropertySource(property);
      if (source) return source;

      if (getters.changesObject[property] === null) return getters.defaultPropertySource(property);

      const pendingValue = getters.pendingValue(property);
      if (pendingValue === null) {
        return getters.defaultPropertySource(property);
      }

      return sourceFromRecord(getters.editedItem, property) || '';
    };
  },

  isPropertyOverridden(state, getters) {
    return (property) => {
      if (_.has(getters.changesObject, property)) {
        const changedValue = getters.changesObject[property];
        return changedValue !== null;
      }
      const rawProperty = toRawProperty(property);
      const pendingUpdates = state.item?.pending_updates || {};
      if (_.has(pendingUpdates, rawProperty)) {
        return pendingUpdates[rawProperty] !== null;
      }
      if (sourceFromRecord(state.item, property) === 'dashboard') return true;
      return false;
    };
  },

  isInheritedProperty() { // overriden at broadcast store
    return () => false;
  },

  isPropertySourceBeingUpdated(state) {
    return property =>
      _.has(state.item.pending_priority_updates, property);
  },

  isPropertyValueBeingUpdated(state) {
    return (property) => {
      const rawProperty = toRawProperty(property);
      return _.has(state.item.pending_updates, rawProperty);
    };
  },

  isPropertyBeingUpdated(state, getters) {
    return property =>
      getters.isPropertySourceBeingUpdated(property)
      || getters.isPropertyValueBeingUpdated(property);
  },

  itemProperty(state, getters) {
    return (property) => {
      if (getters.isEditedItemSourceChanged(property)) {
        const source = getters.changedPropertySource(property)
          || getters.defaultPropertySource(property);
        return getters.propertyValueBySource(property, source);
      }

      if (getters.isPropertySourceBeingUpdated(property)) {
        const pendingSource = getters.pendingPropertySource(property)
          || getters.defaultPropertySource(property);
        return getters.propertyValueBySource(property, pendingSource);
      }

      if (getters.isPropertyValueBeingUpdated(property)) {
        return getters.pendingValue(property);
      }

      return getters.editedItem?.[property];
    };
  },

  pendingValue(state, getters) {
    return (property) => {
      const rawProperty = toRawProperty(property);
      const pendingUpdate = getters.editedItem?.pending_updates?.[rawProperty];
      if (property !== 'logo' || pendingUpdate === null) return pendingUpdate;
      return {
        stdres_url: pendingUpdate && pendingUpdate[0],
        hires_url: pendingUpdate && pendingUpdate[0],
      };
    };
  },

  propertySources(state, getters) {
    return field => propertySources(getters.remotes, field);
  },

  remoteBySource(state, getters) {
    return source => remoteBySource(getters.remotes, source);
  },

  propertyValueBySource(state, getters) {
    return (property, source) => {
      const remote = getters.remoteBySource(source);
      const valueProperty = remoteSourceValueProperty(property);
      return _.get(remote, valueProperty);
    };
  },

  defaultPropertySource(state, getters) {
    return field => defaultPropertySource(state.item, getters.remotes, field);
  },

  isPropertySourceDefault(state, getters) {
    return (property) => {
      if (getters.isPropertyOverridden(property)) return false;

      if (getters.isEditedItemSourceChanged(property)) {
        return getters.changedPropertySource(property) === null;
      }

      if (getters.isPropertySourceBeingUpdated(property)) {
        const pendingPriorityUpdate = getters.editedItem?.pending_priority_updates?.[property];
        return _.isEmpty(pendingPriorityUpdate);
      }

      const source = getters.changedPropertySource(property) || getters.source(property);
      return isPropertySourceDefault(state.item, property, source);
    };
  },

  isRestoreDefaultAvailable(state, getters) {
    return (field) => {
      if (_.isEmpty(getters.remotes)) return false;
      return (
        !!getters.defaultPropertySource(field)
        && !getters.isPropertySourceDefault(field)
      );
    };
  },

  updateNotificationTopic(state) {
    const { item } = state;
    return Notifications.topic('records', item.id, item.organization_id);
  },

  updateExpectation(state, getters) {
    return () => {
      const { id } = state.item;
      const type = typeFromRecordId(id);
      const pendingUpdates = {
        operation: `${type}.update`,
        handler: async () => {
          const { data: record } = await api.getItemDetails(resourceType, id) || {};
          const areAllPending = _(getters.itemUpdateObject)
            .keys()
            .every(property => _.has(record.pending_updates, property));
          return areAllPending ? record : false;
        },
      };
      return [pendingUpdates];
    };
  },

  updateSourceExpectation(state, getters) {
    return () => {
      const { id } = state.item;
      const type = typeFromRecordId(id);
      const pendingUpdates = {
        operation: `${type}.source_priorities_change`,
        handler: async () => {
          const { data: record } = await api.getItemDetails(resourceType, id) || {};
          const areAllPending = _(getters.sourceChangesObject)
            .keys()
            .every(property => _.has(record.pending_priority_updates, property));
          return areAllPending ? record : false;
        },
      };
      return [pendingUpdates];
    };
  },
});

const mutations = ({ stripEmptyOnChange = true } = {}) => {
  const itemMutations = itemPartial.mutations({ stripEmptyOnChange });

  return {
    ...itemMutations,

    addChange(state, { name, value }) {
      itemMutations.addChange(state, { name, value });
      const sourceChangeIndex = state.sourceChanges.findIndex(i => (i.name === name));
      if (sourceChangeIndex >= 0) state.sourceChanges.splice(sourceChangeIndex, 1);
    },

    addSourceChange(state, { name, source }) {
      // note: use addSourceChange action in the app instead!
      if (!name) throw new Error(`Invalid property name provided: ${name}`);

      const existingIndex = state.sourceChanges.findIndex(i => (i.name === name));
      if (existingIndex >= 0) state.sourceChanges.splice(existingIndex, 1);

      state.sourceChanges.push({ name, source });
    },

    clearSourceChanges(state) {
      state.sourceChanges = [];
    },

    clear(state) {
      Object.assign(state, createState());
    },
  };
};

const actions = ({ idKey = 'id' } = {}) => ({
  ...itemPartial.actions({ idKey }),

  addSourceChange({ commit, getters }, { name, source }) {
    // if overridden value in changes state - clear it
    commit('clearChange', name);

    // if overriden value in conrad - clear it
    if (getters.source(name) === 'dashboard') {
      commit('addChange', { name, value: null });
    }
    // 'addSourceChange' change has to be called after 'addChange'
    // as the 'addChange' clears state.sourceChanges
    commit('addSourceChange', { name, source });
  },

  async updatePriorities({
    commit, state, getters, dispatch,
  }, { indicateProgress = true } = {}) {
    if (indicateProgress) commit('setSaving', true);

    try {
      const { [idKey]: id } = state.item;
      const wait = await notifications.createWait(getters.updateNotificationTopic);
      const { data: updateResponse } = await api.updatePriorities(
        getters.updateResourcePath,
        id,
        getters.sourceChangesObject,
      );

      const updateExpectation = getters.updateSourceExpectation(updateResponse);
      const waitResult = await wait(updateExpectation);

      await dispatch('propagateUpdate', waitResult);

      commit('clearSourceChanges');
    } finally {
      if (indicateProgress) commit('setSaving', false);
    }
  },

  async reloadItem({ dispatch, state }) {
    const { id } = state.item;
    const { data: record } = await api.getItemDetails('records', id);
    await dispatch('propagateUpdate', record);
  },

  async propagateUpdate({ commit }, record) {
    commit('setItem', record);
  },
});

export default {
  state: createState,
  getters,
  mutations,
  actions,
};
