import { mapState } from 'vuex';
import MessageModal from '@/components/common/MessageModal';

export default {
  computed: {
    ...mapState('screen', ['mobile']),
  },

  methods: {
    showModal(Content, options = {}, eventHandlers = {}) {
      const {
        dismissable = true,
        overflowable = false,
        width = 480,
        height = 240,
        transition = 'scale',
        className = null,
        ...propsFromOptions
      } = options;
      const { mobile } = this;

      const props = {
        ...propsFromOptions,
        overflowable,
      };

      const modalOptions = {
        reset: true,
        adaptive: true,
        transition,
        clickToClose: dismissable,
        class: mobile ? ['fullscreen'] : [],
        width: mobile ? '100%' : width,
        height: mobile ? '100%' : height,
        classes: ['v--modal', 'overflow-visible', className],
      };

      this.$modal.show(Content, props, modalOptions, eventHandlers);
    },

    showMessageModal({ plain, ...options } = {}) {
      this.showModal(MessageModal, {
        width: plain ? 360 : 560,
        height: plain ? 230 : 401,
        dismissable: false,
        plain,
        ...options,
      });
    },
  },
};
