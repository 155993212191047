import { mapActions, mapState, mapMutations } from 'vuex';
import scrollUtil from '@/utils/scroll';
import WatcherMixin from '@/components/common/WatcherMixin';

const pageSize = 25;

export default storeName => ({
  mixins: [
    WatcherMixin(storeName),
  ],

  computed: {
    ...mapState(`${storeName}/activities`, { activities: 'items' }),
    ...mapState(`${storeName}/activities`, ['includeConrad', 'page', 'total', 'sort', 'loading', 'error']),

    props() {
      return {
        error: this.error,
        activities: this.activities,
        total: this.total,
        includeConrad: this.includeConrad,
        resubmit: this.resubmit.bind(this),
        initialLoading: this.initialLoading,
        nextPageLoading: this.nextPageLoading,
      };
    },

    events() {
      return {
        search: this.search,
        'include-conrad-change': this.changeIncludeConrad,
      };
    },

    initialLoading() {
      return this.loading && this.page === 1;
    },

    nextPageLoading() {
      return this.loading && this.page > 1;
    },
  },

  created() {
    this.loadActivities({ force: true });
    this.watch(() => this.loadActivities({ force: true }));
    window.addEventListener('scroll', this.scroll);
  },

  destroyed() {
    window.removeEventListener('scroll', this.scroll);
  },

  methods: {
    ...mapActions(`${storeName}/activities`, ['loadItems', 'resubmit']),
    ...mapMutations(`${storeName}/activities`, ['setSearchQuery', 'setIncludeConrad']),

    loadActivities({ page = 1, addItems = false, force = false } = {}) {
      const { sort } = this;
      return this.loadItems({
        page, sort, pageSize, addItems, force,
      });
    },

    search(searchQuery) {
      scrollUtil.goToTheTop();
      this.setSearchQuery(searchQuery);
      this.loadActivities();
    },

    changeIncludeConrad() {
      scrollUtil.goToTheTop();
      this.setIncludeConrad(!this.includeConrad);
      this.loadActivities();
    },

    scroll() {
      const shouldLoadMore = this.activities.length < this.total;
      if (scrollUtil.isBottom(400) && shouldLoadMore && !this.loading) {
        this.loadActivities({ page: this.page + 1, addItems: true });
      }
    },
  },
});
