import FileSaver from 'file-saver';
import api from '@/services/api';
import { format } from 'date-fns';

const state = () => ({
  exporting: false,
  exportError: null,
  exportData: null,
});

/* eslint-disable no-param-reassign, no-shadow */
const mutations = () => ({
  startExporting(state) {
    state.exporting = true;
  },
  endExporting(state) {
    state.exporting = false;
  },
  setExportError(state, error) {
    state.exportError = error;
  },
  setExportData(state, data) {
    state.exportData = data;
  },
});

const actions = ({ resourceType }) => ({
  async exportItems({ commit, getters, state }, { include, context } = {}) {
    const { query } = state;
    const extendedQuery = getters.extendedQuery ? getters.extendedQuery(query) : query;

    commit('startExporting');
    try {
      commit('setExportError', null);
      commit('setExportData', null);

      const params = { query: extendedQuery, pagination: false };
      if (include) params.include = include;
      if (context) params.context = context;
      const { data } = await api.getItems(
        `${resourceType}/export`, params, { responseType: 'text' },
      );
      commit('setExportData', data);
    } catch (error) {
      commit('setExportError', error);
      throw error;
    } finally {
      commit('endExporting');
    }
  },
  download({ state, commit }) {
    const blob = new Blob([state.exportData], { type: 'text/csv;charset=utf-8' });
    const timestamp = format(new Date(), 'yyyyMMdd-HHmmss');
    const escapedQuery = state.query.replace(/[^\w]/g, '-').replace(/-+/g, '-');
    FileSaver.saveAs(blob, `dashboard-${resourceType}-${timestamp}-${escapedQuery}.csv`);
    commit('setExportData', null);
  },
});

export default {
  state,
  mutations,
  actions,
};
