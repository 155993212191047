/* eslint-disable no-multi-spaces */
import _                from 'lodash';
import PanelItem        from '@/components/common/PanelItem';
import SourceIndicator  from '@/components/common/SourceIndicator';
import FieldActions     from './FieldActions';
import SourcePicker     from '../../_SourcePicker';
/* eslint-enable no-multi-spaces */

export default {
  components: {
    PanelItem,
    SourceIndicator,
    SourcePicker,
    FieldActions,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    isPropertyBeingUpdated: {
      type: Boolean,
      default: false,
    },
    narrow: {
      type: Boolean,
      default: false,
    },
    narrowFrom: {
      type: String,
      default: '',
    },
    large: {
      type: Boolean,
      default: false,
    },
    propertySources: {
      type: Array,
      default: () => [],
    },
    source: {
      type: String,
      default: '',
    },
    isSourceDefault: {
      type: Boolean,
      default: false,
    },
    sourceOptions: {
      type: Array,
      default: () => [],
    },
    isRestoreDefaultAvailable: {
      type: Boolean,
      default: false,
    },
    isRestoreInheritedAvailable: {
      type: Boolean,
      default: false,
    },
    withActions: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    label() {
      const locale = `station.itemProperties.${this.id}`;
      return this.$te(locale) ? this.$t(locale) : _.startCase(this.id);
    },
    withRestoreDefault() {
      return this.isRestoreDefaultAvailable;
    },
    withRestoreInherited() {
      return this.isRestoreInheritedAvailable;
    },
    sourcePickerProperties() {
      return {
        options: this.sourceOptions,
        source: this.source,
        propertySources: this.propertySources,
        isSourceDefault: this.isSourceDefault,
        editMode: this.editMode,
        disabled: this.isPropertyBeingUpdated,
        value: this.value,
      };
    },
  },
};
