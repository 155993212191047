import _ from 'lodash';
import { mapGetters } from 'vuex';
import DashTableCellWrapper from './TableCellWrapper';

export default ({
  itemProperty = 'item',
  parentItemProperty = 'parentItem',
  nameProperty = 'name',
} = {}) => ({
  components: {
    DashTableCellWrapper,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    parentItem: {
      type: Object,
      default: () => ({}),
    },
    to: {
      type: Object,
      default: null,
    },
    expectedProperties: {
      type: [Array, Function],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Empty',
    },
    placeholderDisabled: {
      type: Boolean,
      default: false,
    },
    notApplicableLabel: {
      type: String,
      default: '—',
    },
    withInheritedHighlights: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('records/editor', { getField: 'field', getRecord: 'record' }),

    field() {
      return this.getField(
        this[nameProperty],
        this[itemProperty],
        this[parentItemProperty],
      );
    },
    record() {
      return this.getRecord(this[itemProperty]);
    },
    indicator() {
      if (!this.isPropertyExpected) return null;
      return this.field.remoteStatus();
    },
    isInherited() {
      return this.withInheritedHighlights && this.field.isInherited();
    },
    isPropertyExpected() {
      const expected = _.isFunction(this.expectedProperties)
        ? this.expectedProperties(this.item)
        : this.expectedProperties;
      return expected.includes(this[nameProperty]);
    },
    contextualPlaceholder() {
      if (this.placeholderDisabled) return '';
      if (this.isPropertyExpected) return this.placeholder;
      return this.notApplicableLabel;
    },
  },
});
